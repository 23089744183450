// src/DataContext.js

import React, { createContext, useContext } from 'react';

// Import all your contexts
import { UserProvider, UserContext } from './contexts/UserContext';
import { AppSettingsProvider, AppSettingsContext } from './contexts/AppSettingsContext';
import { AuthProvider, AuthContext } from './contexts/AuthContext';
import { CatalogProvider, CatalogContext } from './contexts/CatalogContext';
import { FormProvider, FormContext } from './contexts/FormContext';
import { ResponseProvider, ResponseContext } from './contexts/ResponseContext';
import { TeamProvider, TeamContext } from './contexts/TeamContext';
import { UserManagementProvider, UserManagementContext } from './contexts/UserManagementContext';
import { ValidationProvider, ValidationContext } from './contexts/ValidationContext';
import { OrganizationProvider, OrganizationContext } from './contexts/OrganizationContext';
import { GroupProvider, GroupContext } from './contexts/GroupContext';
import { EventProvider, EventContext } from './contexts/EventContext';
import { MessageProvider, MessageContext } from './contexts/MessageContext';

// Import utilities if you wish to include them
import { getObjectByReference } from './utils/firebaseUtils';
import { compressBase64Image, compressImageFields } from './utils/imageUtils';

// Import Firebase if needed
import { firestore, auth } from './firebase';

export const DataContext = createContext();

export const DataProvider = ({ children, groupID }) => {
  return (
    <UserProvider groupID={groupID}>
      <AppSettingsProvider>
        <AuthProvider>
          <CatalogProvider>
            <FormProvider>
              <ResponseProvider>
                <TeamProvider>
                  <UserManagementProvider>
                    <ValidationProvider>
                      <OrganizationProvider>
                        <GroupProvider>
                          <EventProvider>
                            <MessageProvider>
                              <DataContextWrapper>
                                {children}
                              </DataContextWrapper>
                            </MessageProvider>
                          </EventProvider>
                        </GroupProvider>
                      </OrganizationProvider>
                    </ValidationProvider>
                  </UserManagementProvider>
                </TeamProvider>
              </ResponseProvider>
            </FormProvider>
          </CatalogProvider>
        </AuthProvider>
      </AppSettingsProvider>
    </UserProvider>
  );
};

// Wrapper component to consume all contexts and provide a combined value
const DataContextWrapper = ({ children }) => {
  const appSettingsContext = useContext(AppSettingsContext);
  const authContext = useContext(AuthContext);
  const catalogContext = useContext(CatalogContext);
  const formContext = useContext(FormContext);
  const responseContext = useContext(ResponseContext);
  const teamContext = useContext(TeamContext);
  const userContext = useContext(UserContext);
  const userManagementContext = useContext(UserManagementContext);
  const validationContext = useContext(ValidationContext);
  const organizationContext = useContext(OrganizationContext);
  const groupContext = useContext(GroupContext);
  const eventContext = useContext(EventContext);
  const messageContext = useContext(MessageContext);

  // Combine all the contexts into a single value
  const combinedContextValue = {
    // AppSettings
    ...appSettingsContext,

    // Auth
    ...authContext,

    // Catalog
    ...catalogContext,

    // Form
    ...formContext,

    // User
    ...userContext,

    // Response
    ...responseContext,

    // Team
    ...teamContext,

    // UserManagement
    ...userManagementContext,

    // Validation
    ...validationContext,

    // Organization
    ...organizationContext,

    // Group
    ...groupContext,

    // Event
    ...eventContext,

    // Message
    ...messageContext,

    // Utilities (optional inclusion)
    getObjectByReference,
    compressBase64Image,
    compressImageFields,

    // Additional: Firestore and Auth instances if needed
    firestore,
    auth,
  };

  return (
    <DataContext.Provider value={combinedContextValue}>
      {children}
    </DataContext.Provider>
  );
};

export { DataContextWrapper };
export default DataProvider;
