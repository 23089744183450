// src/contexts/ValidationContext.js

import React, { createContext } from 'react';
import { firestore } from '../firebase';
import { doc, setDoc, getDoc, deleteDoc, serverTimestamp, Timestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

export const ValidationContext = createContext();

export const ValidationProvider = ({ children }) => {
  /**
   * Function to send validation code to the user's email
   */
  const sendValidationCode = async (email) => {
    const validationCode = uuidv4().replace(/-/g, '').substring(0, 6).toUpperCase(); // Generate a simple code and make it uppercase
    const expireAt = Timestamp.fromDate(new Date(Date.now() + 15 * 60 * 1000)); // Expires in 15 minutes

    // Save the validation code in Firestore
    const validationCodeDocRef = doc(firestore, 'validationCodes', email);
    await setDoc(validationCodeDocRef, {
      email,
      validationCode,
      createdAt: serverTimestamp(),
      expireAt,
    });

    // Prepare the email content using a nice HTML template
    const subject = 'Your Verification Code';
    const content = getVerificationEmailHtml(validationCode);

    // Send the validation code via email by calling the cloud function
    const sendEmailResponse = await fetch('https://sendemail-lgfph5hmwq-uc.a.run.app', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        toAddress: email,
        subject,
        content,
      }),
    });

    if (!sendEmailResponse.ok) {
      throw new Error('Failed to send validation email');
    }
  };

  // Function to generate the HTML content for the verification email
  const getVerificationEmailHtml = (validationCode) => {
    return `
    <!DOCTYPE html>
    <html>
      <head>
        <meta charset="UTF-8">
        <title>Verify Your Email</title>
        <style>
          /* Add your email styles here */
        </style>
      </head>
      <body>
        <p>Your verification code is:</p>
        <h2>${validationCode}</h2>
        <p>This code will expire in 15 minutes.</p>
      </body>
    </html>
    `;
  };

  /**
   * Function to verify the validation code
   */
  const verifyValidationCode = async (email, code) => {
    const validationCodeDocRef = doc(firestore, 'validationCodes', email);
    const validationCodeSnapshot = await getDoc(validationCodeDocRef);

    if (!validationCodeSnapshot.exists()) {
      throw new Error('Invalid code');
    }

    const data = validationCodeSnapshot.data();
    if (data.validationCode !== code) {
      throw new Error('Invalid code');
    }

    if (data.expireAt.toDate() < new Date()) {
      throw new Error('Code expired');
    }

    // Optionally, delete the validation code after successful verification
    await deleteDoc(validationCodeDocRef);
  };

  return (
    <ValidationContext.Provider
      value={{
        sendValidationCode,
        verifyValidationCode,
      }}
    >
      {children}
    </ValidationContext.Provider>
  );
};
