// src/components/FormBuilder/FieldSelector.jsx

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import './FieldSelector.css';
import ActionButton from '../Common/ActionButton';
import { camelCase } from 'lodash';
import { DataContext } from '../../DataContext';
import { useTranslation } from 'react-i18next';
import RowFieldsBuilder from './RowFieldsBuilder';

const FieldSelector = ({ onAddField, onCancel, initialField, isMandatory }) => {
  const { t } = useTranslation();
  const { fetchAllCatalogs } = useContext(DataContext);

  const [selectedType, setSelectedType] = useState('Text');
  const [selectedSize, setSelectedSize] = useState('full');
  const [label, setLabel] = useState('');
  const [required, setRequired] = useState(false);
  const [length, setLength] = useState('');
  const [description, setDescription] = useState(''); // New state for description

  const [imageShape, setImageShape] = useState('circular');
  const [imagePickerText, setImagePickerText] = useState('');

  const [options, setOptions] = useState([
    { label: '', value: '' },
  ]);

  const [selectOptionsSource, setSelectOptionsSource] = useState('custom');
  const [catalogs, setCatalogs] = useState([]);
  const [selectedCatalogId, setSelectedCatalogId] = useState('');

  const [rowFields, setRowFields] = useState([]);

  const fieldTypes = [
    'Section',
    'Text',
    'Number',
    'Email',
    'Telephone',
    'DatePicker',
    'TimePicker',
    'ToggleSwitch',
    'Checkbox',
    'RadioButton',
    'Select',
    'ColorPicker',
    'TextArea',
    'RangeSlider',
    'MultiRowControl',
    'ImagePicker',
  ];

  const fieldTypeIcons = {
    Section: 'faHeading',
    Text: 'faFont',
    Number: 'faHashtag',
    Email: 'faEnvelopeOpen',
    Telephone: 'faPhoneAlt',
    DatePicker: 'faCalendarAlt',
    TimePicker: 'faClock',
    ToggleSwitch: 'faToggleOn',
    Checkbox: 'faCheckSquare',
    RadioButton: 'faDotCircle',
    Select: 'faList',
    ColorPicker: 'faPalette',
    TextArea: 'faAlignLeft',
    RangeSlider: 'faSlidersH',
    MultiRowControl: 'faGripHorizontal',
    ImagePicker: 'faImage',
  };

  useEffect(() => {
    if (initialField) {
      setSelectedType(initialField.type || 'Text');
      setLabel(initialField.label || '');
      setRequired(initialField.required || false);
      setLength(initialField.length || '');

      if (initialField.type === 'Section') {
        setDescription(initialField.description || ''); // Set description for Section
      } else {
        setDescription('');
      }

      if (initialField.type === 'MultiRowControl') {
        setSelectedSize('full');
      } else {
        setSelectedSize(initialField.size || 'full');
      }

      if (initialField.type === 'ImagePicker') {
        setImageShape(initialField.imageShape || 'circular');
        setImagePickerText(initialField.imagePickerText || '');
      }

      if (['Checkbox', 'RadioButton'].includes(initialField.type)) {
        setOptions(
          initialField.options && initialField.options.length > 0
            ? initialField.options.map((option) => ({
                label: option.label,
                value: option.value || camelCase(option.label),
              }))
            : [{ label: '', value: '' }]
        );
      } else {
        setOptions([{ label: '', value: '' }]);
      }

      if (initialField.type === 'Select') {
        if (initialField.catalogId) {
          setSelectOptionsSource('catalog');
          setSelectedCatalogId(initialField.catalogId);
        } else if (initialField.options && initialField.options.length > 0) {
          setSelectOptionsSource('custom');
          setOptions(
            initialField.options.map((option) => ({
              label: option.label.trim(),
              value: option.value || camelCase(option.label.trim()),
            }))
          );
        }
      }

      if (initialField.type === 'MultiRowControl' && initialField.rowFields) {
        setRowFields(initialField.rowFields);
      } else {
        setRowFields([]);
      }
    } else {
      setSelectedType('Text');
      setSelectedSize('full');
      setLabel('');
      setRequired(false);
      setLength('');
      setDescription(''); // Reset description
      setImageShape('circular');
      setImagePickerText('');
      setOptions([{ label: '', value: '' }]);
      setSelectOptionsSource('custom');
      setSelectedCatalogId('');
      setRowFields([]);
    }
  }, [initialField]);

  useEffect(() => {
    const fetchCatalogs = async () => {
      try {
        const fetchedCatalogs = await fetchAllCatalogs();
        setCatalogs(fetchedCatalogs);
      } catch (error) {
        console.error('Error fetching catalogs:', error);
      }
    };

    fetchCatalogs();
  }, [fetchAllCatalogs]);

  useEffect(() => {
    if (selectedType === 'MultiRowControl') {
      setSelectedSize('full');
    }
  }, [selectedType]);

  const handleDone = () => {
    if (!selectedType || !label.trim()) {
      alert(t('fieldSelector.alertSelectTypeAndLabel'));
      return;
    }

    if (['Checkbox', 'RadioButton'].includes(selectedType)) {
      const hasEmptyLabel = options.some((option) => option.label.trim() === '');
      if (hasEmptyLabel) {
        alert(t('fieldSelector.alertAllOptionsLabel'));
        return;
      }
    }

    if (selectedType === 'Select') {
      if (selectOptionsSource === 'custom') {
        const hasEmptyLabel = options.some((option) => option.label.trim() === '');
        if (hasEmptyLabel) {
          alert(t('fieldSelector.alertAllOptionsLabel'));
          return;
        }
      } else if (selectOptionsSource === 'catalog') {
        if (!selectedCatalogId) {
          alert(t('fieldSelector.alertSelectCatalog'));
          return;
        }
      }
    }

    if (selectedType === 'MultiRowControl') {
      if (rowFields.length === 0) {
        alert(t('fieldSelector.alertAddAtLeastOneRowField'));
        return;
      }
    }

    const fieldData = {
      type: selectedType,
      size: selectedSize,
      label: label.trim(),
      required,
      mandatory: isMandatory || false,
    };

    if (selectedType === 'Section') {
      fieldData.description = description.trim(); // Include description
    }

    if (['Text', 'TextArea'].includes(selectedType) && length) {
      fieldData.length = parseInt(length, 10);
    }

    if (['Checkbox', 'RadioButton'].includes(selectedType)) {
      fieldData.options = options.map((option) => ({
        label: option.label.trim(),
        value: option.value || camelCase(option.label.trim()),
      }));
    }

    if (selectedType === 'Select') {
      if (selectOptionsSource === 'custom') {
        fieldData.options = options.map((option) => ({
          label: option.label.trim(),
          value: option.value || camelCase(option.label.trim()),
        }));
      } else if (selectOptionsSource === 'catalog') {
        fieldData.catalogId = selectedCatalogId;
      }
    }

    if (selectedType === 'ImagePicker') {
      fieldData.imageShape = imageShape;
      fieldData.imagePickerText = imagePickerText.trim();
    }

    if (selectedType === 'MultiRowControl') {
      fieldData.rowFields = rowFields;
    }

    onAddField(fieldData);

    if (!initialField) {
      setSelectedType('Text');
      setSelectedSize('full');
      setLabel('');
      setRequired(false);
      setLength('');
      setDescription('');
      setImageShape('circular');
      setImagePickerText('');
      setOptions([{ label: '', value: '' }]);
      setSelectOptionsSource('custom');
      setSelectedCatalogId('');
      setRowFields([]);
    }
  };

  const handleAddOption = () => {
    setOptions([...options, { label: '', value: '' }]);
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = options.filter((_, idx) => idx !== index);
    setOptions(
      updatedOptions.length > 0 ? updatedOptions : [{ label: '', value: '' }]
    );
  };

  const handleOptionChange = (index, field, value) => {
    const updatedOptions = options.map((option, idx) =>
      idx === index
        ? {
            ...option,
            [field]: value,
            value: field === 'label' ? camelCase(value) : option.value,
          }
        : option
    );

    const values = updatedOptions.map((opt) => opt.value);
    const hasDuplicates = values.some((val, i) => values.indexOf(val) !== i);

    if (hasDuplicates) {
      alert(t('fieldSelector.alertDuplicateOptionValues'));
      return;
    }

    setOptions(updatedOptions);
  };

  const showLengthField = ['Text', 'TextArea'].includes(selectedType);

  const showOptions =
    ['Checkbox', 'RadioButton'].includes(selectedType) ||
    (selectedType === 'Select' && selectOptionsSource === 'custom');

  const showImagePickerConfig = selectedType === 'ImagePicker';

  const showSelectOptionsSource = selectedType === 'Select';

  const showRequiredOption = selectedType !== 'Section';

  const showSizeOptions =
    selectedType !== 'Section' && selectedType !== 'MultiRowControl';

  const handleRequiredChange = (e) => {
    if (isMandatory && !e.target.checked) {
      return;
    }
    setRequired(e.target.checked);
  };

  return (
    <div className="field-selector">
      <div className="field-selector-group full-width">
        <label htmlFor="field-label">
          {selectedType === 'Section'
            ? t('fieldSelector.sectionTitle')
            : t('fieldSelector.fieldLabel')}
        </label>
        <input
          type="text"
          id="field-label"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          placeholder={
            selectedType === 'Section'
              ? t('fieldSelector.enterSectionTitle')
              : t('fieldSelector.enterFieldLabel')
          }
          className="full-width-input"
          disabled={isMandatory && selectedType !== 'MultiRowControl'}
        />
      </div>

      {selectedType === 'Section' && (
        <div className="field-selector-group full-width">
          <label htmlFor="field-description">
            {t('fieldSelector.fieldDescription')}
          </label>
          <textarea
            id="field-description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder={t('fieldSelector.enterFieldDescription')}
            className="full-width-input"
            disabled={isMandatory}
          />
        </div>
      )}

      {showRequiredOption && (
        <div className="field-selector-group full-width">
          <label className="required-checkbox">
            <input
              type="checkbox"
              checked={required}
              onChange={handleRequiredChange}
              disabled={isMandatory}
            />
            {t('fieldSelector.required')}
            {isMandatory && (
              <span className="mandatory-note"> ({t('fieldSelector.mandatory')})</span>
            )}
          </label>
        </div>
      )}

      {/* Field Type Selection */}
      <div className="field-selector-group full-width">
        <label>{t('fieldSelector.fieldType')}</label>
        <div className={`field-type-buttons ${selectedSize}`}>
          {fieldTypes.map((type) => (
            <ActionButton
              key={type}
              onClick={() => {
                if (isMandatory) return;
                setSelectedType(type);
              }}
              label={t(`fieldTypes.${type}`)}
              icon={fieldTypeIcons[type] || 'faPlus'}
              isMobile={false}
              colorType={selectedType === type ? 'primary' : 'secondary'}
              spacing="8px"
              className={`field-type-button ${
                selectedType === type ? 'selected' : ''
              } ${isMandatory ? 'disabled' : ''}`}
              disabled={isMandatory}
            />
          ))}
        </div>
      </div>

      {/* Conditional Rendering for ImagePicker Configuration */}
      {showImagePickerConfig && (
        <>
          <div className="field-selector-group full-width">
            <label>{t('fieldSelector.imageShape')}</label>
            <div className="size-options">
              <label>
                <input
                  type="radio"
                  name="image-shape"
                  value="circular"
                  checked={imageShape === 'circular'}
                  onChange={(e) => setImageShape(e.target.value)}
                  disabled={isMandatory}
                />
                {t('fieldSelector.circular')}
              </label>
              <label>
                <input
                  type="radio"
                  name="image-shape"
                  value="square"
                  checked={imageShape === 'square'}
                  onChange={(e) => setImageShape(e.target.value)}
                  disabled={isMandatory}
                />
                {t('fieldSelector.square')}
              </label>
            </div>
          </div>

          <div className="field-selector-group full-width">
            <label htmlFor="image-picker-text">
              {t('fieldSelector.textBelowImage')}
            </label>
            <input
              type="text"
              id="image-picker-text"
              value={imagePickerText}
              onChange={(e) => setImagePickerText(e.target.value)}
              placeholder={t('fieldSelector.enterTextBelowImage')}
              className="full-width-input"
              disabled={isMandatory}
            />
          </div>
        </>
      )}

      {/* Conditional Rendering for Select Options Source */}
      {showSelectOptionsSource && (
        <div className="field-selector-group full-width">
          <label>{t('fieldSelector.optionsSource')}</label>
          <div className="options-source-toggle">
            <label className="radio-option">
              <input
                type="radio"
                name="select-options-source"
                value="custom"
                checked={selectOptionsSource === 'custom'}
                onChange={() => setSelectOptionsSource('custom')}
                disabled={isMandatory}
              />
              {t('fieldSelector.customOptions')}
            </label>
            <label className="radio-option">
              <input
                type="radio"
                name="select-options-source"
                value="catalog"
                checked={selectOptionsSource === 'catalog'}
                onChange={() => setSelectOptionsSource('catalog')}
                disabled={isMandatory}
              />
              {t('fieldSelector.catalogOptions')}
            </label>
          </div>
        </div>
      )}

      {/* Conditional Rendering for Catalog Selection */}
      {selectedType === 'Select' && selectOptionsSource === 'catalog' && (
        <div className="field-selector-group full-width">
          <label htmlFor="catalog-select">{t('fieldSelector.selectCatalog')}</label>
          <select
            id="catalog-select"
            value={selectedCatalogId}
            onChange={(e) => setSelectedCatalogId(e.target.value)}
            className="full-width-input"
            disabled={isMandatory}
          >
            <option value="">{t('fieldSelector.selectCatalogPlaceholder')}</option>
            {catalogs.map((catalog) => (
              <option key={catalog.id} value={catalog.id}>
                {catalog.name}
              </option>
            ))}
          </select>
        </div>
      )}

      {/* Conditional Rendering for MultiRowControl */}
      {selectedType === 'MultiRowControl' && (
        <RowFieldsBuilder rowFields={rowFields} setRowFields={setRowFields} />
      )}

      {showLengthField && (
        <div className="field-selector-group full-width">
          <label htmlFor="field-length">{t('fieldSelector.fieldLength')}</label>
          <input
            type="number"
            id="field-length"
            value={length}
            onChange={(e) => setLength(e.target.value)}
            placeholder={t('fieldSelector.enterFieldLength')}
            className="full-width-input"
            min="1"
            disabled={isMandatory}
          />
        </div>
      )}

      {showOptions && (
        <div className="field-selector-group full-width">
          <label>{t('fieldSelector.options')}</label>
          {options.map((option, index) => (
            <div key={index} className="option-row">
              <input
                type="text"
                value={option.label}
                onChange={(e) =>
                  handleOptionChange(index, 'label', e.target.value)
                }
                placeholder={t('fieldSelector.optionLabel')}
                className="option-input label-input"
                disabled={isMandatory}
              />
              {options.length > 1 && (
                <button
                  type="button"
                  className="remove-option-button"
                  onClick={() => handleRemoveOption(index)}
                  aria-label={t('fieldSelector.removeOption')}
                  disabled={isMandatory}
                >
                  &times;
                </button>
              )}
            </div>
          ))}
          {!isMandatory && (
            <div className="add-option-container">
              <ActionButton
                onClick={handleAddOption}
                label={t('fieldSelector.addOption')}
                text={t('fieldSelector.addOption')}
                icon="faPlus"
                isMobile={false}
                colorType="secondary"
                spacing="8px"
                className="add-option-button"
              />
            </div>
          )}
        </div>
      )}

      {/* Conditional Rendering for Field Size Options */}
      {showSizeOptions && (
        <div className="field-selector-group full-width">
          <label>{t('fieldSelector.fieldSize')}</label>
          <div className="size-options">
            <label>
              <input
                type="radio"
                name="field-size"
                value="full"
                checked={selectedSize === 'full'}
                onChange={(e) => setSelectedSize(e.target.value)}
                disabled={isMandatory}
              />
              {t('fieldSelector.fullWidth')}
            </label>
            <label>
              <input
                type="radio"
                name="field-size"
                value="half"
                checked={selectedSize === 'half'}
                onChange={(e) => setSelectedSize(e.target.value)}
                disabled={isMandatory}
              />
              {t('fieldSelector.halfWidth')}
            </label>
          </div>
        </div>
      )}

      <div className="field-selector-buttons">
        <ActionButton
          onClick={handleDone}
          label={t('fieldSelector.done')}
          text={t('fieldSelector.done')}
          icon="faCheck"
          isMobile={false}
          colorType="primary"
          disabled={
            !selectedType ||
            !label.trim() ||
            (showOptions &&
              options.some((option) => option.label.trim() === '')) ||
            (selectedType === 'Select' &&
              ((selectOptionsSource === 'custom' &&
                options.some((option) => option.label.trim() === '')) ||
                (selectOptionsSource === 'catalog' && !selectedCatalogId))) ||
            (selectedType === 'MultiRowControl' && rowFields.length === 0)
          }
          spacing="8px"
          className="full-width-button"
        />
        <ActionButton
          onClick={onCancel}
          label={t('fieldSelector.cancel')}
          text={t('fieldSelector.cancel')}
          icon="faTimes"
          isMobile={false}
          colorType="secondary"
          spacing="8px"
          className="full-width-button"
        />
      </div>
    </div>
  );
};

FieldSelector.propTypes = {
  onAddField: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialField: PropTypes.object,
  isMandatory: PropTypes.bool,
};

FieldSelector.defaultProps = {
  initialField: null,
  isMandatory: false,
};

export default FieldSelector;
