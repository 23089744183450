// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import { useQueryClient } from '@tanstack/react-query';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // State to track the current authenticated user
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserLoading, setCurrentUserLoading] = useState(true);

  const queryClient = useQueryClient();

  // Set up authentication state listener.
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setCurrentUserLoading(false);
      if (user) {
        // Invalidate queries to fetch fresh data that require authentication
        queryClient.invalidateQueries(['members', 'formGroups']);
      } else {
        // Remove queries that require authentication
        queryClient.removeQueries(['members', 'formGroups']);
        // Do not clear appSettings so that unauthenticated users can access it
      }
    });

    return () => unsubscribe();
  }, [queryClient]);

  // Authentication methods

  // Function to verify password reset code
  const verifyPasswordResetCode = async (code) => {
    try {
      await auth.verifyPasswordResetCode(code);
      return true; // Code is valid
    } catch (error) {
      console.error('Error verifying password reset code:', error);
      throw error;
    }
  };

  // Function to confirm password reset
  const confirmPasswordReset = async (oobCode, newPassword) => {
    try {
      await auth.confirmPasswordReset(oobCode, newPassword);
      return true; // Password reset successful
    } catch (error) {
      console.error('Error confirming password reset:', error);
      throw error;
    }
  };

  // Function to send password reset email
  const sendPasswordResetEmail = async (email) => {
    try {
      await auth.sendPasswordResetEmail(email);
      return true; // Email sent
    } catch (error) {
      console.error('Error sending password reset email:', error);
      throw error;
    }
  };

  // Function to sign in
  const signIn = async (email, password) => {
    try {
      await auth.signInWithEmailAndPassword(email, password);
      // currentUser will be updated via auth.onAuthStateChanged listener
    } catch (error) {
      console.error('Error signing in:', error);
      throw error;
    }
  };

  // Function to sign out
  const signOut = async () => {
    try {
      await auth.signOut();
      return true; // Sign-out successful
    } catch (error) {
      console.error('Error signing out:', error);
      throw error;
    }
  };

  // Function to get current user's email
  const getCurrentUserEmail = () => {
    const user = auth.currentUser;
    return user ? user.email : null;
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        currentUserLoading,
        signIn,
        signOut,
        sendPasswordResetEmail,
        confirmPasswordReset,
        verifyPasswordResetCode,
        getCurrentUserEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
