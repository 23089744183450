import React, { useContext, useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { EventContext } from '../../contexts/EventContext';
import { DataContext } from '../../DataContext';
import { MessageContext } from '../../contexts/MessageContext';
import { ResponseContext } from '../../contexts/ResponseContext';
import { useTranslation } from 'react-i18next';
import Spinner from '../Common/Spinner';
import CustomModal from '../Common/CustomModal';
import EventForm from './EventForm';
import ToastContainer from '../Common/ToastContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
  faEnvelope,
  faStickyNote,
  faTimes,
  faBirthdayCake,
  faChurch
} from '@fortawesome/free-solid-svg-icons';
import ActionButton from '../Common/ActionButton';
import MessageSender from '../Communications/MessageSender';
import ToggleSwitch from '../FormBuilder/Fields/ToggleSwitch';
import './CalendarPage.css';

const CalendarPage = () => {
  const { t } = useTranslation();
  const {
    events,
    isEventsLoading,
    isEventsError,
    addEvent,
    editEvent,
    deleteEvent,
    deleteEventOccurrence,
  } = useContext(EventContext);

  const { userRole } = useContext(DataContext);
  const { messages, isMessagesLoading, isMessagesError } = useContext(MessageContext);
  const { useResponses } = useContext(ResponseContext);

  // Fetch member responses to get birthdays
  const { data: membersResponses = [], isLoading: isMembersLoading, isError: isMembersError } = useResponses('membersForm');

  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());

  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(null);

  const [toasts, setToasts] = useState([]);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState([]);

  // Toggle states for filtering
  const [showEvents, setShowEvents] = useState(true);
  const [showCommunications, setShowCommunications] = useState(true);
  const [showBirthdays, setShowBirthdays] = useState(true);
  const [showLiturgyEvents, setShowLiturgyEvents] = useState(true);

  const formatDateKey = (date) => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const parseDateKey = (dateKey) => {
    const [year, month, day] = dateKey.split('-').map(Number);
    return new Date(year, month - 1, day);
  };

  const showToast = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prevToasts) => [...prevToasts, { id, message, type, duration }]);
    setTimeout(() => removeToast(id), duration);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  const handlePrevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const handleAddEvent = () => {
    setSelectedDate(null);
    setModalTitle(t('calendar.addEvent', 'Add Event'));
    setModalContent(
      <EventForm
        onSave={async (eventData) => {
          try {
            await addEvent(eventData);
            setShowModal(false);
            showToast(t('calendar.eventAdded', 'Event added successfully'), 'success');
          } catch (error) {
            console.error('Error adding event:', error);
            showToast(t('calendar.errorAddingEvent', 'Error adding event'), 'error');
          }
        }}
        onCancel={() => setShowModal(false)}
      />
    );
    setShowModal(true);
    setMenuVisible(false);
  };

  const handleAddCommunication = () => {
    setSelectedDate(null);
    setModalTitle(t('calendar.addCommunication', 'Add Communication'));
    setModalContent(
      <MessageSender
        onlyScheduled={true}
        onClose={() => setShowModal(false)}
      />
    );
    setShowModal(true);
    setMenuVisible(false);
  };

  const handleAddPost = () => {
    setSelectedDate(null);
    console.log('Add Post');
    setMenuVisible(false);
  };

  const handleEditEvent = (event, occurrenceDate) => {
    setModalTitle(t('calendar.editEvent', 'Edit Event'));
    setModalContent(
      <EventForm
        initialData={event}
        selectedDate={occurrenceDate}
        onSave={async (eventData) => {
          try {
            await editEvent(event.id, eventData);
            setShowModal(false);
            showToast(t('calendar.eventUpdated', 'Event updated successfully'), 'success');
          } catch (error) {
            console.error('Error editing event:', error);
            showToast(t('calendar.errorEditingEvent', 'Error editing event'), 'error');
          }
        }}
        onCancel={() => setShowModal(false)}
        onDelete={async () => {
          try {
            await deleteEvent(event.id);
            setShowModal(false);
            showToast(t('calendar.eventDeleted', 'Event deleted successfully'), 'success');
          } catch (error) {
            console.error('Error deleting event:', error);
            showToast(t('calendar.errorDeletingEvent', 'Error deleting event'), 'error');
          }
        }}
        onDeleteOccurrence={async (date) => {
          try {
            await deleteEventOccurrence(event.id, date);
            setShowModal(false);
            showToast(t('calendar.occurrenceDeleted', 'Occurrence deleted successfully'), 'success');
          } catch (error) {
            console.error('Error deleting occurrence:', error);
            showToast(t('calendar.errorDeletingOccurrence', 'Error deleting occurrence'), 'error');
          }
        }}
      />
    );
    setShowModal(true);
  };

  const handleDateClick = (date) => {
    const dateKey = formatDateKey(date);
    if (eventMap[dateKey]) {
      eventMap[dateKey].sort((a,b) => a.startDateTime - b.startDateTime);
    }
    const filtered = (eventMap[dateKey] || []).filter(ev => {
      if (ev.type === 'event' && !showEvents) return false;
      if (ev.type === 'message' && !showCommunications) return false;
      if (ev.type === 'birthday' && !showBirthdays) return false;
      if (ev.type === 'liturgy' && !showLiturgyEvents) return false;
      return true;
    });
    setSelectedDate(dateKey);
    setSelectedEvents(filtered);
  };

  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    };

    if (menuVisible) {
      document.addEventListener('mousedown', handleClickOutsideMenu);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, [menuVisible]);

  if (isEventsLoading || isMessagesLoading || isMembersLoading) {
    return (
      <div className="loading-container">
        <Spinner size="100px" />
      </div>
    );
  }

  if (isEventsError || isMessagesError || isMembersError) {
    return (
      <div className="error-message">
        {t('calendar.errorFetchingEvents', 'Error fetching data')}
      </div>
    );
  }

  const eventMap = {};
  const now = new Date();
  const currentYear = now.getFullYear();

  // Map events
  events.forEach((event) => {
    const startDate = new Date(
      event.startDateTime.getFullYear(),
      event.startDateTime.getMonth(),
      event.startDateTime.getDate()
    );
    const endDate = new Date(
      event.endDateTime.getFullYear(),
      event.endDateTime.getMonth(),
      event.endDateTime.getDate()
    );
    const recurringDays = event.recurringDays || [];
    const exceptionDates = event.exceptionDates || [];

    if (event.isRecurring && recurringDays.length > 0) {
      for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        const dayIndex = d.getDay().toString();
        const dateKey = formatDateKey(d);
        if (exceptionDates.includes(dateKey)) continue;
        if (recurringDays.includes(dayIndex)) {
          if (!eventMap[dateKey]) eventMap[dateKey] = [];
          eventMap[dateKey].push({ ...event });
        }
      }
    } else {
      for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        const dateKey = formatDateKey(d);
        if (exceptionDates.includes(dateKey)) continue;
        if (!eventMap[dateKey]) eventMap[dateKey] = [];
        eventMap[dateKey].push({ ...event });
      }
    }
  });

  // Map messages as events
  messages.forEach((msg) => {
    if (msg.messageType === 'scheduled' && msg.scheduledDateTime) {
      const dateKey = formatDateKey(msg.scheduledDateTime);
      if (!eventMap[dateKey]) eventMap[dateKey] = [];
      eventMap[dateKey].push({
        id: msg.id,
        // Use message title if available, otherwise fallback to default label
        name: msg.title || t('calendar.scheduledCommunication', 'Scheduled Communication'),
        isAllDay: false,
        startDateTime: msg.scheduledDateTime,
        endDateTime: msg.scheduledDateTime,
        description: msg.content,
        type: 'message',
        color: '#9c27b0',
      });
    }
  });

  // Map birthdays
  membersResponses.forEach((resp) => {
    const fields = resp.fields || [];
    let firstName = 'Member';
    let lastName = '';
    let dob = null;
    fields.forEach((f) => {
      if (f.id === 'dateOfBirth') dob = f.value;
      if (f.id === 'firstName') firstName = f.value || 'Member';
      if (f.id === 'lastName') lastName = f.value || '';
    });
    if (dob) {
      const dobDate = new Date(dob);
      if (!isNaN(dobDate)) {
        const yearsToShow = [currentYear - 1, currentYear, currentYear + 1];
        yearsToShow.forEach((year) => {
          const displayDOB = new Date(year, dobDate.getMonth(), dobDate.getDate());
          const dateKey = formatDateKey(displayDOB);
          if (!eventMap[dateKey]) eventMap[dateKey] = [];
          eventMap[dateKey].push({
            id: resp.id + '-birthday-' + year,
            name: `${firstName} ${lastName}`.trim(),
            isAllDay: true,
            startDateTime: displayDOB,
            endDateTime: displayDOB,
            type: 'birthday',
            color: '#ffa500',
          });
        });
      }
    }
  });

  // Sort events by start time
  Object.keys(eventMap).forEach((dateKey) => {
    eventMap[dateKey].sort((a,b) => a.startDateTime - b.startDateTime);
  });

  const generateCalendar = () => {
    const calendar = [];
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const startDay = startOfMonth.getDay();

    let dayCounter = 1 - startDay;
    for (let week = 0; week < 6; week++) {
      const weekRow = [];
      for (let day = 0; day < 7; day++) {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), dayCounter);
        weekRow.push(date);
        dayCounter++;
      }
      calendar.push(weekRow);
    }
    return calendar;
  };

  const calendar = generateCalendar();

  const createEventBarRoot = () => {
    const eventBarRoot = document.createElement('div');
    eventBarRoot.setAttribute('id', 'event-bar-root');
    document.body.appendChild(eventBarRoot);
    return eventBarRoot;
  };

  const renderEventBottomBar = () => {
    if (!selectedDate) return null;

    const eventBarRoot = document.getElementById('event-bar-root') || createEventBarRoot();
    const parsedSelectedDate = parseDateKey(selectedDate);

    const sortedEvents = [...selectedEvents].sort((a,b) => a.startDateTime - b.startDateTime);

    return ReactDOM.createPortal(
      <div className="event-bottom-bar">
        <div className="event-bottom-bar-content">
          <button className="close-button" onClick={() => setSelectedDate(null)}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <h3>{parsedSelectedDate.toLocaleDateString()}</h3>
          <div className="event-list">
            {sortedEvents.map((ev, index) => {
              // Determine icon color/style
              let iconStyle = {};
              let icon = faCalendarAlt;
              if (ev.type === 'message') {
                icon = faEnvelope;
                iconStyle = { color: '#9c27b0' };
              } else if (ev.type === 'birthday') {
                icon = faBirthdayCake;
                iconStyle = { color: '#ffa500' };
              } else if (ev.type === 'liturgy') {
                icon = faChurch;
                // If liturgy is white, icon black; else use event color or default
                if (ev.color === '#FFFFFF') {
                  iconStyle = { color: 'black' };
                } else {
                  iconStyle = { color: ev.color || '#006400' };
                }
              } else if (ev.type === 'event') {
                icon = faCalendarAlt;
                iconStyle = { color: ev.color || 'var(--main-color)' };
              }

              return (
                <div className={`event-row ${index === sortedEvents.length - 1 ? 'last' : ''}`} key={`${ev.id}-${selectedDate}-${index}`}>
                  <div className="event-info">
                    <div className="event-header">
                      <FontAwesomeIcon icon={icon} className="item-icon" style={iconStyle} />
                      <span className="item-type-label">
                        {ev.type === 'event' && t('calendar.eventLabel', 'Event')}
                        {ev.type === 'message' && t('calendar.communicationLabel', 'Communication')}
                        {ev.type === 'birthday' && t('calendar.birthdayLabel', 'Birthday')}
                        {ev.type === 'liturgy' && t('calendar.liturgyLabel', 'Liturgy')}
                      </span>
                    </div>
                    <div className="event-name">{ev.name}</div>

                    {(ev.type === 'event') && (
                      <>
                        <div className="event-time">
                          {ev.isAllDay
                            ? t('All day', 'All day')
                            : `${ev.startDateTime.toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                              })} - ${ev.endDateTime.toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                              })}`}
                        </div>
                        {ev.location && <div className="event-location">{ev.location}</div>}
                        {ev.description && <div className="event-description">{ev.description}</div>}
                      </>
                    )}
                    {ev.type === 'message' && (
                      <>
                        <div className="event-time">
                          {ev.startDateTime.toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                        </div>
                        {ev.description && (
                          <div className="event-description">
                            <strong>{t('calendar.scheduledMessageContent', 'Message:')}</strong> {ev.description}
                          </div>
                        )}
                      </>
                    )}
                    {ev.type === 'birthday' && (
                      <div className="event-time">
                        {t('All day', 'All day')}
                      </div>
                    )}
                    {ev.type === 'liturgy' && (
                      <div className="event-time">
                        {t('All day', 'All day')}
                      </div>
                    )}
                  </div>
                  {ev.type === 'event' && userRole.roleName === 'Admin' && (
                    <button
                      className="edit-button"
                      onClick={() => {
                        handleEditEvent(ev, selectedDate);
                        setSelectedDate(null);
                      }}
                    >
                      {t('calendar.edit', 'Edit')}
                    </button>
                  )}
                </div>
              );
            })}
          </div>
          {userRole.roleName === 'Admin' && (
            <button
              className="add-event-button"
              onClick={() => {
                setSelectedDate(null);
                setModalTitle(t('calendar.addEvent', 'Add Event'));
                setModalContent(
                  <EventForm
                    initialData={{ startDateTime: parseDateKey(selectedDate) }}
                    onSave={async (eventData) => {
                      try {
                        await addEvent(eventData);
                        setShowModal(false);
                        showToast(t('calendar.eventAdded', 'Event added successfully'), 'success');
                      } catch (error) {
                        console.error('Error adding event:', error);
                        showToast(t('calendar.errorAddingEvent', 'Error adding event'), 'error');
                      }
                    }}
                    onCancel={() => setShowModal(false)}
                  />
                );
                setShowModal(true);
              }}
            >
              {t('calendar.addEvent', 'Add Event')}
            </button>
          )}
        </div>
      </div>,
      eventBarRoot
    );
  };

  const filteredEvents = (array) => {
    return array.filter(ev => {
      if (ev.type === 'event' && !showEvents) return false;
      if (ev.type === 'message' && !showCommunications) return false;
      if (ev.type === 'birthday' && !showBirthdays) return false;
      if (ev.type === 'liturgy' && !showLiturgyEvents) return false;
      return true;
    });
  };

  // We'll use `nowTime` to capture the current moment once.
  const nowTime = now;
  // Also define "start of today" for comparing all-day events
  const startOfToday = new Date(nowTime.getFullYear(), nowTime.getMonth(), nowTime.getDate());

  const getEventIcon = (ev) => {
    let icon = faCalendarAlt;
    let iconStyle = {};

    if (ev.type === 'event') {
      icon = faCalendarAlt;
      iconStyle = { color: '#fff' };
    } else if (ev.type === 'message') {
      icon = faEnvelope;
      iconStyle = { color: '#fff' };
    } else if (ev.type === 'birthday') {
      icon = faBirthdayCake;
      iconStyle = { color: '#fff' };
    } else if (ev.type === 'liturgy') {
      icon = faChurch;
      if (ev.color === '#FFFFFF') {
        iconStyle = { color: 'black' };
      } else {
        iconStyle = { color: '#fff' };
      }
    }

    return <FontAwesomeIcon icon={icon} className="item-icon" style={iconStyle} />;
  };

  return (
    <>
      <div className="calendar-page-container">
        <div className="header-container">
          <h2>{t('calendar.title', 'Calendar')}</h2>
          {userRole.roleName === 'Admin' && (
            <div className="config-button">
              <div className="menu-container" ref={menuRef}>
                <ActionButton
                  onClick={() => setMenuVisible(!menuVisible)}
                  icon="faPlus"
                  variant="secondary"
                  label={t('add', 'Add')}
                  isMobile={false}
                />
                {menuVisible && (
                  <div className="dropdown-menu">
                    <button onClick={handleAddEvent}>
                      <FontAwesomeIcon icon={faCalendarAlt} className="menu-icon" />
                      {t('calendar.addEvent', 'Add Event')}
                    </button>
                    <button onClick={handleAddCommunication}>
                      <FontAwesomeIcon icon={faEnvelope} className="menu-icon" />
                      {t('calendar.addCommunication', 'Add Communication')}
                    </button>
                    <button onClick={handleAddPost}>
                      <FontAwesomeIcon icon={faStickyNote} className="menu-icon" />
                      {t('calendar.addPost', 'Add Post')}
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="calendar-navigation">
          <button className="nav-button" onClick={handlePrevMonth}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <span className="current-month">
            {currentDate.toLocaleString('default', {
              month: 'long',
              year: 'numeric',
            })}
          </span>
          <button className="nav-button" onClick={handleNextMonth}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>

        <div className="calendar-table-container">
          <table className="calendar-table">
            <thead>
              <tr>
                <th>{t('calendar.sunday', 'Sun')}</th>
                <th>{t('calendar.monday', 'Mon')}</th>
                <th>{t('calendar.tuesday', 'Tue')}</th>
                <th>{t('calendar.wednesday', 'Wed')}</th>
                <th>{t('calendar.thursday', 'Thu')}</th>
                <th>{t('calendar.friday', 'Fri')}</th>
                <th>{t('calendar.saturday', 'Sat')}</th>
              </tr>
            </thead>
            <tbody>
              {calendar.map((week, i) => (
                <tr key={i}>
                  {week.map((date, j) => {
                    const dateKey = formatDateKey(date);
                    let eventsForDate = eventMap[dateKey] || [];

                    // Sort events by start time
                    eventsForDate.sort((a,b) => a.startDateTime - b.startDateTime);

                    // Filter by toggles
                    eventsForDate = filteredEvents(eventsForDate);

                    const isCurrentMonth = date.getMonth() === currentDate.getMonth();
                    const today = new Date();
                    const isToday =
                      date.getDate() === today.getDate() &&
                      date.getMonth() === today.getMonth() &&
                      date.getFullYear() === today.getFullYear();

                    // Truncation logic
                    let eventsToShow = eventsForDate;
                    let extraCount = 0;
                    if (eventsForDate.length > 3) {
                      eventsToShow = eventsForDate.slice(0,2);
                      extraCount = eventsForDate.length - 2;
                    }

                    return (
                      <td
                        key={j}
                        className={`${isCurrentMonth ? 'current-month' : 'other-month'} ${
                          isToday ? 'today' : ''
                        }`}
                        onClick={() => handleDateClick(date)}
                      >
                        <div className="date-number">{date.getDate()}</div>
                        {eventsToShow.map((ev) => {
                          // --- NEW LOGIC FOR PAST EVENT ---
                          // If it's all day, consider it "past" only if the calendar day is strictly before today.
                          // Otherwise, use the old time-based comparison for non-all-day events.
                          let isPastEvent;
                          if (ev.isAllDay) {
                            // Compare only the date portion
                            const eventDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                            isPastEvent = eventDay < startOfToday; 
                          } else {
                            // If not all day, compare endDateTime to 'now'
                            isPastEvent = ev.endDateTime < nowTime;
                          }

                          const eventIcon = getEventIcon(ev);

                          const customStyle =
                            ev.color === '#FFFFFF'
                              ? { backgroundColor: ev.color, color: 'black', border: '1px solid black' }
                              : { backgroundColor: ev.color || 'var(--main-color)', color: '#fff' };

                          return (
                            <div
                              key={`${ev.id}-${dateKey}`}
                              className={`event ${isPastEvent ? 'past-event' : ''}`}
                              style={customStyle}
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedDate(dateKey);
                                setSelectedEvents([ev]);
                              }}
                            >
                              {eventIcon} {ev.name}
                            </div>
                          );
                        })}
                        {extraCount > 0 && (
                          <div
                            className="event extra-count"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedDate(dateKey);
                              setSelectedEvents(eventsForDate);
                            }}
                          >
                            +{extraCount}
                          </div>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Toggles for filtering */}
        <div className="filters-container">
          <ToggleSwitch
            id="showEvents"
            checked={showEvents}
            onChange={(checked) => setShowEvents(checked)}
            label={t('calendar.showEvents', 'Show Events')}
            labelPosition="right"
          />
          <ToggleSwitch
            id="showCommunications"
            checked={showCommunications}
            onChange={(checked) => setShowCommunications(checked)}
            label={t('calendar.showCommunications', 'Show Communications')}
            labelPosition="right"
          />
          <ToggleSwitch
            id="showBirthdays"
            checked={showBirthdays}
            onChange={(checked) => setShowBirthdays(checked)}
            label={t('calendar.showBirthdays', 'Show Birthdays')}
            labelPosition="right"
          />
          <ToggleSwitch
            id="showLiturgy"
            checked={showLiturgyEvents}
            onChange={(checked) => setShowLiturgyEvents(checked)}
            label={t('calendar.showLiturgyEvents', 'Show Liturgy Events')}
            labelPosition="right"
          />
        </div>
      </div>

      <CustomModal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setModalContent(null);
          setModalTitle('');
        }}
        title={modalTitle}
      >
        {modalContent}
      </CustomModal>

      <ToastContainer toasts={toasts} removeToast={removeToast} />

      {renderEventBottomBar()}
    </>
  );
};

export default CalendarPage;
