// src/components/Main/TabNavigation.jsx

import React from 'react';
import './TabNavigation.css';
import { useTranslation } from 'react-i18next';

const TabNavigation = ({
  currentTab,
  onTabChange,
  userRole,
  isTransitioning,
  showGroupsTab,
}) => {
  const { t } = useTranslation();

  const tabs = [
    { name: 'members', label: t('tabNavigation.members'), visible: true },
    {
      name: 'groups',
      label: t('tabNavigation.groups'),
      adminOnly: true,
      visible: showGroupsTab,
    },
    {
      name: 'teams',
      label: t('tabNavigation.teams'), // Add Teams tab
      adminOnly: true,
      visible: true,
    },
    {
      name: 'communications',
      label: t('tabNavigation.communications'),
      adminOnly: true,
      visible: true,
    },
    {
      name: 'forms',
      label: t('tabNavigation.forms'),
      adminOnly: true,
      visible: true,
    },
    {
      name: 'calendar',
      label: t('tabNavigation.calendar'), // Add Calendar tab
      adminOnly: false,
      visible: true,
    },
    {
      name: 'settings',
      label: t('tabNavigation.settings'),
      adminOnly: true,
      visible: true,
    },
    { name: 'users', label: t('tabNavigation.users'), adminOnly: true, visible: true },
  ];

  const isAdmin = userRole.roleName === 'Admin';

  return (
    <nav className="tab-navigation" aria-label={t('tabNavigation.ariaLabel')}>
      {tabs.map((tab) => {
        if (tab.adminOnly && !isAdmin) return null;
        if (!tab.visible) return null;
        return (
          <button
            key={tab.name}
            onClick={() => onTabChange(tab.name)}
            className={currentTab === tab.name ? 'active-tab' : ''}
            disabled={isTransitioning}
            aria-current={currentTab === tab.name ? 'page' : undefined}
          >
            {tab.label}
          </button>
        );
      })}
    </nav>
  );
};

export default TabNavigation;
