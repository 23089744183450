// src/contexts/EventContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { firestore } from '../firebase';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  getDoc,
  orderBy,
  limit,
} from 'firebase/firestore';
import { UserContext } from './UserContext';

export const EventContext = createContext();

export const EventProvider = ({ children }) => {
  const { organizationData, currentUser } = useContext(UserContext);
  const [events, setEvents] = useState([]);
  const [isEventsLoading, setIsEventsLoading] = useState(false);
  const [isEventsError, setIsEventsError] = useState(null);

  // Helper: remove undefined fields from an object
  const removeUndefined = (obj) =>
    Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== undefined));

  /**
   * Returns the start/end date for the ±1 year coverage window,
   * anchored at the first day of the current month.
   */
  const getCoverageWindow = () => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    // Start = 1 year before
    const startOfWindow = new Date(startOfMonth);
    startOfWindow.setFullYear(startOfWindow.getFullYear() - 1);

    // End = 1 year after
    const endOfWindow = new Date(startOfMonth);
    endOfWindow.setFullYear(endOfWindow.getFullYear() + 1);

    return { startOfWindow, endOfWindow };
  };

  /**
   * Fetch normal (non-liturgy) events from Firestore that belong to the user's org.
   */
  const fetchOrgEventsFromFirestore = async () => {
    if (!organizationData?.organizationId || !organizationData?.groupId) {
      console.error('Organization data is not available.');
      return [];
    }
    if (!currentUser) {
      console.error('User is not authenticated.');
      return [];
    }

    try {
      const eventsCollectionRef = collection(firestore, 'events');
      // Query events that match the user's organizationId, status is 'published' or 'draft'
      const orgEventsQuery = query(
        eventsCollectionRef,
        where('organizationId', '==', organizationData.organizationId),
        where('status', 'in', ['published', 'draft'])
      );

      const snapshot = await getDocs(orgEventsQuery);
      const orgEvents = snapshot.docs
        .map((docSnap) => {
          const data = docSnap.data();
          // Skip draft events not created by the current user
          if (data.status === 'draft' && data.userId !== currentUser.uid) return null;

          // Visibility logic:
          //   closed => must match groupId
          //   open => org-wide
          if (data.visibility === 'closed' && data.groupId !== organizationData.groupId) {
            return null;
          } else if (data.visibility === 'open') {
            // org-wide
          } else if (!data.visibility) {
            // If visibility is undefined/invalid, exclude
            return null;
          }

          return {
            id: docSnap.id,
            ...data,
            startDateTime: data.startDateTime?.toDate() || null,
            endDateTime: data.endDateTime?.toDate() || null,
            recurringDays: data.recurringDays ? data.recurringDays.map(String) : [],
            exceptionDates: data.exceptionDates || [],
            type: data.type || 'event',
          };
        })
        .filter(Boolean);

      return orgEvents;
    } catch (error) {
      console.error('Error fetching org events:', error);
      setIsEventsError(error);
      return [];
    }
  };

  /**
   * Fetch *global* liturgical events from Firestore. 
   *   - They have `organizationId = 'global'`
   *   - `type = 'liturgy'`
   *   - `visibility = 'open'`
   *   - status in ['published', 'draft'] if needed (usually 'published').
   */
  const fetchGlobalLiturgyEventsFromFirestore = async () => {
    try {
      const eventsCollectionRef = collection(firestore, 'events');
      const litQuery = query(
        eventsCollectionRef,
        where('organizationId', '==', 'global'),
        where('type', '==', 'liturgy'),
        where('visibility', '==', 'open'),
        where('status', 'in', ['published', 'draft'])
      );
      const snapshot = await getDocs(litQuery);

      const globalLitEvents = snapshot.docs.map((docSnap) => {
        const data = docSnap.data();
        return {
          id: docSnap.id,
          ...data,
          startDateTime: data.startDateTime?.toDate() || null,
          endDateTime: data.endDateTime?.toDate() || null,
          recurringDays: data.recurringDays ? data.recurringDays.map(String) : [],
          exceptionDates: data.exceptionDates || [],
          type: 'liturgy', // ensure type is liturgy
        };
      });
      return globalLitEvents;
    } catch (error) {
      console.error('Error fetching global liturgy events:', error);
      setIsEventsError(error);
      return [];
    }
  };

  /**
   * Fetch a small range of liturgical events from the remote service for the given start/end dates.
   */
  const fetchLiturgicalEventsFromAPI = async (startDate, endDate) => {
    const requestData = {
      startYear: startDate.getFullYear(),
      startMonth: startDate.getMonth() + 1,
      endYear: endDate.getFullYear(),
      endMonth: endDate.getMonth() + 1,
      lang: 'en',
    };

    const response = await fetch('https://getliturgicalevents-lgfph5hmwq-uc.a.run.app', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      throw new Error('Error fetching liturgical events');
    }

    const result = await response.json();
    // Filter out generic "Week", "after", "Octave", "Weekday"
    const filtered = result.data.filter((ev) => {
      const text = ev.text || '';
      if (/\bweek\b/i.test(text) ||
          /\bafter\b/i.test(text) ||
          /\boctave\b/i.test(text) ||
          /\bweekday\b/i.test(text)) {
        return false;
      }
      return true;
    });

    // Convert into Firestore-friendly objects (without doc ID yet)
    return filtered.map((ev) => {
      const [year, month, day] = ev.date.split('-').map(Number);
      const eventDate = new Date(year, month - 1, day);
      return {
        name: ev.text,
        isAllDay: true,
        startDateTime: eventDate,
        endDateTime: eventDate,
        type: 'liturgy',
        color: ev.color || '#663399',
        description: ev.text || '',
        visibility: 'open',
        status: 'published',
        // organizationId: 'global' => We'll set this later
      };
    });
  };

  /**
   * Only store liturgical events globally (i.e. `organizationId = 'global'`).
   * Check the latest global-liturgy doc; if coverage is incomplete, fetch more from the API.
   * DEDUPING STRATEGY: each liturgy event is saved by a deterministic doc ID, e.g. "liturgy-YYYY-MM-DD-text".
   */
  const importLiturgicalEventsIfNeeded = async () => {
    if (!currentUser) return; // Only do this if logged in

    const { startOfWindow, endOfWindow } = getCoverageWindow();
    const eventsCollectionRef = collection(firestore, 'events');

    // Query the *latest* global liturgy event
    const latestLitQuery = query(
      eventsCollectionRef,
      where('organizationId', '==', 'global'),
      where('type', '==', 'liturgy'),
      where('visibility', '==', 'open'),
      orderBy('endDateTime', 'desc'),
      limit(1)
    );

    const litSnapshot = await getDocs(latestLitQuery);

    let fetchStart = startOfWindow;
    if (!litSnapshot.empty) {
      // We already have some liturgy event(s)
      const lastLitData = litSnapshot.docs[0].data();
      const lastEnd = lastLitData.endDateTime?.toDate() || startOfWindow;
      if (lastEnd >= endOfWindow) {
        // Already have coverage
        return;
      } else {
        // We need more coverage from lastEnd + 1 day onward
        const nextDay = new Date(lastEnd);
        nextDay.setDate(nextDay.getDate() + 1);
        if (nextDay > fetchStart) {
          fetchStart = nextDay;
        }
      }
    }

    // If the fetchStart is already beyond endOfWindow, skip
    if (fetchStart >= endOfWindow) return;

    // Fetch missing coverage from the API
    try {
      const missingLitEvents = await fetchLiturgicalEventsFromAPI(fetchStart, endOfWindow);
      
      // Insert them as global events. Use deterministic doc IDs to avoid duplicates:
      for (const litEvent of missingLitEvents) {
        const eventDate = litEvent.startDateTime;
        // For instance: "liturgy-YYYY-MM-DD-textWithoutSpaces"
        // You can hash or sanitize event text if needed, but keep it consistent
        const dateString = eventDate.toISOString().split('T')[0]; // "YYYY-MM-DD"
        const sanitizedText = (litEvent.name || '').replace(/\s+/g, '-').toLowerCase();
        const docId = `liturgy-${dateString}-${sanitizedText}`;

        const docRef = doc(eventsCollectionRef, docId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          // Already inserted => skip
          continue;
        }

        // Insert only if not present
        const cleaned = removeUndefined({
          ...litEvent,
          organizationId: 'global',  // <-- Key to store them globally
          userId: currentUser.uid,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        await setDoc(docRef, cleaned);  // setDoc with a known docId => no duplication
      }
    } catch (error) {
      console.error('Error importing liturgical events:', error);
      setIsEventsError(error);
    }
  };

  /**
   * Refresh events:
   *  1) Possibly import new liturgy events (global) if coverage incomplete
   *  2) Fetch org-scoped events + global liturgy events, then merge
   */
  const refreshEvents = async () => {
    setIsEventsLoading(true);
    try {
      await importLiturgicalEventsIfNeeded();  // Ensure global coverage

      const [orgEvents, globalLiturgyEvents] = await Promise.all([
        fetchOrgEventsFromFirestore(),
        fetchGlobalLiturgyEventsFromFirestore(),
      ]);

      // Merge them so liturgy events appear alongside the org events
      setEvents([...orgEvents, ...globalLiturgyEvents]);
    } catch (error) {
      console.error('Error refreshing events:', error);
      setIsEventsError(error);
    } finally {
      setIsEventsLoading(false);
    }
  };

  // Add normal event (still bound to user’s org)
  const addEvent = async (eventData) => {
    if (!eventData || !eventData.name) {
      throw new Error('Event data is required');
    }
    if (!currentUser) {
      throw new Error('User is not authenticated');
    }
    if (!organizationData?.organizationId || !organizationData?.groupId) {
      throw new Error('Organization data is not available.');
    }
    try {
      const eventsCollectionRef = collection(firestore, 'events');
      const cleanedEventData = removeUndefined(eventData);

      // Normal (non-liturgy) event belongs to the user's org
      const newEvent = {
        ...cleanedEventData,
        organizationId: organizationData.organizationId,
        groupId: organizationData.groupId,
        userId: currentUser.uid,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        status: cleanedEventData.status || 'published',
        visibility: cleanedEventData.visibility || 'closed',
      };
      await addDoc(eventsCollectionRef, newEvent);
      await refreshEvents();
    } catch (error) {
      console.error('Error adding event:', error);
      throw error;
    }
  };

  // Edit event
  const editEvent = async (eventId, eventData) => {
    if (!eventId || !eventData) {
      throw new Error('Event ID and data are required');
    }
    if (!currentUser) {
      throw new Error('User is not authenticated');
    }
    try {
      const eventDocRef = doc(firestore, 'events', eventId);
      const cleanedEventData = removeUndefined(eventData);

      await updateDoc(eventDocRef, {
        ...cleanedEventData,
        userId: currentUser.uid,
        updatedAt: serverTimestamp(),
      });
      await refreshEvents();
    } catch (error) {
      console.error('Error editing event:', error);
      throw error;
    }
  };

  // Delete a single occurrence from a recurring event
  const deleteEventOccurrence = async (eventId, occurrenceDate) => {
    if (!eventId || !occurrenceDate) {
      throw new Error('Event ID and occurrence date are required');
    }
    try {
      const eventDocRef = doc(firestore, 'events', eventId);
      const eventSnapshot = await getDoc(eventDocRef);
      if (!eventSnapshot.exists()) {
        throw new Error('Event not found');
      }
      const eventData = eventSnapshot.data();
      const exceptionDates = eventData.exceptionDates || [];

      // Add the occurrence date to exceptionDates
      exceptionDates.push(occurrenceDate);

      await updateDoc(eventDocRef, {
        exceptionDates,
        updatedAt: serverTimestamp(),
      });
      await refreshEvents();
    } catch (error) {
      console.error('Error deleting event occurrence:', error);
      throw error;
    }
  };

  // Delete event entirely
  const deleteEvent = async (eventId) => {
    if (!eventId) {
      throw new Error('Event ID is required');
    }
    try {
      const eventDocRef = doc(firestore, 'events', eventId);
      await deleteDoc(eventDocRef);
      await refreshEvents();
    } catch (error) {
      console.error('Error deleting event:', error);
      throw error;
    }
  };

  // On load, fetch everything (org + liturgy)
  useEffect(() => {
    if (organizationData && organizationData.groupId && currentUser) {
      refreshEvents();
    }
  }, [organizationData, currentUser]);

  return (
    <EventContext.Provider
      value={{
        events,
        isEventsLoading,
        isEventsError,
        fetchEvents: refreshEvents,
        addEvent,
        editEvent,
        deleteEvent,
        deleteEventOccurrence,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export default EventProvider;
