import React from 'react';
import PropTypes from 'prop-types';
import './Tabs.css';

const Tabs = ({ tabs, selectedTab, onTabSelect }) => {
  return (
    <div className="tabs">
      {tabs.map((tab) => (
        <div
          key={tab.id}
          className={`tab ${selectedTab === tab.id ? 'active' : ''}`}
          onClick={() => onTabSelect(tab.id)}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onTabSelect(tab.id);
            }
          }}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};

Tabs.propTypes = {
  /**
   * An array of tab objects: [{ id: string, label: string }, ...]
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,

  /**
   * The currently active tab ID
   */
  selectedTab: PropTypes.string.isRequired,

  /**
   * Callback when a tab is selected
   */
  onTabSelect: PropTypes.func.isRequired,
};

export default Tabs;
