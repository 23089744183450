// src/components/Authentication/SignUpSteps/Step4Form.jsx

import React, { useState } from 'react';
import SelectField from '../../FormBuilder/Fields/SelectField'; // Import the SelectField component
import ColorPicker from '../../FormBuilder/Fields/ColorPicker'; // Import the ColorPicker component
import Spinner from '../../Common/Spinner';
import { useTranslation } from 'react-i18next';

const Step4Form = ({ onNext, onBack, formData }) => {
  const { t } = useTranslation();

  const [organizationName, setOrganizationName] = useState(formData.organizationName || '');
  const [addressLine1, setAddressLine1] = useState(formData.addressLine1 || '');
  const [addressLine2, setAddressLine2] = useState(formData.addressLine2 || '');
  const [city, setCity] = useState(formData.city || '');
  const [state, setState] = useState(formData.state || '');
  const [zipCode, setZipCode] = useState(formData.zipCode || '');
  const [country, setCountry] = useState(formData.country || '');
  const [timezone, setTimezone] = useState(formData.timezone || '');
  const [language, setLanguage] = useState(formData.language || '');
  const [color, setColor] = useState(formData.color || '#000000');
  const [subdomain, setSubdomain] = useState(formData.subdomain || '');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Save the form data and proceed to the next step
    const organizationData = {
      organizationName,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      country,
      timezone,
      language,
      color,
      subdomain,
    };

    try {
      onNext(organizationData);
    } catch (error) {
      console.error('Error proceeding to summary:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h2>{t('signUp.step4Title')}</h2>
      <form onSubmit={handleSubmit} className="sign-up-form">
        {/* Organization Name - Full Width */}
        <div className="sign-up-input-group full-width">
          <label htmlFor="organizationName">{t('signUp.organizationNameLabel')}</label>
          <input
            id="organizationName"
            type="text"
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            placeholder={t('signUp.organizationNamePlaceholder')}
            required
            aria-label={t('signUp.organizationNameLabel')}
          />
        </div>
        {/* Subdomain - Full Width */}
        <div className="sign-up-input-group full-width">
          <label htmlFor="subdomain">{t('signUp.subdomainLabel')}</label>
          <input
            id="subdomain"
            type="text"
            value={subdomain}
            onChange={(e) => setSubdomain(e.target.value)}
            placeholder={t('signUp.subdomainPlaceholder')}
            required
            aria-label={t('signUp.subdomainLabel')}
          />
        </div>
        {/* Address Fields - Half Width */}
        <div className="sign-up-input-row">
          <div className="sign-up-input-group half-width">
            <label htmlFor="addressLine1">{t('signUp.addressLine1Label')}</label>
            <input
              id="addressLine1"
              type="text"
              value={addressLine1}
              onChange={(e) => setAddressLine1(e.target.value)}
              placeholder={t('signUp.addressLine1Placeholder')}
              required
              aria-label={t('signUp.addressLine1Label')}
            />
          </div>
          <div className="sign-up-input-group half-width">
            <label htmlFor="addressLine2">{t('signUp.addressLine2Label')}</label>
            <input
              id="addressLine2"
              type="text"
              value={addressLine2}
              onChange={(e) => setAddressLine2(e.target.value)}
              placeholder={t('signUp.addressLine2Placeholder')}
              aria-label={t('signUp.addressLine2Label')}
            />
          </div>
        </div>
        {/* Country and State */}
        <div className="sign-up-input-row">
          <div className="sign-up-input-group half-width">
            <SelectField
              label={t('signUp.countryLabel')}
              name="country"
              value={country}
              onChange={setCountry}
              placeholder={t('signUp.selectCountryPlaceholder')}
              catalogId="countries"
              required
            />
          </div>
          <div className="sign-up-input-group half-width">
            <SelectField
              label={t('signUp.stateLabel')}
              name="state"
              value={state}
              onChange={setState}
              placeholder={t('signUp.selectStatePlaceholder')}
              catalogId="states"
              required
            />
          </div>
        </div>
        {/* City and Zip Code */}
        <div className="sign-up-input-row">
          <div className="sign-up-input-group half-width">
            <label htmlFor="city">{t('signUp.cityLabel')}</label>
            <input
              id="city"
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              placeholder={t('signUp.cityPlaceholder')}
              required
              aria-label={t('signUp.cityLabel')}
            />
          </div>
          <div className="sign-up-input-group half-width">
            <label htmlFor="zipCode">{t('signUp.zipCodeLabel')}</label>
            <input
              id="zipCode"
              type="text"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              placeholder={t('signUp.zipCodePlaceholder')}
              required
              aria-label={t('signUp.zipCodeLabel')}
            />
          </div>
        </div>
        {/* Timezone and Language */}
        <div className="sign-up-input-row">
          <div className="sign-up-input-group half-width">
            <SelectField
              label={t('signUp.timezoneLabel')}
              name="timezone"
              value={timezone}
              onChange={setTimezone}
              placeholder={t('signUp.selectTimezonePlaceholder')}
              catalogId="timezones"
              required
            />
          </div>
          <div className="sign-up-input-group half-width">
            <SelectField
              label={t('signUp.languageLabel')}
              name="language"
              value={language}
              onChange={setLanguage}
              placeholder={t('signUp.selectLanguagePlaceholder')}
              catalogId="languages"
              required
            />
          </div>
        </div>
        {/* Color Picker - Half Width */}
        <div className="sign-up-input-row">
          <div className="sign-up-input-group half-width">
            {/* Use the ColorPicker component */}
            <ColorPicker
              label={t('signUp.colorLabel')}
              value={color}
              onChange={setColor}
              required
            />
          </div>
          <div className="sign-up-input-group half-width">
            {/* Empty to balance the row */}
          </div>
        </div>
        <button type="submit" className="sign-up-submit-button" disabled={loading}>
          {loading ? <Spinner /> : t('signUp.nextButton')}
        </button>
        <button type="button" className="sign-up-back-button" onClick={() => onBack({})}>
          {t('signUp.backButton')}
        </button>
      </form>
    </>
  );
};

export default Step4Form;
