// src/components/Main/MainScreen.jsx

import React, { useState, useContext } from 'react';
import { DataContext } from '../../DataContext';
import MembersPage from '../Members/MembersPage';
import Settings from '../Settings/Settings';
import Users from '../Users/Users';
import Header from './Header';
import Footer from './Footer';
import MainContent from './MainContent';
import { useColorSettings } from '../Common/ColorSettingsContext';
import { useTranslation } from 'react-i18next';
import './MainScreen.css';
import GroupsPage from '../Groups/GroupsPage';
import MessageSender from '../Communications/MessageSender';
import FormsPage from '../FormBuilder/FormsPage';
import TeamsPage from '../Teams/TeamsPage'; // Import the Teams component
import CalendarPage from '../Calendar/CalendarPage'; 

const MainScreen = () => {
  const { t } = useTranslation();
  const { appSettings } = useColorSettings();
  const { userRole, organizationData } = useContext(DataContext);

  const [isTransitioning, setIsTransitioning] = useState(false);
  const [currentTab, setCurrentTab] = useState('members');
  const [nextTab, setNextTab] = useState(null);

  const handleTabChange = (tab) => {
    if (tab === currentTab || isTransitioning) return;
    setNextTab(tab);
    setIsTransitioning(true);
  };

  React.useEffect(() => {
    if (isTransitioning && nextTab) {
      const timeout = setTimeout(() => {
        setCurrentTab(nextTab);
        setNextTab(null);
        setIsTransitioning(false);
      }, 300);
      return () => clearTimeout(timeout);
    }
  }, [isTransitioning, nextTab]);

  // Determine if the Groups tab should be visible
  const showGroupsTab = organizationData.groupId === organizationData.organizationId;

  return (
    <div className="main-screen-app">
      <Header
        currentTab={currentTab}
        onTabChange={handleTabChange}
        isTransitioning={isTransitioning}
        showGroupsTab={showGroupsTab}
      />

      <MainContent
        currentTab={currentTab}
        isTransitioning={isTransitioning}
        components={{
          members: <MembersPage />,
          groups: showGroupsTab ? <GroupsPage /> : null,
          teams: <TeamsPage />,
          communications: <MessageSender showHistory={true} />,
          forms: <FormsPage />,
          calendar: <CalendarPage />,
          settings: <Settings />,
          users: <Users />,

        }}
      />

      <Footer />
    </div>
  );
};

export default MainScreen;
