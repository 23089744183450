// src/contexts/OrganizationContext.js

import React, { createContext } from 'react';

export const OrganizationContext = createContext();

export const OrganizationProvider = ({ children }) => {
  /**
   * Function to create the organization, groups, role groups, form groups,
   * and then create the user, assigning the correct data.
   */
  const createOrganizationWithUser = async (userData, organizationData) => {
    const { email, firstName, lastName, password } = userData;

    try {
      // Call the signUp cloud function
      const signUpResponse = await fetch('https://sign-up-lgfph5hmwq-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          firstName,
          lastName,
          password,
          organizationData,
        }),
      });

      if (!signUpResponse.ok) {
        const errorData = await signUpResponse.text();
        throw new Error(`Failed to sign up: ${errorData || 'Unknown error'}`);
      }

      const { uid, organizationId, groupId } = await signUpResponse.json();

      return { uid, organizationId, groupId };
    } catch (error) {
      console.error('Error during sign-up:', error);
      throw error;
    }
  };

  return (
    <OrganizationContext.Provider
      value={{
        createOrganizationWithUser,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};
