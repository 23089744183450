// config.js

import CatholicoreLogo from './assets/CatholicoreLogo.png';

export const defaultColors = {
  main: '#bb8300',
};

export const defaultLanguage = {
  main: 'en',
};

export const defaultLogo = {
  main: CatholicoreLogo,
};
