// src/contexts/CatalogContext.js

import React, { createContext, useContext } from 'react';
import { firestore } from '../firebase';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { UserContext } from './UserContext';

export const CatalogContext = createContext();

export const CatalogProvider = ({ children }) => {
  const { organizationData } = useContext(UserContext);

  /**
   * Function to fetch catalog data for given catalogIds
   */
  const fetchCatalogs = async (catalogIds) => {
    try {
      const fetchedCatalogData = {};
      for (const catalogId of catalogIds) {
        const catalogDocRef = doc(firestore, 'catalog', catalogId);
        const catalogDocSnap = await getDoc(catalogDocRef);
        if (catalogDocSnap.exists()) {
          const catalog = catalogDocSnap.data();
          fetchedCatalogData[catalogId] = catalog;
        } else {
          console.warn(`Catalog with ID ${catalogId} does not exist.`);
        }
      }
      return fetchedCatalogData;
    } catch (error) {
      console.error('Error fetching catalogs:', error);
      throw error;
    }
  };

  /**
   * Function to fetch all catalogs
   */
  const fetchAllCatalogs = async () => {
    try {
      const catalogsCollection = collection(firestore, 'catalog');
      const snapshot = await getDocs(catalogsCollection);
      const fetchedCatalogs = snapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name, // Assuming each catalog has a 'name' field
      }));
      return fetchedCatalogs;
    } catch (error) {
      console.error('Error fetching catalogs:', error);
      throw error;
    }
  };

  /**
   * Function to fetch catalog options by catalogId
   */
  const fetchCatalogOptions = async (catalogId) => {
    try {
      if (!catalogId) {
        throw new Error('Catalog ID is required to fetch catalog options.');
      }

      const catalogDocRef = doc(firestore, 'catalog', catalogId);
      const catalogDocSnap = await getDoc(catalogDocRef);
      if (catalogDocSnap.exists()) {
        const catalogData = catalogDocSnap.data();
        if (Array.isArray(catalogData.codes)) {
          const fetchedOptions = catalogData.codes.map((code) => ({
            value: code.value,
            label: code.description,
            icon: code.icon,
            code: code.code,
            order: code.order,
          }));
          return fetchedOptions;
        } else {
          console.warn('Catalog "codes" is not an array.');
          return [];
        }
      } else {
        console.warn('No such catalog document!');
        return [];
      }
    } catch (error) {
      console.error('Error fetching catalog options:', error);
      throw error;
    }
  };

  return (
    <CatalogContext.Provider
      value={{
        fetchCatalogs,
        fetchAllCatalogs,
        fetchCatalogOptions,
      }}
    >
      {children}
    </CatalogContext.Provider>
  );
};

export default CatalogProvider;