// src/utils/PDFUtility.js

import { saveAs } from 'file-saver';
import React from 'react';
import {
  Document,
  Page,
  Text,
  Image as PdfImage, // Alias the Image component
  View,
  StyleSheet,
  pdf,
  Font,
} from '@react-pdf/renderer';

// Register fonts
Font.register({
  family: 'Helvetica',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/helvetica/v6/6m1lZP1eYgSOzhaeRmJlA1aJelcFVJv2eVO2T2_DFGw.ttf',
      fontWeight: 'normal',
    },
    {
      src: 'https://fonts.gstatic.com/s/helvetica/v6/6m1lZP1eYgSOzhaeRmJlA1aJelcFVJv2eVO2T2_DFGw.ttf',
      fontWeight: 'bold',
    },
  ],
});

/**
 * Generates a PDF from the given responses and fields using react-pdf.
 * @param {Array} responses - Array of response objects.
 * @param {Array} fields - Array of field definitions.
 * @param {boolean} isIndividual - If true, generates individual PDFs; otherwise, combines all responses into one PDF.
 * @param {string} groupLogo - Base64 string of the group logo image (must be a valid PNG base64 data URI).
 * @param {string} formName - Name of the form.
 */
export const generatePDF = async (
  responses,
  fields,
  isIndividual = false,
  groupLogo = null,
  formName = 'Form'
) => {
  if (!responses || responses.length === 0) {
    console.warn('No responses to generate PDF.');
    return;
  }

  if (isIndividual) {
    // Generate individual PDFs
    for (const response of responses) {
      const blob = await pdf(
        <PDFDocument
          response={response}
          fields={fields}
          groupLogo={groupLogo}
          formName={formName}
        />
      ).toBlob();

      const fileName = `${formName.replace(/\s+/g, '_')}_${response.id}.pdf`;
      saveAs(blob, fileName);
    }
  } else {
    // Generate a single PDF with all responses
    const document = (
      <Document>
        {responses.map((response, index) => (
          <PDFPage
            key={index}
            response={response}
            fields={fields}
            groupLogo={groupLogo}
            formName={formName}
          />
        ))}
      </Document>
    );

    const blob = await pdf(document).toBlob();
    const fileName = `${formName.replace(/\s+/g, '_')}_responses.pdf`;
    saveAs(blob, fileName);
  }
};

/**
 * PDFDocument component for individual PDFs.
 * @param {Object} props - The component props.
 * @returns {React.Element} - The PDF document.
 */
const PDFDocument = ({ response, fields, groupLogo, formName }) => (
  <Document>
    <PDFPage
      response={response}
      fields={fields}
      groupLogo={groupLogo}
      formName={formName}
    />
  </Document>
);

/**
 * PDFPage component representing a single page in the PDF.
 * @param {Object} props - The component props.
 * @returns {React.Element} - The PDF page.
 */
const PDFPage = ({ response, fields, groupLogo, formName }) => {
  const styles = StyleSheet.create({
    page: {
      padding: 10,
      fontFamily: 'Helvetica',
    },
    logo: {
      height: 100,
      alignSelf: 'center',
      marginBottom: 10,
    },
    formName: {
      fontSize: 16,
      fontFamily: 'Helvetica-Bold',
      textAlign: 'center',
      marginBottom: 10,
    },
    separator: {
      borderBottomWidth: 1,
      borderBottomColor: '#999',
      marginBottom: 5,
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableCol: {
      flex: 1,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
    },
    tableCell: {
      margin: 5,
      fontSize: 10,
      fontFamily: 'Helvetica',
    },
    boldText: {
      fontFamily: 'Helvetica-Bold',
    },
  });

  // Prepare data for the table, excluding ImagePicker fields
  const tableData = response.fields
    .filter((field) => field.type !== 'ImagePicker')
    .map((field) => {
      const fieldDef = fields.find((f) => f.id === field.id);
      const value = formatFieldValue(field, fieldDef);

      return {
        label: field.label,
        value: value,
        isCheckbox: field.type === 'Checkbox',
        type: field.type,
      };
    });

  // Arrange data into table rows
  const tableRows = [];
  let i = 0;
  while (i < tableData.length) {
    const item = tableData[i];
    if (item.type === 'MultiRowControl') {
      // Add as a single row spanning all columns
      tableRows.push([
        {
          label: item.label,
          value: item.value,
          colSpan: 2,
          isCheckbox: item.isCheckbox,
        },
      ]);
      i++;
    } else {
      // Prepare a row with up to two items
      const row = [];
      // First item
      row.push({
        label: item.label,
        value: item.value,
        isCheckbox: item.isCheckbox,
      });

      // Second item, if available and not a MultiRowControl
      if (
        i + 1 < tableData.length &&
        tableData[i + 1].type !== 'MultiRowControl'
      ) {
        const item2 = tableData[i + 1];
        row.push({
          label: item2.label,
          value: item2.value,
          isCheckbox: item2.isCheckbox,
        });
        i += 2;
      } else {
        // Fill with empty cell
        row.push({ label: '', value: '' });
        i++;
      }

      tableRows.push(row);
    }
  }

  return (
    <Page size="A4" style={styles.page}>
      {groupLogo && (
        <PdfImage
          src={groupLogo}
          style={styles.logo}
        />
      )}
      <Text style={styles.formName}>{formName}</Text>
      <View style={styles.separator} />

      {/* Render the table data */}
      <View style={styles.table}>
        {tableRows.map((row, rowIndex) => (
          <View style={styles.tableRow} key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <View
                style={[
                  styles.tableCol,
                  cell.colSpan === 2 && { flex: 2 },
                ]}
                key={cellIndex}
              >
                <Text style={styles.tableCell}>
                  {cell.label && (
                    <Text style={styles.boldText}>{cell.label}:</Text>
                  )}{' '}
                  {cell.value}
                </Text>
              </View>
            ))}
          </View>
        ))}
      </View>
    </Page>
  );
};

/**
 * Converts a camelCase string to Capitalized Words.
 * @param {string} str - The camelCase string.
 * @returns {string} - The string converted to Capitalized Words.
 */
const camelCaseToCapitalizedWords = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/^./, function (s) {
      return s.toUpperCase();
    });
};

/**
 * Formats the field value for display in the PDF.
 * @param {Object} field - The field object.
 * @param {Object} fieldDef - The field definition.
 * @returns {string} - The formatted field value.
 */
const formatFieldValue = (field, fieldDef) => {
  const { value, type } = field;
  let formattedValue;

  switch (type) {
    case 'MultiRowControl':
      if (
        !value ||
        !Array.isArray(value) ||
        !fieldDef ||
        !fieldDef.rowFields
      ) {
        formattedValue = '';
        break;
      }
      // Convert the multi-row data to a string representation
      formattedValue = value
        .map((row, index) => {
          const rowValues = fieldDef.rowFields
            .map(
              (rowField) => `${rowField.label}: ${row[rowField.id] || ''}`
            )
            .join(', ');
          return `Row ${index + 1} : ${rowValues}`;
        })
        .join('\n');
      break;
    case 'Checkbox':
      if (Array.isArray(value)) {
        formattedValue = value
          .filter((option) => option.completed) // Keep only options where completed is true
          .map((option) => camelCaseToCapitalizedWords(option.name))
          .join(', ');
      } else {
        formattedValue = '';
      }
      break;
    case 'DatePicker':
      formattedValue = value ? new Date(value).toLocaleDateString() : '';
      break;
    case 'DateTimePicker':
    case 'TimePicker':
      formattedValue = value ? new Date(value).toLocaleString() : '';
      break;
    case 'ToggleSwitch':
      formattedValue = value ? 'Yes' : 'No';
      break;
    default:
      if (Array.isArray(value)) {
        formattedValue = value.join(', ');
      } else {
        formattedValue =
          value !== null && value !== undefined ? value.toString() : '';
      }
  }

  // Capitalize value except for emails
  if (
    fieldDef &&
    fieldDef.type !== 'Email' &&
    typeof formattedValue === 'string'
  ) {
    formattedValue = formattedValue
      .split('\n')
      .map((line) =>
        line
          .split(' ')
          .map(
            (word) =>
              word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(' ')
      )
      .join('\n');
  }

  return formattedValue;
};
