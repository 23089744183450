import React, { createContext, useContext, useState, useEffect } from 'react';
import { firestore } from '../firebase';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { UserContext } from './UserContext';
import axios from 'axios';

export const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const { organizationData, currentUser } = useContext(UserContext);
  const [messages, setMessages] = useState([]);
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);
  const [isMessagesError, setIsMessagesError] = useState(null);

  const fetchMessages = async () => {
    if (!organizationData || !organizationData.groupId || !currentUser) {
      return;
    }
    setIsMessagesLoading(true);
    try {
      const messagesCollectionRef = collection(firestore, 'messages');
      const messagesQuery = query(
        messagesCollectionRef,
        where('organizationId', '==', organizationData.organizationId),
        where('status', 'in', ['pending', 'sent', 'partial'])
      );

      const messagesSnapshot = await getDocs(messagesQuery);
      const messagesList = messagesSnapshot.docs.map((docSnap) => {
        const data = docSnap.data();
        return {
          id: docSnap.id,
          ...data,
          scheduledDateTime: data.scheduledDateTime ? data.scheduledDateTime.toDate() : null,
          createdAt: data.createdAt ? data.createdAt.toDate() : null,
          updatedAt: data.updatedAt ? data.updatedAt.toDate() : null,
        };
      });

      setMessages(messagesList);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setIsMessagesError(error);
    } finally {
      setIsMessagesLoading(false);
    }
  };

  const addMessage = ({
    title,
    message,
    image,
    recipients,
    recipientType,
    selectedGroups,
    selectedTeams,
    selectedMembers,
    isScheduled,
    scheduledDateTime,
  }) => {
    const toPhoneNumbersMap = {};
    recipients.forEach((r) => {
      toPhoneNumbersMap[r.phoneNumber] = {
        sent: false,
        name: r.name,
      };
    });

    const formData = new FormData();
    formData.append('title', title || '');
    formData.append('message', message);
    formData.append('fromPhoneNumber', '+19147329805');
    formData.append('toPhoneNumbers', JSON.stringify(toPhoneNumbersMap));
    formData.append('recipientType', recipientType);
    formData.append('selectedGroups', JSON.stringify(selectedGroups));
    formData.append('selectedTeams', JSON.stringify(selectedTeams));
    formData.append('selectedMembers', JSON.stringify(selectedMembers));
    formData.append('organizationId', organizationData.organizationId);
    formData.append('groupId', organizationData.groupId);
    formData.append('userId', currentUser.uid);

    if (isScheduled && scheduledDateTime) {
      formData.append('scheduledDateTime', Math.floor(scheduledDateTime.getTime() / 1000));
    }

    let imageUrl = null;
    if (image) {
      formData.append('image', image);
      imageUrl = URL.createObjectURL(image);
    }

    const newMessage = {
      id: 'local-' + Date.now(),
      title: title,
      content: message,
      imageUrl: imageUrl,
      toPhoneNumbers: toPhoneNumbersMap,
      messageType: isScheduled ? 'scheduled' : 'direct',
      status: isScheduled ? 'pending' : 'sent',
      createdAt: new Date(),
      scheduledDateTime: isScheduled ? scheduledDateTime : null,
    };

    setMessages((prevMessages) => [newMessage, ...prevMessages]);

    axios.post('https://createmessage-lgfph5hmwq-uc.a.run.app', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return { success: true, isScheduled };
  };

  const editMessage = async (messageId, messageData) => {
    if (!messageId || !messageData) {
      throw new Error('Message ID and data are required');
    }
    if (!currentUser) {
      throw new Error('User is not authenticated');
    }

    const payload = {
      docId: messageId,
      messageData
    };

    try {
      const response = await axios.post('https://editscheduledmessage-lgfph5hmwq-uc.a.run.app', payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      // After successful edit, refetch messages
      await fetchMessages();
    } catch (error) {
      console.error('Error editing message:', error);
      throw error;
    }
  };

  const deleteMessage = async (messageId) => {
    if (!messageId) {
      throw new Error('Message ID is required');
    }
    try {
      const messageDocRef = doc(firestore, 'messages', messageId);
      await deleteDoc(messageDocRef);
      await fetchMessages();
    } catch (error) {
      console.error('Error deleting message:', error);
      throw error;
    }
  };

  // New function to delete a scheduled message and its cron job before 5 mins of scheduled time
  const deleteScheduledMessage = async (messageId) => {
    if (!messageId) {
      throw new Error('Message ID is required');
    }

    const payload = { docId: messageId };

    try {
      const response = await axios.post('https://deletescheduledmessage-lgfph5hmwq-uc.a.run.app', payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      // After successful delete, refetch messages
      await fetchMessages();
    } catch (error) {
      console.error('Error deleting scheduled message:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (organizationData && organizationData.groupId && currentUser) {
      fetchMessages();
    }
  }, [organizationData, currentUser]);

  return (
    <MessageContext.Provider
      value={{
        messages,
        isMessagesLoading,
        isMessagesError,
        fetchMessages,
        addMessage,
        editMessage,
        deleteMessage,
        deleteScheduledMessage, // Exporting the new function
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export default MessageProvider;
