// src/components/Authentication/Login.jsx

import React, { useState, useContext, useEffect } from 'react';
import { Navigate, useNavigate, Link, useParams } from 'react-router-dom';
import CustomModal from '../Common/CustomModal';
import './Login.css';
import { useTranslation } from 'react-i18next';
import Spinner from '../Common/Spinner';
import CatholicoreLogo from '../../assets/CatholicoreLogo.png';
import CatholicoreLogoWhite from '../../assets/CatholicoreLogoWhite.png';

// Import contexts
import { DataContext } from '../../DataContext';
import { useColorSettings } from '../Common/ColorSettingsContext';
import { useLanguageSettings } from '../Common/LanguageSettingsContext';

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { groupId } = useParams(); // Extract groupId from route if present

  // Access DataContext
  const context = useContext(DataContext);

  // Access color settings
  const { loading: colorLoading, color } = useColorSettings();

  // Access language settings
  const { loading: languageLoading } = useLanguageSettings();

  // State Hooks
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Destructure context
  const {
    appSettingsLoading,
    appSettings,
    currentUser,
    currentUserLoading,
    currentUserError,
    signIn,
    userRoles,
    userRolesLoading,
  } = context;

  // Combined loading state
  const isLoading =
    currentUserLoading ||
    appSettingsLoading ||
    colorLoading ||
    languageLoading ||
    userRolesLoading;

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await signIn(email, password);
      // After successful sign-in, handle redirection in useEffect
    } catch (error) {
      console.error('Authentication Error:', error);
      // Handle error (e.g., show a modal or message)
    }
  };

  // Handle redirection after login
  useEffect(() => {
    if (
      currentUserLoading ||
      userRolesLoading ||
      userRoles === null ||
      currentUser === null
    ) {
      // Wait until loading is complete
      return;
    }

    const determineGroupIdAndRedirect = () => {
      if (currentUser && userRoles) {
        console.log('User Roles:', userRoles);
        if (groupId) {
          // User logged in from /{groupId}/login, redirect to that group
          navigate(`/${groupId}/main`, { replace: true });
        } else if (userRoles.length === 1) {
          // Only one role, navigate to that group
          const firstRoleEntry = userRoles[0];
          const userGroupIdRef = firstRoleEntry.groupId;
          const userGroupId = userGroupIdRef.id; // Assuming it's a DocumentReference
          navigate(`/${userGroupId}/main`, { replace: true });
        } else if (userRoles.length > 1) {
          // Multiple roles, navigate to OrganizationGroupSelectionPage
          navigate('/organization-selection', { replace: true });
        } else {
          // No roles assigned, navigate to a page that handles this case
          navigate('/organization-selection', { replace: true });
        }
      }
    };

    determineGroupIdAndRedirect();
  }, [
    currentUser,
    groupId,
    userRoles,
    currentUserLoading,
    userRolesLoading,
    navigate,
  ]);

  // Loading State
  if (isLoading) {
    return (
      <div
        className="login-background"
        style={{ backgroundColor: color || '#ffffff' }}
      >
        {/* White Logo Positioned at Top Left */}
        <div className="login-white-logo">
          <img src={CatholicoreLogoWhite} alt="Catholicore White Logo" />
        </div>
        <div className="login-container">
          <div className="login-logo">
            <img
              src={appSettings?.profilePicture || CatholicoreLogo}
              alt={t('login.logoAlt')}
            />
          </div>
          <h2>{t('login.title')}</h2>
          <Spinner />
        </div>
      </div>
    );
  }

  // Authentication Error State
  if (currentUserError) {
    return (
      <div
        className="login-background"
        style={{ backgroundColor: color || '#ffffff' }}
      >
        {/* White Logo Positioned at Top Left */}
        <div className="login-white-logo">
          <img src={CatholicoreLogoWhite} alt="Catholicore White Logo" />
        </div>
        <div className="login-container">
          <div className="login-logo">
            <img
              src={appSettings?.profilePicture || CatholicoreLogo}
              alt={t('login.logoAlt')}
            />
          </div>
          <h2>{t('login.title')}</h2>
          <p>{t('login.authError', { error: currentUserError.message })}</p>
        </div>
      </div>
    );
  }

  // Render the Login Form
  return (
    <div
      className="login-background"
      style={{ backgroundColor: color || '#ffffff' }}
    >
      {/* White Logo Positioned at Top Left */}
      <div className="login-white-logo">
        <a href="/">
          <img src={CatholicoreLogoWhite} alt="Catholicore White Logo" />
        </a>
      </div>
      <div className="login-container">
        <div className="login-logo">
          <img
            src={appSettings?.profilePicture || CatholicoreLogo}
            alt={t('login.logoAlt')}
          />
        </div>
        <h2>{t('login.title')}</h2>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="login-input-group">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t('login.emailPlaceholder')}
              required
              aria-label={t('login.emailLabel')}
            />
          </div>
          <div className="login-input-group">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t('login.passwordPlaceholder')}
              required
              minLength={6}
              aria-label={t('login.passwordLabel')}
            />
          </div>
          <button type="submit" className="login-submit-button">
            {t('login.submitButton')}
          </button>
        </form>
        <br />
        <h6
          onClick={() => {
            if (groupId) {
              navigate(`/${groupId}/password-recovery`);
            } else {
              navigate('/password-recovery');
            }
          }}
          className="login-forgot-password"
        >
          {t('login.forgotPassword')}
        </h6>
        <h6
          onClick={() => {
            navigate('/sign-up');
          }}
          className="login-create-account"
        >
          {t('login.createAccount')}
        </h6>
      </div>
      <CustomModal
        show={false}
        onClose={() => {}}
        title={t('login.modalTitle')}
      >
        {/* Handle modal content if needed */}
      </CustomModal>
      <footer className="login-footer">
        <div className="login-policy-links">
          <Link to="/privacy-policy" className="login-policy-link">
            Privacy Policy
          </Link>
          <span className="login-policy-separator">|</span>
          <Link to="/terms-of-service" className="login-policy-link">
            Terms of Service
          </Link>
        </div>
      </footer>
    </div>
  );
};

export default Login;
