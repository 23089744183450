import React, { useState, useContext } from 'react';
import { DataContext } from '../../../DataContext';
import Spinner from '../../Common/Spinner';
import { useTranslation } from 'react-i18next';

const Step1Form = ({ onNext, formData }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState(formData.email || '');
  const [loading, setLoading] = useState(false);
  const { sendValidationCode } = useContext(DataContext);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage(''); // Clear previous errors
    try {
      await sendValidationCode(email);
      onNext({ email });
    } catch (error) {
      console.error('Error sending validation code:', error);
      setErrorMessage(t('signUp.errorSendingCode'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h2>{t('signUp.step1Title')}</h2>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <form onSubmit={handleSubmit} className="sign-up-form">
        <div className="sign-up-input-group">
          <label htmlFor="email">{t('signUp.emailLabel')}</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrorMessage('');
            }}
            placeholder={t('signUp.emailPlaceholder')}
            required
            aria-label={t('signUp.emailLabel')}
          />
        </div>
        <button type="submit" className="sign-up-submit-button" disabled={loading}>
          {loading ? <Spinner /> : t('signUp.sendCodeButton')}
        </button>
      </form>
    </>
  );
};

export default Step1Form;
