// src/utils/firebaseUtils.js

import { getDoc } from 'firebase/firestore';

/**
 * Retrieves the document data from a given Firestore reference.
 *
 * @param {DocumentReference} reference - The Firestore document reference.
 * @returns {Promise<Object|null>} - A promise that resolves to the document data or null if it doesn't exist.
 */
export const getObjectByReference = async (reference) => {
  try {
    const docSnap = await getDoc(reference);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting document:', error);
    throw error;
  }
};
