// src/components/Authentication/SignUpSteps/Step5Form.jsx

import React, { useState, useContext } from 'react';
import Spinner from '../../Common/Spinner';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../../DataContext';

const Step5Form = ({ onNext, onBack, formData }) => {
  const { t } = useTranslation();
  const { createOrganizationWithUser } = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    setLoading(true);
    setErrorMessage('');

    const {
      email,
      firstName,
      lastName,
      password,
      organizationName,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      country,
      timezone,
      language,
      color,
      subdomain,
    } = formData;

    const userData = {
      email,
      firstName,
      lastName,
      password,
    };

    const organizationData = {
      organizationName,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      country,
      timezone,
      language,
      color,
      subdomain,
    };

    try {
      const response = await createOrganizationWithUser(userData, organizationData);
      console.log('Organization created successfully, moving to next step.');
      console.log(response);
      onNext({response}); // Proceed to the next step
    } catch (error) {
      console.error('Error creating organization:', error);
      setErrorMessage(t('signUp.errorCreatingOrganization'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h2>{t('signUp.step5Title')}</h2>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <div className="sign-up-summary">
        <h3>{t('signUp.reviewInformation')}</h3>
        <div className="summary-section">
          <h4>{t('signUp.personalInformation')}</h4>
          <table>
            <tbody>
              <tr>
                <th>{t('signUp.emailLabel')}</th>
                <td>{formData.email}</td>
              </tr>
              <tr>
                <th>{t('signUp.firstNameLabel')}</th>
                <td>{formData.firstName}</td>
              </tr>
              <tr>
                <th>{t('signUp.lastNameLabel')}</th>
                <td>{formData.lastName}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="summary-section">
          <h4>{t('signUp.organizationInformation')}</h4>
          <table>
            <tbody>
              <tr>
                <th>{t('signUp.organizationNameLabel')}</th>
                <td>{formData.organizationName}</td>
              </tr>
              <tr>
                <th>{t('signUp.subdomainLabel')}</th>
                <td>{formData.subdomain}</td>
              </tr>
              <tr>
                <th>{t('signUp.addressLine1Label')}</th>
                <td>{formData.addressLine1}</td>
              </tr>
              {formData.addressLine2 && (
                <tr>
                  <th>{t('signUp.addressLine2Label')}</th>
                  <td>{formData.addressLine2}</td>
                </tr>
              )}
              <tr>
                <th>{t('signUp.cityLabel')}</th>
                <td>{formData.city}</td>
              </tr>
              <tr>
                <th>{t('signUp.stateLabel')}</th>
                <td>{formData.state}</td>
              </tr>
              <tr>
                <th>{t('signUp.zipCodeLabel')}</th>
                <td>{formData.zipCode}</td>
              </tr>
              <tr>
                <th>{t('signUp.countryLabel')}</th>
                <td>{formData.country}</td>
              </tr>
              <tr>
                <th>{t('signUp.timezoneLabel')}</th>
                <td>{formData.timezone}</td>
              </tr>
              <tr>
                <th>{t('signUp.languageLabel')}</th>
                <td>{formData.language}</td>
              </tr>
              <tr>
                <th>{t('signUp.colorLabel')}</th>
                <td>
                  <div
                    className="color-box"
                    style={{ backgroundColor: formData.color }}
                  ></div>
                  {formData.color}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <button
        type="button"
        className="sign-up-submit-button"
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? <Spinner /> : t('signUp.finishButton')}
      </button>
      <button type="button" className="sign-up-back-button" onClick={() => onBack({})}>
        {t('signUp.backButton')}
      </button>
    </>
  );
};

export default Step5Form;
