// src/components/Layout.js

import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import GlobalSpinner from '../Common/GlobalSpinner';
import CatholicoreLogo from '../../assets/CatholicoreLogo.png';
import { DataContext } from '../../DataContext'; // Updated to DataContext
import { useColorSettings } from '../Common/ColorSettingsContext';
import { useLanguageSettings } from '../Common/LanguageSettingsContext';
import './Layout.css'; // Import the CSS file

const Layout = ({ children }) => {
  const location = useLocation();

  // Access contexts
  const {
    appSettings,
    appSettingsLoading,
    currentUser,
    currentUserLoading,
    userRolesLoading,
    userRoles,
  } = useContext(DataContext);

  const { loading: colorLoading } = useColorSettings();
  const { loading: languageLoading } = useLanguageSettings();

  // Determine if initial loading is still happening
  const isInitialLoading =
    currentUserLoading ||
    appSettingsLoading ||
    colorLoading ||
    languageLoading ||
    (currentUser ? (userRolesLoading || userRoles === null) : false);

  // State to manage loading and transitions
  const [isLoading, setIsLoading] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [logo, setLogo] = useState(CatholicoreLogo);

  // Update the logo when appSettings are loaded
  useEffect(() => {
    if (!appSettingsLoading) {
      const profilePicture = appSettings?.profilePicture || CatholicoreLogo;
      setLogo(profilePicture);
    }
  }, [appSettingsLoading, appSettings]);

  // Handle route changes
  useEffect(() => {
    // Start loading when the route changes
    setIsLoading(true);
  }, [location]);

  // Start loading when authentication state changes
  useEffect(() => {
    // Whenever currentUser changes, start loading
    setIsLoading(true);
  }, [currentUser]);

  // Manage loading and transitions based on data loading state
  useEffect(() => {
    if (isInitialLoading) {
      // If initial loading starts, ensure loading is active
      setIsLoading(true);
    } else {
      // Start the transition when initial loading is complete
      setIsTransitioning(true);

      // After the transition duration, hide the loading screen
      const transitionDuration = 800; // Match the CSS transition duration (in milliseconds)
      const timeout = setTimeout(() => {
        setIsLoading(false);
        setIsTransitioning(false);
      }, transitionDuration);

      return () => clearTimeout(timeout);
    }
  }, [isInitialLoading]);

  return (
    <div className="layout-container">
      {(isLoading || isTransitioning) && (
        <div
          className={`loading-screen ${
            !isLoading && isTransitioning ? 'fade-out' : ''
          }`}
        >
          <GlobalSpinner
            show={true}
            overlayColor="#fff"
            animationDuration={0.8} // Match the CSS duration (in seconds)
            opaque={true}
            color={appSettings?.color || '#bb8300'}
            logo={logo}
          />
        </div>
      )}
      <div className={`content ${!isLoading ? 'visible' : ''}`}>
        {children}
      </div>
    </div>
  );
};

export default Layout;
