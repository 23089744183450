// src/components/FormBuilder/Fields/Section.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './Section.css';

const Section = ({ label, description }) => {
  return (
    <div className="form-section">
      <h3 className="form-section-title">{label}</h3>
      {description && <p className="form-section-description">{description}</p>}
    </div>
  );
};

Section.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
};

Section.defaultProps = {
  description: '',
};

export default Section;
