// src/contexts/FormContext.js

import React, { createContext, useContext, useState, useMemo } from 'react';
import { firestore } from '../firebase';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  orderBy,
  updateDoc,
  addDoc,
  deleteDoc,
  serverTimestamp,
  writeBatch,
  getCountFromServer,
  Timestamp,
  limit,
} from 'firebase/firestore';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { compressImageFields } from '../utils/imageUtils';
import { UserContext } from './UserContext';

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const { organizationData } = useContext(UserContext);
  const queryClient = useQueryClient();
  const [sortField, setSortField] = useState('fullName'); // Default sort field
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

  const {
    data: sortedForms,
    isLoading: allFormsLoading,
    error: allFormsError,
    refetch: refetchAllForms,
  } = useQuery({
    queryKey: ['formGroups', organizationData.groupId, 'forms'],
    queryFn: async () => {
      const formsRef = collection(firestore, 'formGroups', organizationData.groupId, 'forms');
      const q = orderBy('createdAt', 'desc');
      const snapshot = await getDocs(formsRef, q);
      const fetchedForms = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Now, for each form, fetch the response count and last response date
      const formsWithStats = await Promise.all(
        fetchedForms.map(async (form) => {
          const responsesCollectionRef = collection(
            firestore,
            'formGroups',
            organizationData.groupId,
            'forms',
            form.id,
            'responses'
          );

          // Get the count of responses
          const countQuery = responsesCollectionRef;
          const countSnapshot = await getCountFromServer(countQuery);
          const responseCount = countSnapshot.data().count;

          // Get the last response date
          const lastResponseQuery = orderBy('submittedAt', 'desc');
          const lastResponseSnapshot = await getDocs(responsesCollectionRef, lastResponseQuery, limit(1));
          let lastResponseDate = null;
          if (!lastResponseSnapshot.empty) {
            const lastResponseDoc = lastResponseSnapshot.docs[0];
            const lastResponseData = lastResponseDoc.data();
            lastResponseDate = lastResponseData.submittedAt;
          }

          return {
            ...form,
            responseCount,
            lastResponseDate,
          };
        })
      );

      return formsWithStats;
    },
    enabled: !!firestore && !!organizationData.groupId && !!organizationData.organizationId,
    onError: (err) => {
      console.error('Error fetching forms:', err);
    },
  });

  // Sorting Forms Client-Side
  const sortedFormsData = useMemo(() => {
    if (!sortedForms) return [];

    const sorted = [...sortedForms].sort((a, b) => {
      const aField = a[sortField];
      const bField = b[sortField];

      // Handle undefined or null fields
      if (aField == null && bField == null) return 0;
      if (aField == null) return sortOrder === 'asc' ? -1 : 1;
      if (bField == null) return sortOrder === 'asc' ? 1 : -1;

      // Compare based on data type
      if (typeof aField === 'string' && typeof bField === 'string') {
        return sortOrder === 'asc'
          ? aField.localeCompare(bField)
          : bField.localeCompare(aField);
      }

      // Handle Firestore Timestamps
      if (aField instanceof Timestamp && bField instanceof Timestamp) {
        return sortOrder === 'asc'
          ? aField.toMillis() - bField.toMillis()
          : bField.toMillis() - aField.toMillis();
      }

      // For responseCount (numbers)
      if (typeof aField === 'number' && typeof bField === 'number') {
        return sortOrder === 'asc' ? aField - bField : bField - aField;
      }

      // Default comparison
      if (aField < bField) return sortOrder === 'asc' ? -1 : 1;
      if (aField > bField) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
    return sorted;
  }, [sortedForms, sortField, sortOrder]);

  // Function to set sorting parameters
  const setSorting = (field) => {
    if (sortField === field) {
      // Toggle sort order
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortField(field);
      setSortOrder('asc'); // Reset to ascending when changing field
    }
  };

  /**
   * Function to fetch form details
   */
  const fetchFormDetails = async (formId) => {
    if (!formId) {
      console.warn('fetchFormDetails called without a formId.');
      throw new Error('Form ID is required to fetch form details.');
    }

    const groupId = organizationData.groupId;
    if (!groupId) {
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      // Define the path to the specific form document under the new structure
      const formDocRef = doc(firestore, 'formGroups', groupId, 'forms', formId);
      const formSnapshot = await getDoc(formDocRef);

      if (formSnapshot.exists()) {
        const formDataFirestore = formSnapshot.data();
        return { id: formId, ...formDataFirestore };
      } else {
        console.warn(`Form with ID ${formId} does not exist.`);
        return null;
      }
    } catch (error) {
      console.error(`Error fetching form details for formId ${formId}:`, error);
      throw error;
    }
  };

  /**
   * Function to save a form (create or update) under formGroups/{groupId}/forms/{formId}
   */
  const saveForm = async (formId, formData) => {
    try {
      if (!organizationData.groupId) {
        throw new Error('Group ID is missing in organizationData.');
      }

      if (formId) {
        const formDocRef = doc(
          firestore,
          'formGroups',
          organizationData.groupId,
          'forms',
          formId
        );

        // Update existing form
        await updateDoc(formDocRef, {
          ...formData,
          updatedAt: serverTimestamp(),
        });
      } else {
        const formCollection = collection(
          firestore,
          'formGroups',
          organizationData.groupId,
          'forms'
        );
        // Create or set the {formId} document
        const docRef = await addDoc(formCollection, {
          ...formData,
          groupId: organizationData.groupId,
          organizationId: organizationData.organizationId,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        return docRef.id;
      }

      // Invalidate and refetch forms query to ensure data consistency
      queryClient.invalidateQueries(['formGroups', organizationData.groupId]);
    } catch (error) {
      console.error('Error saving form:', error);
      throw error;
    }
  };

  /**
   * Function to delete a form
   */
  const deleteForm = async (formId) => {
    // Define the protected form IDs that cannot be deleted
    const protectedFormIds = ['membersForm', 'groupsForm'];

    // Check if the formId is protected
    if (protectedFormIds.includes(formId)) {
      // Throw an error to prevent deletion
      throw new Error('This form cannot be deleted.');
    }

    // Retrieve the groupId from organizationData
    const groupId = organizationData.groupId;
    if (!groupId) {
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      // Define the path to the specific form document under the new structure
      const formDocRef = doc(firestore, 'formGroups', groupId, 'forms', formId);

      // Delete the form document
      await deleteDoc(formDocRef);

      // Invalidate forms query to refetch the forms
      queryClient.invalidateQueries(['formGroups', groupId, 'forms']);
    } catch (error) {
      console.error('Error deleting form:', error);
      throw error;
    }
  };

  /**
   * Function to fetch form fields
   */
  const getFormFields = async (formId) => {
    if (!formId) {
      console.warn('getFormFields called without a formId.');
      throw new Error('Form ID is required to fetch form fields.');
    }

    const groupId = organizationData.groupId;
    if (!groupId) {
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      // Define the path to the specific form document under the new structure
      const formDocRef = doc(firestore, 'formGroups', groupId, 'forms', formId);
      const formSnapshot = await getDoc(formDocRef);

      if (formSnapshot.exists()) {
        const formData = formSnapshot.data();
        return formData;
      } else {
        throw new Error(`Form with ID ${formId} does not exist.`);
      }
    } catch (error) {
      console.error(`Error fetching form fields for formId ${formId}:`, error);
      throw error;
    }
  };

  const useFormFields = (formId) => {
    return useQuery({
      queryKey: ['formFields', formId],
      queryFn: () => getFormFields(formId),
      enabled: !!formId, // Only run if formId is provided
      staleTime: 5 * 60 * 1000, // 5 minutes
    });
  };

  /**
   * Function to get tableFields configuration from Firestore
   */
  const getTableFields = async (formId) => {
    if (!formId) {
      throw new Error('Form ID is required to get table fields.');
    }

    const groupId = organizationData.groupId;
    if (!groupId) {
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      const formDocRef = doc(firestore, 'formGroups', groupId, 'forms', formId);
      const formSnapshot = await getDoc(formDocRef);

      if (formSnapshot.exists()) {
        const formData = formSnapshot.data();
        return formData.tableFields || [];
      } else {
        console.warn(`Form with ID ${formId} does not exist.`);
        return [];
      }
    } catch (error) {
      console.error('Error fetching table fields:', error);
      throw error;
    }
  };

  /**
   * Function to save tableFields configuration to Firestore
   */
  const saveTableFields = async (formId, tableFields) => {
    if (!formId) {
      throw new Error('Form ID is required to save table fields.');
    }

    const groupId = organizationData.groupId;
    if (!groupId) {
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      const formDocRef = doc(firestore, 'formGroups', groupId, 'forms', formId);
      await updateDoc(formDocRef, {
        tableFields: tableFields,
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.error('Error saving table fields:', error);
      throw error;
    }
  };

  return (
    <FormContext.Provider
      value={{
        sortedForms: sortedFormsData || [],
        allFormsLoading,
        allFormsError,
        refetchAllForms,
        sortField,
        sortOrder,
        setSorting,
        fetchFormDetails,
        saveForm,
        deleteForm,
        getFormFields,
        useFormFields,
        getTableFields,
        saveTableFields,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormProvider;
