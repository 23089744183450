// src/components/LandingPage/LandingPage.jsx

import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';
import CatholicoreLogo from '../../assets/CatholicoreLogoWhite.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconMap from '../Common/IconMap'; // Ensure this path is correct

const LandingPage = () => {
  return (
    <div className="landing-container">
      {/* Header */}
      <header className="landing-header">
        <div className="logo">
          <img src={CatholicoreLogo} alt="Catholicore Logo" />
        </div>
        <nav className="nav-links">
          <Link to="/sign-up" className="landing-nav-button create-account-button">Create Account</Link>
          <Link to="/login" className="landing-nav-button">Sign In</Link>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1>Welcome to Catholicore</h1>
          <p>Connect, Engage, and Grow Your Faith Community with Catholicore</p>
          <Link to="/sign-up" className="cta-button">Get Started</Link>
        </div>
      </section>

      {/* Features Section */}
      <section className="features">
        <h2>Features</h2>
        <div className="features-grid">
          {/* Feature Cards */}
          <div className="feature-card">
            <FontAwesomeIcon icon={IconMap.faUsers} className="feature-icon" />
            <h3>Member Management</h3>
            <p>Effortlessly manage your members with streamlined online sign-ups and profiles.</p>
          </div>
          <div className="feature-card">
            <FontAwesomeIcon icon={IconMap.faEnvelope} className="feature-icon" />
            <h3>Communication Tools</h3>
            <p>Stay connected with your community through SMS, email, and push notifications.</p>
          </div>
          <div className="feature-card">
            <FontAwesomeIcon icon={IconMap.faCalendarAlt} className="feature-icon" />
            <h3>Event Calendar</h3>
            <p>Organize and promote events with an integrated calendar shared with your community.</p>
          </div>
          <div className="feature-card">
            <FontAwesomeIcon icon={IconMap.faPaintBrush} className="feature-icon" />
            <h3>Customization</h3>
            <p>Personalize your platform's look and language to reflect your community's unique style.</p>
          </div>
          <div className="feature-card">
            <FontAwesomeIcon icon={IconMap.faFilePen} className="feature-icon" />
            <h3>Custom Forms</h3>
            <p>Design custom forms for registrations, surveys, and more, tailored to your needs.</p>
          </div>
          <div className="feature-card">
            <FontAwesomeIcon icon={IconMap.faHandHoldingUsd} className="feature-icon" />
            <h3>Online Giving</h3>
            <p>Enable seamless and secure online donations to support your mission.</p>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="landing-footer">
        <p>&copy; {new Date().getFullYear()} Catholicore. All rights reserved.</p>
        <div className="footer-links">
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-of-service">Terms of Service</Link>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
