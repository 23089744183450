// src/pages/MembersPage.jsx

import React, { useContext, useState, useEffect, useRef } from 'react';
import MembersTable from './MembersTable';
import { DataContext } from '../../DataContext';
import { useTranslation } from 'react-i18next';
import Spinner from '../Common/Spinner';
import FillOutForm from '../FormBuilder/FillOutForm';
import MessageSender from '../Communications/MessageSender';
import CustomModal from '../Common/CustomModal';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import ToastContainer from '../Common/ToastContainer';
import ActionButton from '../Common/ActionButton';
import ConfigureFieldsModal from '../Common/ConfigureFieldsModal';
import './MembersPage.css';

import {
  generateCSV,
  downloadCSV,
} from '../FormBuilder/CSVUtility';

import { generatePDF } from '../FormBuilder/PDFUtility';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisV,
  faCog,
  faFileCsv,
  faFilePdf,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

const MembersPage = () => {
  const { t } = useTranslation();

  // Consume DataContext
  const {
    useResponses,
    userRole,
    deleteResponse,
    useFormFields,
    getTableFields,
    saveTableFields,
    appSettings,
  } = useContext(DataContext);

  // Use the useResponses hook to fetch members data for "membersForm"
  const {
    data: responses = [],
    isLoading,
    error,
    refetch,
  } = useResponses('membersForm');

  const {
    data: formFieldsData = {},
    isLoading: formFieldsLoading,
  } = useFormFields('membersForm');

  // Fetch tableFields configuration
  const [tableFields, setTableFields] = useState([]);
  const [loadingTableFields, setLoadingTableFields] = useState(true);

  useEffect(() => {
    const fetchTableFields = async () => {
      try {
        const fields = await getTableFields('membersForm');
        if (fields && fields.length > 0) {
          setTableFields(fields);
        } else {
          // If no configuration exists, set default fields
          setTableFields([
            {
              id: 'fullName',
              label: 'Full Name',
              accessor: ['firstName', 'lastName'],
              sortable: true,
              hideInMobile: false,
              type: 'Text',
              clickToEdit: false,
            },
            {
              id: 'location',
              label: 'Location',
              accessor: ['city', 'state'],
              sortable: true,
              hideInMobile: true,
              type: 'Text',
              clickToEdit: false,
            },
            {
              id: 'dateOfBirth',
              label: 'Date of Birth',
              accessor: 'dateOfBirth',
              sortable: true,
              hideInMobile: false,
              type: 'DatePicker',
              clickToEdit: false,
            },
            {
              id: 'phoneNumber',
              label: 'Phone Number',
              accessor: 'phoneNumber',
              sortable: true,
              hideInMobile: false,
              type: 'Telephone',
              clickToEdit: false,
            },
          ]);
        }
      } catch (error) {
        console.error('Error fetching table fields:', error);
      } finally {
        setLoadingTableFields(false);
      }
    };

    fetchTableFields();
  }, [getTableFields]);

  // State variables for export loading states
  const [isExportingCSV, setIsExportingCSV] = useState(false);
  const [isExportingPDF, setIsExportingPDF] = useState(false);

  // Function to handle exporting responses to CSV
  const handleExportCSV = async () => {
    if (responses.length === 0) {
      showToast(t('responsesTab.noResponsesToExport'), 'error');
      return;
    }

    setIsExportingCSV(true);

    try {
      const csvContent = generateCSV(responses, formFieldsData.fields);
      downloadCSV(csvContent, 'members.csv');
      showToast(t('responsesTab.csvExportSuccess'), 'success');
    } catch (error) {
      showToast(t('responsesTab.csvExportError'), 'error');
      console.error('Error exporting CSV:', error);
    } finally {
      setIsExportingCSV(false);
      setMenuVisible(false);
    }
  };

  // Function to handle exporting responses to PDF
  const handleExportPDF = async () => {
    if (responses.length === 0) {
      showToast(t('responsesTab.noResponsesToExport'), 'error');
      return;
    }

    setIsExportingPDF(true);

    try {
      // Use the group's logo from appSettings
      const groupLogo = appSettings?.profilePicture || null;
      await generatePDF(
        responses,
        formFieldsData.fields,
        false,
        groupLogo,
        t('membersForm', 'Members')
      );
      showToast(t('responsesTab.pdfExportSuccess'), 'success');
    } catch (error) {
      showToast(t('responsesTab.pdfExportError'), 'error');
      console.error('Error exporting PDF:', error);
    } finally {
      setIsExportingPDF(false);
      setMenuVisible(false);
    }
  };

  // Map responses to members
  const members = responses.map((response) => {
    const member = {};
    response.fields.forEach((field) => {
      member[field.id] = field.value;
    });
    member.id = response.id;
    member.fields = response.fields;
    member.submittedAt = response.submittedAt;
    member.createdAt = response.createdAt;
    member.updatedAt = response.updatedAt;
    member.groups = response.groups
      ? response.groups.map((groupRef) => groupRef.id)
      : [];
    member.response = response; // Include the original response
    return member;
  });

  // Local state for modals and toast notifications
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [deleteModalMessage, setDeleteModalMessage] = useState('');
  const [toasts, setToasts] = useState([]);
  const [showConfigModal, setShowConfigModal] = useState(false);

  // State for menu visibility
  const [menuVisible, setMenuVisible] = useState(false);

  // Ref for the menu container
  const menuRef = useRef(null);

  // Toast Management
  const showToast = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prevToasts) => [
      ...prevToasts,
      { id, message, type, duration },
    ]);
    setTimeout(() => removeToast(id), duration);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  // Action Handlers
  const handleAdd = () => {
    setModalContent(
      <FillOutForm
        fields={formFieldsData.fields}
        formId="membersForm"
        showToast={showToast}
        initialData={null}
        onCancel={() => {
          setShowModal(false);
          setModalContent(null);
          setModalTitle('');
          refetch();
        }}
      />
    );
    setModalTitle(t('membersTable.addMember', 'Add Member'));
    setShowModal(true);
  };

  const handleEdit = (record) => {
    setModalContent(
      <FillOutForm
        fields={formFieldsData.fields}
        formId="membersForm"
        showToast={showToast}
        initialData={record}
        responseId={record.id}
        onCancel={() => {
          setShowModal(false);
          setModalContent(null);
          setModalTitle('');
          refetch();
        }}
      />
    );
    setModalTitle(
      t('membersTable.editMember', {
        name: `${record.firstName} ${record.lastName}`,
      })
    );
    setShowModal(true);
  };

  const handleSendMessage = (record) => {
    setModalContent(
      <MessageSender
        single={true}
        recipient={record.phoneNumber}
        showToast={showToast}
      />
    );
    setModalTitle(
      t('membersTable.sendMessageTo', {
        name: `${record.firstName} ${record.lastName}`,
      })
    );
    setShowModal(true);
  };

  const handleCall = (record) => {
    window.location.href = `tel:${record.phoneNumber}`;
  };

  const handleSendEmail = (record) => {
    window.location.href = `mailto:${record.email}`;
  };

  const handleDelete = (record) => {
    setRecordToDelete(record);
    setDeleteModalMessage(
      t('deleteConfirmation.message', {
        item: `${record.firstName} ${record.lastName}`,
      })
    );
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (!recordToDelete) return;
    try {
      await deleteResponse('membersForm', recordToDelete.id);
      showToast(
        t(
          'memberTable.recordDeleted',
          'Member has been deleted successfully'
        ),
        'success'
      );
      setShowDeleteModal(false);
      setRecordToDelete(null);
      refetch();
    } catch (error) {
      console.error('Error deleting member:', error);
      showToast(
        t('memberTable.deleteError', 'Error deleting member'),
        'error'
      );
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setRecordToDelete(null);
  };

  // Function to handle generating PDF for a single member
  const handleGeneratePdf = async (record) => {
    try {
      const groupLogo = appSettings?.profilePicture || null;
      await generatePDF(
        [record.response],
        formFieldsData.fields,
        false,
        groupLogo,
        t('membersForm', 'Member')
      );
      showToast(t('responsesTab.pdfExportSuccess'), 'success');
    } catch (error) {
      showToast(t('responsesTab.pdfExportError'), 'error');
      console.error('Error exporting PDF:', error);
    }
  };

  // Define the actions you want to perform on each row
  const actions = [
    {
      label: t('edit', 'Edit'),
      icon: 'faEdit',
      callback: handleEdit,
      variant: 'secondary',
      condition: () => true,
    },
    {
      label: t('sendMessage', 'Send Message'),
      icon: 'faPaperPlane',
      callback: handleSendMessage,
      variant: 'primary',
      condition: (record) => !!record.phoneNumber,
    },
    {
      label: t('call', 'Call'),
      icon: 'faPhone',
      callback: handleCall,
      variant: 'secondary',
      condition: (record) => !!record.phoneNumber,
      as: 'a',
      hrefAccessor: (record) => `tel:${record.phoneNumber}`,
    },
    {
      label: t('sendEmail', 'Send Email'),
      icon: 'faEnvelope',
      callback: handleSendEmail,
      variant: 'info',
      disabledAccessor: (record) => !record.email,
    },
    {
      label: t('generatePdf', 'Generate PDF'),
      icon: 'faFilePdf',
      callback: handleGeneratePdf,
      variant: 'secondary',
      condition: () => true,
    },
    {
      label: t('delete', 'Delete'),
      icon: 'faTrash',
      callback: handleDelete,
      variant: 'danger',
      condition: () => userRole.roleName === 'Admin',
    },
  ];

  // Handle opening the configuration modal
  const handleOpenConfigModal = () => {
    setShowConfigModal(true);
    setMenuVisible(false);
  };

  // Handle saving the configuration
  const handleSaveConfig = async (configuredFields) => {
    try {
      await saveTableFields('membersForm', configuredFields);
      setTableFields(configuredFields);
      setShowConfigModal(false);
      showToast(
        t(
          'tableConfigurationSuccessful',
          'Table configuration saved successfully'
        ),
        'success'
      );
    } catch (error) {
      console.error('Error saving table configuration:', error);
      showToast(
        t('tableConfigurationFailed', 'Failed to save table configuration'),
        'error'
      );
    }
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    };

    if (menuVisible) {
      document.addEventListener('mousedown', handleClickOutsideMenu);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, [menuVisible]);

  // Loading and Error States
  if (isLoading || loadingTableFields || formFieldsLoading) {
    return (
      <div className="loading-container">
        <Spinner size="100px" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-message">
        {t('membersPage.errorFetchingMembers')}
      </div>
    );
  }

  return (
    <div className="members-page-container">
      {/* Header Container */}
      <div className="header-container">
        <h2>{t('membersPage.title', 'Members')}</h2>

        {/* Configuration Menu Button */}
        <div className="config-button">
          <div className="menu-container" ref={menuRef}>
            <ActionButton
              onClick={() => setMenuVisible(!menuVisible)}
              icon="faEllipsisV"
              variant="secondary"
              label={t('moreOptions', 'More Options')}
            />
            {menuVisible && (
              <div className="dropdown-menu">
                <button onClick={handleOpenConfigModal}>
                  <FontAwesomeIcon icon={faCog} className="menu-icon" />
                  {t('configureTableFields', 'Configure Table Fields')}
                </button>
                <button
                  onClick={handleExportCSV}
                  disabled={isExportingCSV}
                >
                  <FontAwesomeIcon icon={faFileCsv} className="menu-icon" />
                  {t('exportCSV', 'Export CSV')}
                  {isExportingCSV && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      className="menu-spinner"
                    />
                  )}
                </button>
                <button
                  onClick={handleExportPDF}
                  disabled={isExportingPDF}
                >
                  <FontAwesomeIcon icon={faFilePdf} className="menu-icon" />
                  {t('exportPDF', 'Export PDF')}
                  {isExportingPDF && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      className="menu-spinner"
                    />
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <MembersTable
        formId="membersForm"
        fields={tableFields}
        actions={actions}
        members={members}
        userRole={userRole}
        addMember={handleAdd}
        exportMember={handleExportCSV}
        onRowClick={handleEdit}
      />

      {/* Custom Modal for Editing or Messaging */}
      <CustomModal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setModalContent(null);
          setModalTitle('');
        }}
        title={modalTitle}
      >
        {modalContent}
      </CustomModal>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <DeleteConfirmationModal
          show={showDeleteModal}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          title={t('deleteConfirmation.title')}
          message={deleteModalMessage}
        />
      )}

      {/* Configuration Modal */}
      {showConfigModal && (
        <ConfigureFieldsModal
          show={showConfigModal}
          onClose={() => setShowConfigModal(false)}
          onSave={handleSaveConfig}
          availableFields={formFieldsData.fields}
          currentConfig={tableFields}
        />
      )}

      {/* Toast Notifications */}
      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </div>
  );
};

export default MembersPage;