// src/utils/JSONUtility.js

export const exportToJsonFile = (data, filename) => {
    const dataStr = JSON.stringify(data, null, 2);
    const blob = new Blob([dataStr], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
  
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
  
    // Clean up
    document.body.removeChild(anchor);
    URL.revokeObjectURL(url);
  };
  