// src/components/Authentication/ProtectedRoute.jsx

import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { DataContext } from '../../DataContext';

const ProtectedRoute = ({ children }) => {
  const { currentUser, currentUserLoading } = useContext(DataContext);

  if (currentUserLoading) {
    // Optionally, return a spinner or loading indicator
    return null; // Or a loading component
  }

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
