// src/components/GroupTable/GroupsTable.jsx

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import './GroupsTable.css';
import ActionButton from '../Common/ActionButton';
import Table from '../Common/Table';
import PaginationControls from '../Common/PaginationControls';
import SearchBar from '../Common/SearchBar';
import { useTranslation } from 'react-i18next';

const GroupsTable = ({
  fields,
  actions,
  groups,
  userRole,
  addGroup,
  onRowClick, // Added onRowClick prop
}) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [searchTerm, setSearchTerm] = useState('');

  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  const setSorting = (field) => {
    if (sortField === field) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
    setCurrentPage(1);
  };

  const filteredRecords = useMemo(() => {
    if (!searchTerm) return groups || [];

    const lowercasedTerm = searchTerm.toLowerCase();

    return (groups || []).filter((group) => {
      return fields.some((field) => {
        const value =
          typeof field.accessor === 'function'
            ? field.accessor(group)
            : Array.isArray(field.accessor)
            ? field.accessor.map((key) => group[key] || '').join(' ')
            : group[field.accessor];
        if (value && typeof value === 'string') {
          return value.toLowerCase().includes(lowercasedTerm);
        }
        return false;
      });
    });
  }, [searchTerm, groups, fields]);

  const sortedRecords = useMemo(() => {
    if (!filteredRecords) return [];
    if (!sortField) return filteredRecords;

    const column = fields.find((f) => f.id === sortField);
    if (!column) return filteredRecords;

    const getFieldValue = (record, column) => {
      const { accessor } = column;

      if (Array.isArray(accessor)) {
        return accessor.map((key) => record[key] || '').join(' ').trim();
      } else if (typeof accessor === 'string') {
        return record[accessor];
      } else if (typeof accessor === 'function') {
        return accessor(record);
      } else {
        return '';
      }
    };

    const sorted = [...filteredRecords].sort((a, b) => {
      let aField = getFieldValue(a, column);
      let bField = getFieldValue(b, column);

      if (aField == null) return sortOrder === 'asc' ? -1 : 1;
      if (bField == null) return sortOrder === 'asc' ? 1 : -1;

      if (typeof aField === 'string' && typeof bField === 'string') {
        return sortOrder === 'asc'
          ? aField.localeCompare(bField)
          : bField.localeCompare(aField);
      }

      if (aField < bField) return sortOrder === 'asc' ? -1 : 1;
      if (aField > bField) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });

    return sorted;
  }, [filteredRecords, sortField, sortOrder, fields]);

  const paginatedRecords = useMemo(() => {
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    return sortedRecords.slice(startIdx, endIdx);
  }, [sortedRecords, currentPage, itemsPerPage]);

  const totalPages = useMemo(() => {
    return Math.ceil(filteredRecords.length / itemsPerPage) || 1;
  }, [filteredRecords.length, itemsPerPage]);

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages]);

  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  }, []);

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  return (
    <div className="group-table-container">
      <div className="group-table-header">
        <div className="header-top">
          <SearchBar
            searchTerm={searchTerm}
            onSearchChange={handleSearchChange}
            placeholder={t('groupsTable.searchPlaceholder', 'Search groups...')}
          />
          <div className="action-buttons">
            <ActionButton
              onClick={addGroup}
              label={t('groupsTable.addNewGroup', 'Add New Group')}
              icon="faPlus"
              colorType="primary"
            />
          </div>
        </div>
      </div>

      <div className="record-count" aria-live="polite">
        {filteredRecords.length === 1
          ? `${t(
              'groupsTable.recordsFound',
              { count: filteredRecords.length },
              '1 group found'
            )}`
          : `${t(
              'groupsTable.recordsFound_plural',
              { count: filteredRecords.length },
              `${filteredRecords.length} groups found`
            )}`}
      </div>

      {/* Table Component */}
      <Table
        data={paginatedRecords}
        columns={fields}
        actions={actions}
        userRole={userRole}
        setSorting={setSorting}
        sortField={sortField}
        sortOrder={sortOrder}
        noRecordsMessage={t('groupsTable.noRecordsFound', 'No groups found')}
        actionsHeaderLabel={t('actions', 'Actions')}
        onRowClick={onRowClick} // Pass the onRowClick prop to Table
      />

      {/* Pagination Controls */}
      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        onPrev={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        onNext={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
        onPageClick={(page) => setCurrentPage(page)}
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={handleItemsPerPageChange}
        itemsPerPageLabel={t('groupsTable.itemsPerPageLabel', 'Groups per page:')}
        previousLabel={t('previousLabel', 'Previous')}
        nextLabel={t('nextLabel', 'Next')}
        pageLabel={t('pageLabel', 'Page')}
      />
    </div>
  );
};

GroupsTable.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      accessor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.string),
      ]).isRequired,
      sortable: PropTypes.bool,
      type: PropTypes.string,
      clickToEdit: PropTypes.bool,
    })
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      callback: PropTypes.func.isRequired,
      variant: PropTypes.string,
      condition: PropTypes.func,
      as: PropTypes.string,
      hrefAccessor: PropTypes.func,
      disabledAccessor: PropTypes.func,
    })
  ).isRequired,
  groups: PropTypes.array.isRequired,
  userRole: PropTypes.object.isRequired,
  addGroup: PropTypes.func.isRequired,
  onRowClick: PropTypes.func, // Added prop type
};

GroupsTable.defaultProps = {
  onRowClick: null, // Default value
};

export default GroupsTable;
