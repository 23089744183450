// src/components/Authentication/SignUpForm.jsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SignUpForm.css';
import Step1Form from './SignUpSteps/Step1Form';
import Step2Form from './SignUpSteps/Step2Form';
import Step3Form from './SignUpSteps/Step3Form';
import Step4Form from './SignUpSteps/Step4Form';
import Step5Form from './SignUpSteps/Step5Form';
import Step6Form from './SignUpSteps/Step6Form';
import CatholicoreLogoWhite from '../../assets/CatholicoreLogoWhite.png';
import { useTranslation } from 'react-i18next';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

const SignUpForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [signupResponse, setSignupResponse] = useState(null); // New state for response
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNext = (data) => {
    if (data.response) {
      setSignupResponse(data.response); // Store the response
    } else {
      setFormData((prevData) => ({ ...prevData, ...data }));
    }
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = (data) => {
    setFormData((prevData) => ({ ...prevData, ...data }));
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const totalSteps = 6; // Update total steps to 6

  // Function to render the current step component
  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1Form onNext={handleNext} formData={formData} />;
      case 2:
        return <Step2Form onNext={handleNext} onBack={handleBack} formData={formData} />;
      case 3:
        return <Step3Form onNext={handleNext} onBack={handleBack} formData={formData} />;
      case 4:
        return <Step4Form onNext={handleNext} onBack={handleBack} formData={formData} />;
      case 5:
        return <Step5Form onNext={handleNext} onBack={handleBack} formData={formData} />;
      case 6:
        return <Step6Form formData={formData} response={signupResponse} />;
      default:
        return null;
    }
  };

  return (
    <div className="sign-up-background">
      {/* White Logo Positioned at Top Left */}
      <div className="sign-up-white-logo">
        <a href="/">
          <img src={CatholicoreLogoWhite} alt="Catholicore White Logo" />
        </a>
      </div>
      <div className="sign-up-container">
        {/* Progress Bar */}
        <div className="sign-up-progress-bar">
          {[...Array(totalSteps)].map((_, i) => {
            const step = i + 1;
            return (
              <div key={step} className="progress-step-wrapper">
                <div className={`progress-step ${currentStep >= step ? 'active' : ''}`}>
                  <div className="progress-step-circle">
                    {currentStep > step ? (
                      <span className="progress-step-checkmark">&#10003;</span>
                    ) : (
                      <span>{step}</span>
                    )}
                  </div>
                </div>
                {step < totalSteps && (
                  <div className={`progress-step-line ${currentStep > step ? 'active' : ''}`}></div>
                )}
              </div>
            );
          })}
        </div>

        {/* SwitchTransition for smoother animations */}
        <SwitchTransition mode="out-in">
          <CSSTransition key={currentStep} timeout={300} classNames="fade" unmountOnExit>
            <div className="sign-up-step">{renderCurrentStep()}</div>
          </CSSTransition>
        </SwitchTransition>

        {/* Display the login link only during Step 1 */}
        {currentStep === 1 && (
          <div className="sign-up-login-link">
            <h6
              onClick={() => {
                navigate('/login');
              }}
              className="sign-up-login-text"
            >
              {t('signUp.alreadyHaveAccount')}
            </h6>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignUpForm;
