// src/contexts/UserContext.js

import React, { createContext, useState, useEffect } from 'react';
import { firestore, auth } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

export const UserContext = createContext();

export const UserProvider = ({ children, groupID }) => {
  const [currentUser, setCurrentUser] = useState(auth.currentUser);
  const [userRole, setUserRole] = useState({ roleName: 'User' });
  const [userRoles, setUserRoles] = useState(null); // Initialize as null
  const [userRolesLoading, setUserRolesLoading] = useState(false);
  const [organizationData, setOrganizationData] = useState({
    organizationId: '',
    groupId: groupID,
  });
  const [userData, setUserData] = useState({
    fullName: '',
    photoURL: '',
  });

  // Update currentUser when auth state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);

  // Fetch User Roles and Data
  useEffect(() => {
    const fetchUserRoleAndData = async () => {
      setUserRolesLoading(true);
      if (currentUser && firestore) {
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const userSnapshot = await getDoc(userDocRef);
        if (userSnapshot.exists()) {
          const userDataFirestore = userSnapshot.data();

          // Get the user's roles
          const userRolesFirestore = userDataFirestore.roles || [];
          setUserRoles(userRolesFirestore);

          setUserData({
            fullName:
              `${userDataFirestore.firstName || ''} ${
                userDataFirestore.lastName || ''
              }` || currentUser.email,
            photoURL: userDataFirestore.photoURL || '',
          });
        } else {
          setUserRoles([]); // User has no roles
          setUserData({
            fullName: currentUser.email,
            photoURL: '',
          });
        }
      } else {
        setUserRoles(null); // Set to null when no currentUser
        setUserRole({ roleName: 'User' });
        setUserData({
          fullName: '',
          photoURL: '',
        });
      }
      setUserRolesLoading(false);
    };

    fetchUserRoleAndData();
  }, [currentUser]); // Depend on currentUser

  // Set organizationData.groupId if not provided
  useEffect(() => {
    if (!organizationData.groupId && userRoles && userRoles.length > 0) {
      const firstRoleEntry = userRoles[0];
      const groupIdRef = firstRoleEntry.groupId;
      const groupId = groupIdRef && groupIdRef.id;
      setOrganizationData((prevData) => ({
        ...prevData,
        groupId: groupId,
      }));
    }
  }, [organizationData.groupId, userRoles]);

  // Fetch Organization Data based on groupId.
  useEffect(() => {
    const fetchOrganizationData = async () => {
      if (firestore && organizationData.groupId) {
        const groupDocRef = doc(firestore, 'groups', organizationData.groupId);
        const groupSnapshot = await getDoc(groupDocRef);
        if (groupSnapshot.exists()) {
          const groupData = groupSnapshot.data();
          setOrganizationData((prevData) => ({
            ...prevData,
            organizationId: groupData.organizationId.id,
          }));
        } else {
          console.error('Group document does not exist.');
        }
      }
    };

    fetchOrganizationData();
  }, [organizationData.groupId]);

  // Fetch userRole based on organizationData.groupId
  useEffect(() => {
    const fetchUserRole = async () => {
      if (userRoles && organizationData.groupId) {
        const userRoleEntry = userRoles.find((roleEntry) => {
          const groupIdRef = roleEntry.groupId;
          const groupId = groupIdRef && groupIdRef.id;
          return groupId === organizationData.groupId;
        });

        if (userRoleEntry && userRoleEntry.role) {
          const roleSnapshot = await getDoc(userRoleEntry.role);
          if (roleSnapshot.exists()) {
            const roleData = roleSnapshot.data();
            setUserRole({
              roleName: roleData.name,
            });
          } else {
            setUserRole({
              roleName: 'User',
            });
          }
        } else {
          setUserRole({
            roleName: 'User',
          });
        }
      } else {
        setUserRole({
          roleName: 'User',
        });
      }
    };

    fetchUserRole();
  }, [userRoles, organizationData.groupId]);

  return (
    <UserContext.Provider
      value={{
        currentUser, // Add this line
        userRole,
        userRoles,
        userData,
        organizationData,
        userRolesLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
