// src/components/Common/ActionButton.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconMap from './IconMap';
import CustomTooltip from './CustomTooltip';
import Spinner from './Spinner';
import { useTranslation } from 'react-i18next';
import './ActionButton.css';

const ActionButton = ({
  onClick,
  onSplitClick,
  label,
  text,
  icon,
  splitIcon,
  split,
  isMobile,
  colorType,
  as,
  href,
  disabled,
  ariaLabel,
  type,
  target,
  rel,
  spacing,
  className,
  size,
  fullWidth,
  spin,
}) => {
  const { t } = useTranslation();
  const isAnchor = as === 'a';
  const isIconOnly = icon && !text;

  const tooltipContent = disabled
    ? t('actionButton.noActionAvailable', 'No action available')
    : label || t('actionButton.defaultLabel', 'Action Button');

  const classes = [
    'action-button',
    `size-${size}`,
    colorType === 'primary' ? 'primary' : 'secondary',
    isMobile ? 'action-button-mobile' : '',
    isIconOnly ? 'icon-only' : '',
    fullWidth ? 'full-width' : '',
    split ? 'split-button' : '',
    disabled ? 'disabled' : '',
    className,
  ]
    .filter(Boolean)
    .join(' ');

  const style = spacing ? { margin: spacing } : {};

  const commonMainButtonAriaLabel = ariaLabel || label || t('actionButton.defaultLabel', 'Action Button');

  if (split) {
    // Split button rendering
    const mainButtonProps = {
      className: 'main-button',
      onClick,
      disabled,
      type,
      'aria-label': commonMainButtonAriaLabel,
      title: commonMainButtonAriaLabel,
    };

    const splitButtonProps = {
      className: 'split-button-toggle',
      onClick: onSplitClick,
      disabled,
      type: 'button',
      'aria-label': t('actionButton.moreOptions', 'More options'),
      title: t('actionButton.moreOptions', 'More options'),
    };

    const mainContent = (
      <>
        {spin && <Spinner size="16px" color="#fff" />}
        {!spin && icon && <FontAwesomeIcon icon={iconMap[icon]} className="button-icon" />}
        {text && <span className="button-text">{text}</span>}
      </>
    );

    const toggleContent = (
      <>
        {splitIcon ? (
          <FontAwesomeIcon icon={iconMap[splitIcon]} className="split-button-icon" />
        ) : (
          <FontAwesomeIcon icon={iconMap.faCaretDown} className="split-button-icon" />
        )}
      </>
    );

    // If icon-only and not mobile, wrap in tooltip
    if (!isMobile && isIconOnly) {
      return (
        <CustomTooltip text={tooltipContent}>
          <div className={classes} style={style}>
            <button {...mainButtonProps}>{mainContent}</button>
            <button {...splitButtonProps}>{toggleContent}</button>
          </div>
        </CustomTooltip>
      );
    }

    return (
      <div className={classes} style={style}>
        <button {...mainButtonProps}>{mainContent}</button>
        <button {...splitButtonProps}>{toggleContent}</button>
      </div>
    );
  } else {
    // Regular button
    const commonProps = {
      className: classes,
      style,
      'aria-label': commonMainButtonAriaLabel,
      title: commonMainButtonAriaLabel,
      ...(isAnchor ? { href } : { onClick }),
      ...(isAnchor ? { target, rel } : { type }),
      ...(disabled && !isAnchor ? { disabled: true } : {}),
    };

    const content = (
      <>
        {spin && <Spinner size="16px" color="#fff" />}
        {!spin && icon && <FontAwesomeIcon icon={iconMap[icon]} className="button-icon" />}
        {text && <span className="button-text">{text}</span>}
      </>
    );

    if (!isMobile && isIconOnly) {
      return (
        <CustomTooltip text={tooltipContent}>
          {isAnchor ? <a {...commonProps}>{content}</a> : <button {...commonProps}>{content}</button>}
        </CustomTooltip>
      );
    }

    return isAnchor ? <a {...commonProps}>{content}</a> : <button {...commonProps}>{content}</button>;
  }
};

ActionButton.propTypes = {
  onClick: PropTypes.func,
  onSplitClick: PropTypes.func,
  label: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  splitIcon: PropTypes.string,
  split: PropTypes.bool,
  isMobile: PropTypes.bool,
  colorType: PropTypes.oneOf(['primary', 'secondary']),
  as: PropTypes.oneOf(['button', 'a']),
  href: (props, propName, componentName) => {
    if (props.as === 'a' && !props[propName]) {
      return new Error(
        `The prop \`${propName}\` is required when \`as='a'\` is specified in \`${componentName}\`.`
      );
    }
    return null;
  },
  target: PropTypes.string,
  rel: PropTypes.string,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  spacing: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  fullWidth: PropTypes.bool,
  spin: PropTypes.bool,
};

ActionButton.defaultProps = {
  label: '',
  text: '',
  icon: null,
  splitIcon: null,
  split: false,
  onSplitClick: null,
  isMobile: false,
  colorType: 'primary',
  as: 'button',
  href: null,
  target: '_self',
  rel: 'noopener noreferrer',
  disabled: false,
  ariaLabel: '',
  type: 'button',
  spacing: '',
  className: '',
  size: 'medium',
  fullWidth: false,
  spin: false,
};

export default ActionButton;
