// src/contexts/ResponseContext.js

import React, { createContext, useContext } from 'react';
import { firestore, auth } from '../firebase';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  query,
  where,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  writeBatch,
  Timestamp,
} from 'firebase/firestore';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { compressImageFields } from '../utils/imageUtils';
import { UserContext } from './UserContext';

export const ResponseContext = createContext();

export const ResponseProvider = ({ children }) => {
  const { organizationData } = useContext(UserContext);
  const queryClient = useQueryClient();

  /**
   * Function to fetch responses for a form
   */
  // src/contexts/ResponseContext.js

const fetchResponsesForForm = async (formId) => {
    console.log('fetchResponsesForForm called with formId:', formId);
  
    if (!formId) {
      console.warn('fetchResponsesForForm called without a formId.');
      return [];
    }
  
    if (!organizationData) {
      console.error('organizationData is not defined.');
      throw new Error('organizationData is not defined.');
    }
  
    const { groupId, organizationId } = organizationData;
  
    console.log('organizationData:', organizationData);
    console.log('organizationId:', organizationId, 'Type:', typeof organizationId);
    console.log('groupId:', groupId, 'Type:', typeof groupId);
  
    if (!groupId) {
      console.error('Group ID is not defined in organizationData.');
      throw new Error('Group ID is not defined in organizationData.');
    }
  
    try {
      // Helper function to fetch responses for a specific groupId
      const fetchResponsesByGroup = async (currentGroupId) => {
        console.log(`Fetching responses for groupId: ${currentGroupId}`);
  
        const responsesCollection = collection(
          firestore,
          'formGroups',
          currentGroupId,
          'forms',
          formId,
          'responses'
        );
  
        const responsesQuery = query(
          responsesCollection,
          orderBy('submittedAt', 'desc')
          // You can add a limit here if needed, e.g., limit(100)
        );
        
        const responsesSnapshot = await getDocs(responsesQuery);
        console.log(
          `Fetched ${responsesSnapshot.size} responses from groupId: ${currentGroupId}`
        );
  
        return responsesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      };
  
      let fetchedResponses = [];

      console.log("Group ID: "+ groupId);
      console.log("Organization ID: "+ organizationId);
  
      if (groupId === organizationId) {
        // When groupId equals organizationId, fetch from all formGroups in the same organization
        const formGroupsCollection = collection(firestore, 'formGroups');
        const formGroupsQuery = query(
          formGroupsCollection,
          where('organizationId', '==', organizationId) // Ensure organizationId is a string
        );
        const formGroupsSnapshot = await getDocs(formGroupsQuery);
        console.log(`Fetched ${formGroupsSnapshot.size} formGroups.`);
  
        if (formGroupsSnapshot.empty) {
          console.warn('No formGroups found.');
          return [];
        }
  
        const formGroupIds = formGroupsSnapshot.docs.map((doc) => doc.id);
  
        // Fetch responses from all formGroups in parallel
        const allResponsesPromises = formGroupIds.map((currentGroupId) =>
          fetchResponsesByGroup(currentGroupId)
        );
  
        const allResponsesArrays = await Promise.all(allResponsesPromises);
        console.log('All responses arrays fetched.');
  
        // Flatten the array of arrays into a single array of responses
        fetchedResponses = allResponsesArrays.flat();
        console.log(`Total responses fetched: ${fetchedResponses.length}`);
      } else {
        console.log('groupId does not equal organizationId. Fetching from specific groupId.');
  
        // When groupId does not equal organizationId, fetch from the specific groupId
        fetchedResponses = await fetchResponsesByGroup(groupId);
        console.log(
          `Total responses fetched from groupId ${groupId}: ${fetchedResponses.length}`
        );
      }
  
      return fetchedResponses;
    } catch (error) {
      console.error(`Error fetching responses for formId ${formId}:`, error);
      throw error;
    }
  };
  

  /**
   * Custom hook to manage responses using React Query
   */
  const useResponses = (formId) => {
    const { organizationData } = useContext(UserContext);
  
    return useQuery({
      queryKey: ['responses', formId],
      queryFn: () => fetchResponsesForForm(formId),
      enabled: !!formId && !!organizationData.organizationId, // Only run if formId and organizationId are provided
      staleTime: 5 * 60 * 1000, // 5 minutes
      retry: 2, // Retry failed requests twice
      onError: (error) => {
        // Optional: Implement global error handling or notifications
        console.error('Failed to fetch responses:', error);
      },
    });
  };

  /**
   * Function to submit a form response
   */
  const submitFormResponse = async (formId, responseData) => {
    if (!formId) {
      console.warn('submitFormResponse called without a formId.');
      throw new Error('Form ID is required to submit a response.');
    }

    const groupId = organizationData.groupId;
    if (!groupId) {
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      // Compress images in responseData.fields
      const compressedFields = await compressImageFields(responseData.fields);
      responseData.fields = compressedFields;

      // Define the path to the responses collection under the new structure
      const responsesCollection = collection(
        firestore,
        'formGroups',
        groupId,
        'forms',
        formId,
        'responses'
      );

      // Add the response data with a timestamp
      const docRef = await addDoc(responsesCollection, {
        ...responseData,
        submittedAt: serverTimestamp(),
      });

      // **Additional Logic for "groupsForm"**
      if (formId === 'groupsForm') {
        // Transform responseData.fields into a document
        const groupData = {};
        responseData.fields.forEach((field) => {
          groupData[field.id] = field.value;
        });
        // Add createdAt, updatedAt, organizationId, and id
        groupData.createdAt = serverTimestamp();
        groupData.updatedAt = serverTimestamp();
        groupData.organizationId = doc(firestore, 'organization', organizationData.organizationId);
        groupData.id = docRef.id;

        // Create a new document in the "groups" collection
        const groupsDocRef = doc(firestore, 'groups', docRef.id);
        await setDoc(groupsDocRef, groupData);

        // **Existing Logic to create formGroups/{groupId} document**
        const formGroupDocRef = doc(firestore, 'formGroups', docRef.id);
        const formGroupData = {
          id: docRef.id,
          organizationId: organizationData.organizationId,
        };
        await setDoc(formGroupDocRef, formGroupData);

        // Copy default forms to the new group's forms collection
        const defaultFormsRef = collection(firestore, 'defaultForms');
        const defaultFormsSnapshot = await getDocs(defaultFormsRef);
        const formsBatch = writeBatch(firestore);

        defaultFormsSnapshot.forEach((defaultFormDoc) => {
          const defaultFormData = defaultFormDoc.data();

          // Add groupId and organizationId to each form
          defaultFormData.groupId = docRef.id;
          defaultFormData.organizationId = organizationData.organizationId;

          // Set the document in 'formGroups/{groupId}/forms/{formId}'
          const defaultFormId = defaultFormDoc.id;
          const targetFormDocRef = doc(
            firestore,
            'formGroups',
            docRef.id,
            'forms',
            defaultFormId
          );
          formsBatch.set(targetFormDocRef, defaultFormData);
        });

        // Commit the batch operation for forms
        await formsBatch.commit();

        // **New Logic to create roleGroups/{groupId} document**
        const roleGroupDocRef = doc(firestore, 'roleGroups', docRef.id);
        const roleGroupData = {
          id: docRef.id,
          organizationId: organizationData.organizationId,
        };
        await setDoc(roleGroupDocRef, roleGroupData);

        // Copy default roles to the new role group's roles collection
        const defaultRolesRef = collection(firestore, 'defaultRoles');
        const defaultRolesSnapshot = await getDocs(defaultRolesRef);
        const rolesBatch = writeBatch(firestore);

        defaultRolesSnapshot.forEach((defaultRoleDoc) => {
          const defaultRoleData = defaultRoleDoc.data();

          // Add groupId and organizationId to each role
          defaultRoleData.groupId = docRef.id;
          defaultRoleData.organizationId = organizationData.organizationId;

          // Set the document in 'roleGroups/{groupId}/roles/{roleId}'
          const roleId = defaultRoleDoc.id;
          const targetRoleDocRef = doc(
            firestore,
            'roleGroups',
            docRef.id,
            'roles',
            roleId
          );

          rolesBatch.set(targetRoleDocRef, defaultRoleData);
        });

        // Commit the batch operation for roles
        await rolesBatch.commit();

        // **New Logic to add the Admin role to the current user**
        if (auth.currentUser) {
          const currentUserId = auth.currentUser.uid;

          // Reference to the user's document
          const userDocRef = doc(firestore, 'users', currentUserId);

          // Get the user's document
          const userSnapshot = await getDoc(userDocRef);
          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();

            // Get the existing roles array or initialize it
            let rolesArray = userData.roles || [];

            // Add the new role entry
            rolesArray.push({
              groupId: doc(firestore, 'groups', docRef.id), // The new group ID as a reference
              organizationId: organizationData.organizationId,
              role: doc(
                firestore,
                'roleGroups',
                docRef.id,
                'roles',
                'Qk6agHI01TjA0vqGqUGT'
              ), // Admin role reference
            });

            // Update the user's document
            await updateDoc(userDocRef, { roles: rolesArray });
          } else {
            console.warn(`User document for user ID ${currentUserId} does not exist.`);
          }
        } else {
          console.warn('No authenticated user found when trying to add admin role.');
        }
      }

      // Invalidate the responses query to refetch after submission
      queryClient.invalidateQueries([
        'formGroups',
        groupId,
        'forms',
        formId,
        'responses',
      ]);

      return docRef.id; // Return Firestore document ID
    } catch (error) {
      console.error('Error submitting response:', error);
      throw error;
    }
  };

  /**
   * Function to update a response
   */
  const updateResponse = async (formId, responseId, responseData) => {
    if (!formId || !responseId) {
      console.warn('updateResponse called without formId or responseId.');
      throw new Error('Form ID and Response ID are required to update a response.');
    }

    const groupId = organizationData.groupId;
    if (!groupId) {
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      // Compress images in responseData.fields
      const compressedFields = await compressImageFields(responseData.fields);
      responseData.fields = compressedFields;

      // Define the path to the specific response document under the new structure
      const responseDocRef = doc(
        firestore,
        'formGroups',
        groupId,
        'forms',
        formId,
        'responses',
        responseId
      );

      // Update the response data with an updated timestamp
      await updateDoc(responseDocRef, {
        ...responseData,
        updatedAt: serverTimestamp(),
      });

      // **Additional Logic for "groupsForm"**
      if (formId === 'groupsForm') {
        // Transform responseData.fields into a document
        const groupData = {};
        responseData.fields.forEach((field) => {
          groupData[field.id] = field.value;
        });
        // Add updatedAt, organizationId, and groupId
        groupData.updatedAt = serverTimestamp();
        groupData.organizationId = doc(firestore, 'organization', organizationData.organizationId);
        groupData.id = responseId;

        const groupsDocRef = doc(firestore, 'groups', responseId);
        await updateDoc(groupsDocRef, groupData);
      }

      // Invalidate the responses query to refetch after update
      queryClient.invalidateQueries(['formGroups', groupId, 'forms', formId, 'responses']);
    } catch (error) {
      console.error('Error updating response:', error);
      throw error;
    }
  };

  /**
   * Function to delete a response
   */
  const deleteResponse = async (formId, responseId) => {
    if (!formId || !responseId) {
      console.warn('deleteResponse called without formId or responseId.');
      throw new Error('Form ID and Response ID are required to delete a response.');
    }

    const groupId = organizationData.groupId;
    if (!groupId) {
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      // Define the path to the specific response document under the new structure
      const responseDocRef = doc(
        firestore,
        'formGroups',
        groupId,
        'forms',
        formId,
        'responses',
        responseId
      );

      // Delete the response document
      await deleteDoc(responseDocRef);

      // **Additional Logic for "groupsForm"**
      if (formId === 'groupsForm') {
        const groupsDocRef = doc(firestore, 'groups', responseId);
        await deleteDoc(groupsDocRef);
      }

      // Invalidate the responses query to refetch after deletion
      queryClient.invalidateQueries(['formGroups', groupId, 'forms', formId, 'responses']);
    } catch (error) {
      console.error('Error deleting response:', error);
      throw error;
    }
  };

  return (
    <ResponseContext.Provider
      value={{
        fetchResponsesForForm,
        useResponses,
        submitFormResponse,
        updateResponse,
        deleteResponse,
      }}
    >
      {children}
    </ResponseContext.Provider>
  );
};