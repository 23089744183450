// src/utils/imageUtils.js

export const compressBase64Image = (base64Image) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        // Create a canvas with the desired dimensions
        const canvas = document.createElement('canvas');
        const maxWidth = 800; // Maximum width
        const maxHeight = 800; // Maximum height
  
        let width = img.width;
        let height = img.height;
  
        // Calculate the scaling factor to maintain aspect ratio
        if (width > height) {
          if (width > maxWidth) {
            height = Math.round((height *= maxWidth / width));
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = Math.round((width *= maxHeight / height));
            height = maxHeight;
          }
        }
  
        canvas.width = width;
        canvas.height = height;
  
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
  
        // Compress the image
        const compressedBase64 = canvas.toDataURL('image/png', 0.7); // Adjust quality as needed (0.0 - 1.0)
        resolve(compressedBase64);
      };
      img.onerror = function (error) {
        console.error('Error loading image:', error);
        reject(error);
      };
      img.src = base64Image;
    });
  };
  
  /**
   * Helper function to compress images in responseData.fields
   */
  export const compressImageFields = async (fields) => {
    const processedFields = await Promise.all(
      fields.map(async (field) => {
        if (
          field.type === 'ImagePicker' &&
          field.value &&
          field.value.startsWith('data:image/')
        ) {
          // Compress the image
          const compressedValue = await compressBase64Image(field.value);
          return {
            ...field,
            value: compressedValue,
          };
        } else {
          return field;
        }
      })
    );
    return processedFields;
  };
  