// src/components/Authentication/SignUpSteps/Step2Form.jsx

import React, { useState, useContext, useRef, useEffect } from 'react';
import { DataContext } from '../../../DataContext';
import Spinner from '../../Common/Spinner';
import { useTranslation } from 'react-i18next';

const Step2Form = ({ onNext, onBack, formData }) => {
  const { t } = useTranslation();
  const [codeDigits, setCodeDigits] = useState(Array(6).fill(''));
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const { verifyValidationCode, sendValidationCode } = useContext(DataContext);
  const inputRefs = useRef([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Timer effect for the resend button
  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [resendTimer]);

  const handleChange = (e, index) => {
    const value = e.target.value.toUpperCase();
    if (/^[A-Z0-9]?$/.test(value)) {
      const newCodeDigits = [...codeDigits];
      newCodeDigits[index] = value;
      setCodeDigits(newCodeDigits);
      if (value) {
        if (index < 5) {
          inputRefs.current[index + 1].focus();
        } else {
          inputRefs.current[index].blur();
        }
      }
      setErrorMessage('');
      setSuccessMessage('');
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !codeDigits[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('Text').toUpperCase();
    if (/^[A-Z0-9]{6}$/.test(pasteData)) {
      const newCodeDigits = pasteData.split('');
      setCodeDigits(newCodeDigits);
      inputRefs.current[5].focus();
      handleSubmit(newCodeDigits.join(''));
    } else {
      setErrorMessage(t('signUp.invalidPaste'));
    }
  };

  const handleSubmit = async (code) => {
    if (!code) {
      code = codeDigits.join('');
    }
    if (code.length < 6) {
      setErrorMessage(t('signUp.codeIncomplete'));
      return;
    }
    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    try {
      await verifyValidationCode(formData.email, code);
      onNext({ codeVerified: true });
    } catch (error) {
      console.error('Error verifying code:', error);
      setErrorMessage(t('signUp.errorVerifyingCode'));
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    setResendLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    try {
      await sendValidationCode(formData.email);
      setResendTimer(60); // 60 seconds timer
      setSuccessMessage(t('signUp.codeResent'));
    } catch (error) {
      console.error('Error resending code:', error);
      setErrorMessage(t('signUp.errorResendingCode'));
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <>
      <h2>{t('signUp.step2Title')}</h2>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        className="sign-up-form"
      >
        <div className="sign-up-code-inputs">
          {codeDigits.map((digit, index) => (
            <input
              key={index}
              ref={(el) => (inputRefs.current[index] = el)}
              type="text"
              maxLength="1"
              value={digit}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onPaste={index === 0 ? handlePaste : null}
              required
              className="sign-up-code-input"
            />
          ))}
        </div>
        <button type="submit" className="sign-up-submit-button" disabled={loading}>
          {loading ? <Spinner /> : t('signUp.verifyCodeButton')}
        </button>
        <button type="button" className="sign-up-back-button" onClick={() => onBack({})}>
          {t('signUp.backButton')}
        </button>
        <div className="resend-code-container">
          <button
            type="button"
            className="resend-code-button"
            onClick={handleResendCode}
            disabled={resendLoading || resendTimer > 0}
          >
            {resendLoading ? (
              <Spinner />
            ) : resendTimer > 0 ? (
              `${t('signUp.resendCodeButton')} (${resendTimer}s)`
            ) : (
              t('signUp.resendCodeButton')
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default Step2Form;
