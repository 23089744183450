// src/components/Authentication/OrganizationGroupSelectionPage.jsx

import React, { useState, useEffect, useContext, useRef } from 'react';
import { DataContext } from '../../DataContext';
import { firestore } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './OrganizationGroupSelectionPage.css';
import CatholicoreLogoWhite from '../../assets/CatholicoreLogoWhite.png';
import IconMap from '../Common/IconMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OrganizationGroupSelectionPage = () => {
  const { userRoles, userRolesLoading, signOut } = useContext(DataContext);
  const navigate = useNavigate();

  const [organizationData, setOrganizationData] = useState({});
  const [groupData, setGroupData] = useState({});
  const [loading, setLoading] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState('#ffffff');

  const groupContainersRef = useRef({});
  const [chevronVisibility, setChevronVisibility] = useState({});

  // Utility function to determine if a color is light or dark
  const isColorLight = (hexColor) => {
    if (!hexColor) return true;
    const c = hexColor.substring(1); // strip #
    const rgb = parseInt(c, 16); // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff; // extract red
    const g = (rgb >> 8) & 0xff; // extract green
    const b = (rgb >> 0) & 0xff; // extract blue
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    return luma > 185;
  };

  // Handle logout
  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userRolesLoading || userRoles === null) {
          // Wait until userRoles are loaded
          return;
        }

        if (!userRoles || userRoles.length === 0) {
          setLoading(false);
          return;
        }

        // Group roles by organizationId
        const rolesByOrg = {};
        for (const roleEntry of userRoles) {
          const organizationId = roleEntry.organizationId;
          const groupIdRef = roleEntry.groupId;
          const groupId = groupIdRef && groupIdRef.id;

          if (!rolesByOrg[organizationId]) {
            rolesByOrg[organizationId] = [];
          }
          rolesByOrg[organizationId].push(groupId);
        }

        // Fetch organization data
        const orgData = {};
        for (const orgId of Object.keys(rolesByOrg)) {
          const orgDocRef = doc(firestore, 'organization', orgId);
          const orgSnapshot = await getDoc(orgDocRef);
          if (orgSnapshot.exists()) {
            const orgInfo = orgSnapshot.data();
            orgData[orgId] = orgInfo;
          } else {
            orgData[orgId] = { name: 'Unknown Organization' };
          }
        }

        // Fetch group data
        const grpData = {};
        for (const groupIds of Object.values(rolesByOrg)) {
          for (const grpId of groupIds) {
            if (!grpData[grpId]) {
              const grpDocRef = doc(firestore, 'groups', grpId);
              const grpSnapshot = await getDoc(grpDocRef);
              if (grpSnapshot.exists()) {
                const grpInfo = grpSnapshot.data();
                grpData[grpId] = grpInfo;
              } else {
                grpData[grpId] = {
                  groupName: 'Unknown Group',
                  color: '#cccccc',
                  profilePicture: '',
                };
              }
            }
          }
        }

        setOrganizationData(orgData);
        setGroupData(grpData);

        // Set background color based on the organization's group color
        for (const orgId of Object.keys(orgData)) {
          const matchingGroup = grpData[orgId];
          if (matchingGroup && matchingGroup.color) {
            setBackgroundColor(matchingGroup.color);
            break;
          }
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching organization or group data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [userRoles, userRolesLoading]);

  useEffect(() => {
    if (!loading) {
      const orgIds = Object.keys(organizationData);
      const cleanupFns = [];

      orgIds.forEach((orgId) => {
        const container = groupContainersRef.current[orgId];
        if (container) {
          const updateChevronVisibility = () => {
            const scrollLeft = container.scrollLeft;
            const scrollWidth = container.scrollWidth;
            const clientWidth = container.clientWidth;

            const maxScrollLeft = scrollWidth - clientWidth;

            const canScroll = scrollWidth > clientWidth;

            const canScrollLeft = canScroll && scrollLeft > 0;
            const canScrollRight = canScroll && scrollLeft < maxScrollLeft;

            setChevronVisibility((prev) => ({
              ...prev,
              [orgId]: {
                canScrollLeft,
                canScrollRight,
              },
            }));
          };

          updateChevronVisibility();
          container.addEventListener('scroll', updateChevronVisibility);
          window.addEventListener('resize', updateChevronVisibility);

          // Cleanup function
          const cleanup = () => {
            container.removeEventListener('scroll', updateChevronVisibility);
            window.removeEventListener('resize', updateChevronVisibility);
          };
          cleanupFns.push(cleanup);
        }
      });

      // Cleanup function for useEffect
      return () => {
        cleanupFns.forEach((cleanupFn) => cleanupFn());
      };
    }
  }, [loading, organizationData]);

  const handleEnterGroup = (groupId) => {
    navigate(`/${groupId}/main`);
  };

  const handleScrollLeft = (orgId) => {
    const container = groupContainersRef.current[orgId];
    if (container) {
      container.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const handleScrollRight = (orgId) => {
    const container = groupContainersRef.current[orgId];
    if (container) {
      container.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  if (userRolesLoading || loading) {
    return (
      <div className="organization-group-selection-page">Loading...</div>
    );
  }

  if (!userRoles || userRoles.length === 0) {
    return (
      <div className="organization-group-selection-page">
        You have no roles assigned.
      </div>
    );
  }

  return (
    <div
      className="organization-group-selection-page"
      style={{ backgroundColor: backgroundColor || '#ffffff' }}
    >
      {/* Catholicore logo at the top */}
      <div className="organization-logo">
        <img src={CatholicoreLogoWhite} alt="Catholicore Logo" />
      </div>
      {/* Logout button at the top right */}
      <div className="logout-button">
        <button onClick={handleLogout}>Log out</button>
      </div>
      <div className="organization-content">
        {Object.keys(organizationData).map((orgId) => {
          const rolesForOrg = userRoles.filter(
            (role) => role.organizationId === orgId
          );
          const hasMultipleCards = rolesForOrg.length > 1;
          return (
            <div key={orgId} className="organization-container">
              <h2>{organizationData[orgId].name}</h2>
              <div className="group-cards-wrapper">
                {hasMultipleCards && (
                  <button
                    className="chevron-button left"
                    onClick={() => handleScrollLeft(orgId)}
                    disabled={!chevronVisibility[orgId]?.canScrollLeft}
                  >
                    <FontAwesomeIcon icon={IconMap['faChevronLeft']} />
                  </button>
                )}
                <div
                  className="group-cards-container"
                  ref={(el) => (groupContainersRef.current[orgId] = el)}
                >
                  {rolesForOrg.map((role) => {
                    const groupId = role.groupId.id;
                    const group = groupData[groupId];
                    if (!group) {
                      return null;
                    }
                    const textColor = isColorLight(group.color)
                      ? '#000'
                      : '#fff';
                    return (
                      <div
                        key={groupId}
                        className="group-card"
                        style={{
                          backgroundColor: group.color || '#ffffff',
                          color: textColor,
                        }}
                      >
                        {group.profilePicture ? (
                          <img
                            src={group.profilePicture}
                            alt={group.groupName}
                            className="group-logo"
                          />
                        ) : (
                          <div className="group-logo-placeholder">
                            {group.groupName.charAt(0)}
                          </div>
                        )}
                        <h3>{group.groupName}</h3>
                        <button onClick={() => handleEnterGroup(groupId)}>
                          Enter
                        </button>
                      </div>
                    );
                  })}
                </div>
                {hasMultipleCards && (
                  <button
                    className="chevron-button right"
                    onClick={() => handleScrollRight(orgId)}
                    disabled={!chevronVisibility[orgId]?.canScrollRight}
                  >
                    <FontAwesomeIcon icon={IconMap['faChevronRight']} />
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OrganizationGroupSelectionPage;
