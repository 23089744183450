// src/components/Authentication/SignUpSteps/Step6Form.jsx

import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../Common/Spinner';
import { useTranslation } from 'react-i18next';

const Step6Form = ({ formData, response }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!response) {
      // Handle the case where response is not yet available
      return;
    }

    // Redirect after 5 seconds
    const timer = setTimeout(() => {
      const groupId = response.groupId;
      navigate(`/${groupId}/login`);
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate, response]);

  return (
    <div className="building-organization">
      <h2>{t('signUp.buildingOrganizationTitle', { organizationName: formData.organizationName })}</h2>
      <p>{t('signUp.buildingOrganizationMessage')}</p>
      <Spinner />
    </div>
  );
};

export default Step6Form;