import React, { useContext, useState } from 'react';
import './TeamTypesModal.css';
import CustomModal from '../Common/CustomModal';
import { TeamContext } from '../../contexts/TeamContext';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import ActionButton from '../Common/ActionButton';

// Import the new IconMapSelector
import IconMapSelector from '../Common/IconMapSelector';

const TeamTypesModal = ({ show, onClose }) => {
  const {
    teamTypes,
    addTeamType,
    deleteTeamType,
    editTeamType,
    fetchTeamTypes,
  } = useContext(TeamContext);

  const [newTypeName, setNewTypeName] = useState('');
  const [newTypeIcon, setNewTypeIcon] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [typeToDelete, setTypeToDelete] = useState(null);

  // CREATE new type
  const handleAddType = async () => {
    if (!newTypeName.trim()) return;
    try {
      await addTeamType({ name: newTypeName.trim(), icon: newTypeIcon });
      setNewTypeName('');
      setNewTypeIcon('');
    } catch (error) {
      console.error(error);
    }
  };

  // DELETE type
  const handleDeleteType = (type) => {
    setTypeToDelete(type);
    setDeleteModalOpen(true);
  };
  const confirmDeleteType = async () => {
    if (!typeToDelete) return;
    try {
      await deleteTeamType(typeToDelete.id);
    } catch (error) {
      console.error(error);
    } finally {
      setDeleteModalOpen(false);
      setTypeToDelete(null);
    }
  };

  // EDIT name/icon
  const handleNameChange = (typeId, newName) => {
    editTeamType(typeId, { name: newName });
    fetchTeamTypes();
  };
  const handleIconChange = (typeId, newIcon) => {
    editTeamType(typeId, { icon: newIcon });
    fetchTeamTypes();
  };

  // REORDER up/down
  const handleMoveUp = (type) => {
    const currentIndex = teamTypes.findIndex((t) => t.id === type.id);
    if (currentIndex <= 0) return;
    const prevType = teamTypes[currentIndex - 1];
    editTeamType(type.id, { order: prevType.order });
    editTeamType(prevType.id, { order: type.order });
    fetchTeamTypes();
  };
  const handleMoveDown = (type) => {
    const currentIndex = teamTypes.findIndex((t) => t.id === type.id);
    if (currentIndex >= teamTypes.length - 1) return;
    const nextType = teamTypes[currentIndex + 1];
    editTeamType(type.id, { order: nextType.order });
    editTeamType(nextType.id, { order: type.order });
    fetchTeamTypes();
  };

  return (
    <>
      <CustomModal show={show} onClose={onClose} title="Manage Team Types">
        <div className="team-types-modal-container">
          {teamTypes.map((type, index) => {
            const isFirst = index === 0;
            const isLast = index === teamTypes.length - 1;
            return (
              <div key={type.id} className="team-types-card">
                <div className="team-types-main">
                  <IconMapSelector
                    selectedIcon={type.icon || ''}
                    onChange={(val) => handleIconChange(type.id, val)}
                  />
                  <input
                    type="text"
                    className="type-name-input"
                    value={type.name}
                    onChange={(e) => handleNameChange(type.id, e.target.value)}
                    placeholder="Team Type Name"
                  />
                </div>

                <div className="team-types-card-actions">
                  <ActionButton
                    onClick={() => handleMoveUp(type)}
                    icon="faArrowUp"
                    colorType="secondary"
                    disabled={isFirst}
                  />
                  <ActionButton
                    onClick={() => handleMoveDown(type)}
                    icon="faArrowDown"
                    colorType="secondary"
                    disabled={isLast}
                  />
                  <ActionButton
                    onClick={() => handleDeleteType(type)}
                    icon="faTrash"
                    colorType="danger"
                  />
                </div>
              </div>
            );
          })}

          {/* Add New Team Type */}
          <div className="add-team-type-section">
            <h4>Create New Team Type</h4>
            <div className="add-team-type-row">
              <input
                type="text"
                value={newTypeName}
                onChange={(e) => setNewTypeName(e.target.value)}
                placeholder="Team Type Name"
              />
              <IconMapSelector
                selectedIcon={newTypeIcon}
                onChange={(val) => setNewTypeIcon(val)}
                placeholder="None"
              />
              <ActionButton
                onClick={handleAddType}
                icon="faPlus"
                colorType="primary"
                text=""
              />
            </div>
          </div>
        </div>
      </CustomModal>

      {/* Delete Confirmation Modal */}
      {deleteModalOpen && (
        <DeleteConfirmationModal
          show={deleteModalOpen}
          onConfirm={confirmDeleteType}
          onCancel={() => setDeleteModalOpen(false)}
          title="Delete Team Type"
          message={`Are you sure you want to delete the "${typeToDelete?.name}" team type?`}
        />
      )}
    </>
  );
};

export default TeamTypesModal;
