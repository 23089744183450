// src/components/FormBuilder/Fields/TextAreaField.jsx

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './TextAreaField.css';

const TextAreaField = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  rows,
  length,
  required, // Indicates if the field is required
  size, // Determines the width of the field
  disabled,
}) => {
  const [error, setError] = useState('');

  const handleBlur = () => {
    if (required && value.trim() === '') {
      setError('This field is required.');
    } else {
      setError('');
    }
  };

  const handleChange = (e) => {
    onChange(e.target.value);

    if (required && e.target.value.trim() === '') {
      setError('This field is required.');
    } else {
      setError('');
    }
  };

  return (
    <div className={`form-group`}>
      <label htmlFor={name}>
        {label} {required && <span className="required-asterisk">*</span>}
      </label>
      <textarea
        id={name}
        name={name}
        value={value}
        onChange={handleChange} // Pass only the value
        onBlur={handleBlur} // Attach the onBlur handler if provided
        placeholder={placeholder}
        className={`form-control ${error ? 'input-error' : ''}`}
        rows={rows}
        maxLength={length} // Enforce maximum length if provided
        disabled={disabled}
      ></textarea>
      <div className="form-footer">
        {length && (
          <small className="char-count">
            {value.length}/{length} characters
          </small>
        )}
        {error && <span className="error-message">{error}</span>}
      </div>
    </div>
  );
};

TextAreaField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  length: PropTypes.number, // Maximum number of characters allowed
  required: PropTypes.bool, // Indicates if the field is required
  size: PropTypes.oneOf(['full', 'half']), // Determines the width of the field
  disabled: PropTypes.bool,
};

TextAreaField.defaultProps = {
  placeholder: '',
  rows: 4,
  length: null, // No maximum length by default
  required: false,
  size: 'full', // Default size is full width
  disabled: false,
};

export default TextAreaField;
