// src/contexts/GroupContext.js

import React, { createContext, useContext } from 'react';
import { firestore } from '../firebase';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { useQuery } from '@tanstack/react-query';
import { UserContext } from './UserContext';

export const GroupContext = createContext();

export const GroupProvider = ({ children }) => {
  const { organizationData } = useContext(UserContext);

  // Fetch groups data using React Query.
  const {
    data: groupsData,
    isLoading: groupsLoading,
    error: groupsError,
    refetch: refetchGroups,
  } = useQuery({
    queryKey: ['groups'],
    queryFn: async () => {
      const groupsRef = collection(firestore, 'groups');
      const q = query(groupsRef, orderBy('groupName', 'asc'));
      const snapshot = await getDocs(q);
      const fetchedData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // Filter groups based on organizationData
      const filteredGroups = fetchedData.filter((group) => {
        if (organizationData.groupId === organizationData.organizationId) {
          // Show all groups with matching organizationId
          return (
            group.organizationId.id === organizationData.organizationId &&
            group.id !== organizationData.organizationId
          );
        } else {
          // Show groups matching groupId and organizationId
          return (
            group.id === organizationData.groupId &&
            group.organizationId.id === organizationData.organizationId
          );
        }
      });
      return filteredGroups;
    },
    enabled: !!firestore && !!organizationData.groupId && !!organizationData.organizationId,
    onError: (err) => {
      console.error('Error fetching groups:', err);
    },
  });

  return (
    <GroupContext.Provider
      value={{
        groupsData,
        groupsLoading,
        groupsError,
        refetchGroups,
      }}
    >
      {children}
    </GroupContext.Provider>
  );
};

export default GroupProvider;
