// src/contexts/AppSettingsContext.js

import React, { createContext, useContext } from 'react';
import { firestore, storage } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { defaultColors, defaultLanguage, defaultLogo } from '../config';
import { UserContext } from './UserContext';

export const AppSettingsContext = createContext();

export const AppSettingsProvider = ({ children }) => {
  const { organizationData } = useContext(UserContext);
  const queryClient = useQueryClient();

  const {
    data: appSettingsData,
    isLoading: appSettingsLoading,
    error: appSettingsError,
    refetch: refetchAppSettings,
  } = useQuery({
    queryKey: ['appSettings', organizationData.groupId],
    queryFn: async () => {
      if (organizationData.groupId) {
        const settingsDocRef = doc(firestore, 'groups', organizationData.groupId);
        const docSnapshot = await getDoc(settingsDocRef);
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          const appSettings = {
            profilePicture: data.profilePicture,
            color: data.color,
            language: data.language,
          };
          return appSettings;
        } else {
          // If group does not have settings, use defaults
          return {
            profilePicture: defaultLogo.main,
            color: defaultColors.main,
            language: defaultLanguage.main,
          };
        }
      } else {
        // No groupId; return default settings
        return {
          profilePicture: defaultLogo.main,
          color: defaultColors.main,
          language: defaultLanguage.main,
        };
      }
    },
    enabled: !!firestore,
    keepPreviousData: true,
    onError: (err) => {
      console.error('Error fetching appSettings:', err);
    },
  });

  // Mutation to update appSettings
  const updateAppSettingsMutation = useMutation({
    mutationFn: async (newSettings) => {
      const settingsDocRef = doc(firestore, 'groups', organizationData.groupId);
      await setDoc(settingsDocRef, newSettings, { merge: true });
    },
    onMutate: async (newSettings) => {
      await queryClient.cancelQueries(['appSettings', organizationData.groupId]);

      const previousAppSettings = queryClient.getQueryData(['appSettings', organizationData.groupId]);

      queryClient.setQueryData(['appSettings', organizationData.groupId], (oldAppSettings) => ({
        ...oldAppSettings,
        ...newSettings,
      }));

      return { previousAppSettings };
    },
    onError: (err, newSettings, context) => {
      queryClient.setQueryData(['appSettings', organizationData.groupId], context.previousAppSettings);
      console.error('Error updating appSettings:', err);
    },
    onSettled: () => {
      // After mutation, invalidate and refetch appSettings
      queryClient.invalidateQueries(['appSettings', organizationData.groupId]);
    },
  });

  const updateSettings = async (newSettings, specificField = null) => {
    const settingsToUpdate = specificField ? { [specificField]: newSettings[specificField] } : newSettings;
    await updateAppSettingsMutation.mutateAsync(settingsToUpdate);
  };

  // Function to upload logo
  // Function to upload logo
  const uploadLogo = async (logoFile, onProgress) => {
    if (!organizationData.groupId) {
      throw new Error('Missing groupId in organizationData.');
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (event) {
        const img = new Image();
        img.onload = async function () {
          // Create a canvas with the desired dimensions
          const canvas = document.createElement('canvas');
          const maxWidth = 800; // Maximum width
          const maxHeight = 800; // Maximum height

          let width = img.width;
          let height = img.height;

          // Calculate the scaling factor to maintain aspect ratio
          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height *= maxWidth / width));
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width *= maxHeight / height));
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          // Compress the image
          const compressedBase64 = canvas.toDataURL('image/png', 0.7); // Adjust quality as needed (0.0 - 1.0)

          // Now update the group's profilePicture field in Firestore
          try {
            const settingsDocRef = doc(firestore, 'groups', organizationData.groupId);
            await setDoc(settingsDocRef, { profilePicture: compressedBase64 }, { merge: true });
            resolve(compressedBase64);
          } catch (error) {
            console.error('Error updating profilePicture in Firestore:', error);
            reject(error);
          }
        };
        img.onerror = function (error) {
          console.error('Error loading image:', error);
          reject(error);
        };
        img.src = event.target.result;
      };

      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        reject(error);
      };

      reader.onprogress = (event) => {
        if (event.lengthComputable && onProgress) {
          const progress = (event.loaded / event.total) * 100;
          onProgress(progress);
        }
      };

      reader.readAsDataURL(logoFile);
    });
  };


  return (
    <AppSettingsContext.Provider
      value={{
        appSettings: appSettingsData,
        appSettingsLoading,
        appSettingsError,
        updateSettings,
        refetchAppSettings,
        uploadLogo,
      }}
    >
      {children}
    </AppSettingsContext.Provider>
  );
};

export default AppSettingsProvider;
