// src/contexts/UserManagementContext.js

import React, { createContext, useContext, useState } from 'react';
import { firestore, auth } from '../firebase';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  addDoc,
  query,
  where,
  setDoc,
  deleteDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { useQueryClient } from '@tanstack/react-query';
import UserIcon from '../assets/UserIcon.png';
import { defaultLanguage } from '../config';
import { UserContext } from './UserContext';

export const UserManagementContext = createContext();

export const UserManagementProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [rolesMap, setRolesMap] = useState({});
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [isUsersError, setIsUsersError] = useState(null);

  const { organizationData } = useContext(UserContext);
  const queryClient = useQueryClient();

  // Function to fetch users and roles
  const fetchUsersAndRoles = async () => {
    if (!organizationData || !organizationData.groupId) {
      console.error('Organization data is not available.');
      return;
    }
    setIsUsersLoading(true);
    try {
      // Fetch roles from roleGroups/{groupId}/roles
      const rolesCollectionRef = collection(
        firestore,
        'roleGroups',
        organizationData.groupId,
        'roles'
      );
      const rolesSnapshot = await getDocs(rolesCollectionRef);
      const rolesList = rolesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const rolesMapTemp = {};
      rolesList.forEach((role) => {
        rolesMapTemp[role.id] = role;
      });
      setRolesMap(rolesMapTemp);
      setRoles(rolesList);

      // Fetch users
      const usersCollectionRef = collection(firestore, 'users');
      const usersSnapshot = await getDocs(usersCollectionRef);
      const usersList = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Filter users
      const filteredUsers = usersList.filter((user) => {
        return user.roles?.some((roleEntry) => {
          const groupIdRef = roleEntry.groupId;
          const groupId = groupIdRef && groupIdRef.id;
          return groupId === organizationData.groupId;
        });
      });

      // Map users with role names
      const usersWithRoles = filteredUsers.map((user) => {
        const roleNames = user.roles
          .filter((roleEntry) => {
            const groupIdRef = roleEntry.groupId;
            const groupId = groupIdRef && groupIdRef.id;
            return groupId === organizationData.groupId;
          })
          .map((roleEntry) => {
            const roleRef = roleEntry.role;
            const roleId = roleRef && roleRef.id;
            return rolesMapTemp[roleId]?.name || 'Unknown Role';
          });

        return {
          id: user.id,
          fullName: `${user.firstName} ${user.lastName}`,
          email: user.email,
          roleNames,
          ...user,
        };
      });

      setUsers(usersWithRoles);
    } catch (error) {
      console.error('Error fetching users and roles:', error);
      setIsUsersError(error);
    } finally {
      setIsUsersLoading(false);
    }
  };

  // Function to add a user
  const addUser = async ({ email, firstName, lastName, roleId }) => {
    if (!email || !firstName || !lastName || !roleId) {
      throw new Error('Missing required fields');
    }

    try {
      // Check if user exists
      const usersRef = collection(firestore, 'users');
      const q = query(usersRef, where('email', '==', email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // User exists, update roles
        const existingUserDoc = querySnapshot.docs[0];
        const existingUserData = existingUserDoc.data();
        let rolesArray = existingUserData.roles || [];

        const roleExists = rolesArray.some(
          (roleEntry) => roleEntry.groupId?.id === organizationData.groupId
        );

        if (roleExists) {
          rolesArray = rolesArray.map((roleEntry) => {
            if (roleEntry.groupId?.id === organizationData.groupId) {
              return {
                ...roleEntry,
                role: doc(
                  firestore,
                  'roleGroups',
                  organizationData.groupId,
                  'roles',
                  roleId
                ),
                organizationId: organizationData.organizationId,
              };
            }
            return roleEntry;
          });
        } else {
          rolesArray.push({
            groupId: doc(firestore, 'groups', organizationData.groupId),
            organizationId: organizationData.organizationId,
            role: doc(
              firestore,
              'roleGroups',
              organizationData.groupId,
              'roles',
              roleId
            ),
          });
        }

        const userDocRef = doc(firestore, 'users', existingUserDoc.id);
        await updateDoc(userDocRef, {
          firstName,
          lastName,
          roles: rolesArray,
        });

        await fetchUsersAndRoles();
        return { action: 'updated', userId: existingUserDoc.id };
      } else {
        // Create new user via cloud function
        const currentUser = auth.currentUser;
        if (currentUser) {
          const idToken = await currentUser.getIdToken();

          const response = await fetch('https://create-user-lgfph5hmwq-uc.a.run.app', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({
              email,
              firstName,
              lastName,
              groupId: organizationData.groupId,
              roles: [
                {
                  groupId: `groups/${organizationData.groupId}`,
                  organizationId: organizationData.organizationId,
                  role: `roleGroups/${organizationData.groupId}/roles/${roleId}`,
                },
              ],
              active: true,
              language: defaultLanguage.main,
              pictureProfile: UserIcon,
            }),
          });

          const data = await response.json();

          if (response.ok) {
            await resetUserPassword(email);
            await fetchUsersAndRoles();
            return { action: 'created', userId: data.uid };
          } else {
            throw new Error(`Error creating user: ${data.message || data}`);
          }
        } else {
          throw new Error('User not authenticated');
        }
      }
    } catch (error) {
      console.error('Error adding user:', error);
      throw error;
    }
  };

  // Function to edit a user
  const editUser = async (userId, { firstName, lastName, roleId }) => {
    if (!userId || !firstName || !lastName || !roleId) {
      throw new Error('Missing required fields');
    }

    try {
      const userDocRef = doc(firestore, 'users', userId);
      const userSnapshot = await getDoc(userDocRef);
      if (userSnapshot.exists()) {
        const existingUserData = userSnapshot.data();
        let rolesArray = existingUserData.roles || [];

        const roleExists = rolesArray.some(
          (roleEntry) => roleEntry.groupId?.id === organizationData.groupId
        );

        if (roleExists) {
          rolesArray = rolesArray.map((roleEntry) => {
            if (roleEntry.groupId?.id === organizationData.groupId) {
              return {
                ...roleEntry,
                role: doc(
                  firestore,
                  'roleGroups',
                  organizationData.groupId,
                  'roles',
                  roleId
                ),
                organizationId: organizationData.organizationId,
              };
            }
            return roleEntry;
          });
        } else {
          rolesArray.push({
            groupId: doc(firestore, 'groups', organizationData.groupId),
            organizationId: organizationData.organizationId,
            role: doc(
              firestore,
              'roleGroups',
              organizationData.groupId,
              'roles',
              roleId
            ),
          });
        }

        // Update the user document with additional fields
        await updateDoc(userDocRef, {
          firstName,
          lastName,
          roles: rolesArray,
          active: true,
          language: defaultLanguage.main,
          pictureProfile: UserIcon,
        });

        await fetchUsersAndRoles();
        return { action: 'updated', userId };
      } else {
        throw new Error('User does not exist');
      }
    } catch (error) {
      console.error('Error editing user:', error);
      throw error;
    }
  };

  // Function to delete a user
  const deleteUser = async (userId) => {
    if (!userId) {
      throw new Error('User ID is required');
    }

    try {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const idToken = await currentUser.getIdToken();
        const response = await fetch('https://delete-user-lgfph5hmwq-uc.a.run.app', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({ uid: userId, groupId: organizationData.groupId }),
        });

        const data = await response.json();

        if (response.ok) {
          await fetchUsersAndRoles();
          return { action: 'deleted', userId };
        } else {
          throw new Error(`Error deleting user: ${data.message || data}`);
        }
      } else {
        throw new Error('User not authenticated');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      throw error;
    }
  };

  // Function to reset user password
  const resetUserPassword = async (email) => {
    if (!email) {
      throw new Error('Email is required');
    }

    try {
      await auth.sendPasswordResetEmail(email);
    } catch (error) {
      console.error('Error sending password reset email:', error);
      throw error;
    }
  };

  
  return (
    <UserManagementContext.Provider
      value={{
        users,
        roles,
        rolesMap,
        isUsersLoading,
        isUsersError,
        fetchUsersAndRoles,
        addUser,
        editUser,
        deleteUser,
        resetUserPassword,
      }}
    >
      {children}
    </UserManagementContext.Provider>
  );
};

export default UserManagementProvider;