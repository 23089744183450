// src/components/Toast.jsx

import React, { useEffect } from 'react';
import './Toast.css'; // Import the corresponding CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useColorSettings } from './ColorSettingsContext'; // Import the custom hook

const Toast = ({ message, type, onClose, duration = 10000 }) => {
  const { color, loading } = useColorSettings(); // Consume the context

  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    // Cleanup the timer if the component is unmounted before the timeout
    return () => clearTimeout(timer);
  }, [duration, onClose]);

  // Determine the icon based on the type
  const getIcon = () => {
    switch (type) {
      case 'success':
        return faCheckCircle;
      case 'error':
        return faExclamationCircle;
      case 'info':
        return faInfoCircle;
      default:
        return faInfoCircle;
    }
  };

  // Determine the background color based on the type
  const getBackgroundColor = () => {
    switch (type) {
      case 'success':
        return color || '#bb8300'; // Use primary color from context
      case 'error':
        return '#dc3545'; // Red
      case 'info':
        return '#17a2b8'; // Teal
      default:
        return '#17a2b8'; // Default to Teal
    }
  };

  const toastStyle = {
    backgroundColor: getBackgroundColor(),
    animation: `fadeInOut ${duration}ms forwards`, // Apply combined animation
  };

  return (
    <div
      className={`toast toast-${type}`}
      style={toastStyle}
      role="alert"
      aria-live="assertive"
    >
      <div className="toast-content">
        <FontAwesomeIcon icon={getIcon()} className="toast-icon" />
        <span>{message}</span>
      </div>
      <button className="toast-close-button" onClick={onClose}>
        &times;
      </button>
    </div>
  );
};

export default Toast;
