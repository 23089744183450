// src/components/Common/Table.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './Table.css';
import ActionButton from './ActionButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faSort } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const Table = ({
  data,
  columns,
  actions,
  userRole,
  setSorting,
  sortField,
  sortOrder,
  noRecordsMessage,
  actionsHeaderLabel,
  onRowClick, // Prop for handling row clicks
}) => {
  const { t } = useTranslation();

  const formatDate = (value) => {
    const date = new Date(value);
    return isNaN(date.getTime()) ? value : date.toLocaleString();
  };

  const formatDateOnly = (value) => {
    const date = new Date(value);
    return isNaN(date.getTime()) ? value : date.toLocaleDateString();
  };

  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  const camelCaseToNormal = (text) => {
    if (!text) return '';
    const result = text
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase());
    return result;
  };

  // Function to render field value based on type for UI display
  const renderFieldValue = (field, record) => {
    const { accessor, type, render } = field;

    if (render) {
      return render(record);
    }

    let value;

    if (typeof accessor === 'function') {
      value = accessor(record);
    } else if (Array.isArray(accessor)) {
      if (type === 'Checkbox') {
        value = record[accessor[0]];
      } else {
        // If accessor is an array, concatenate the values
        value = accessor.map((key) => record[key] || '').join(' ').trim();
      }
    } else if (typeof accessor === 'string') {
      // If accessor is a string, directly access the field
      value = record[accessor];
    } else {
      // Handle other cases if necessary
      value = '';
    }

    if (Array.isArray(value)) {
      if (type === 'Checkbox') {
        return value
          .filter((option) => option.completed)
          .map((option) => {
            const optionLabel = camelCaseToNormal(t(option.name, option.name));
            return optionLabel;
          })
          .join(', ');
      }
      return value.join(', ');
    }

    let formattedValue;
    switch (type) {
      case 'ImagePicker':
        formattedValue = (
          <img
            src={value || '/default-profile.png'}
            alt={`${record.firstname || 'Image'}`}
            className="profile-picture"
          />
        );
        break;
      case 'DatePicker':
        formattedValue = isValidDate(new Date(value))
          ? formatDateOnly(value)
          : value?.toString();
        break;
      case 'DateTimePicker':
      case 'TimePicker':
        formattedValue = isValidDate(new Date(value))
          ? formatDate(value)
          : value?.toString();
        break;
      case 'RangeSlider':
      case 'Number':
        formattedValue = value?.toString();
        break;
      case 'RadioButton':
      case 'Select':
        formattedValue = value ? t(value.toString(), value.toString()) : '';
        break;
      case 'ToggleSwitch':
        formattedValue = value ? t('yes') : t('no');
        break;
      case 'TextArea':
      case 'Text':
      default:
        formattedValue =
          value !== null && value !== undefined ? value.toString() : '';
        break;
    }

    // Wrap the formatted value with a span if clickToEdit is enabled
    if (field.clickToEdit && onRowClick) {
      return (
        <span
          className="clickable-value"
          onClick={() => onRowClick(record)}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onRowClick(record);
            }
          }}
        >
          {formattedValue}
        </span>
      );
    }

    return formattedValue;
  };

  return (
    <div className="table-wrapper">
      <table className="generic-table">
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={column.id}
                className={`${column.sortable ? 'sortable' : ''} ${
                  column.hideInMobile ? 'hide-in-mobile' : ''
                }`}
                onClick={() => {
                  if (column.sortable) {
                    setSorting(column.id);
                  }
                }}
              >
                {t(column.id, column.label)}
                {column.sortable && (
                  <span className="sort-indicator">
                    {sortField === column.id ? (
                      sortOrder === 'asc' ? (
                        <FontAwesomeIcon icon={faSortUp} />
                      ) : (
                        <FontAwesomeIcon icon={faSortDown} />
                      )
                    ) : (
                      <FontAwesomeIcon icon={faSort} />
                    )}
                  </span>
                )}
              </th>
            ))}
            {actions.length > 0 && <th>{actionsHeaderLabel}</th>}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((record) => (
              <tr key={record.id}>
                {columns.map((column) => (
                  <td
                    key={column.id}
                    className={column.hideInMobile ? 'hide-in-mobile' : ''}
                  >
                    {renderFieldValue(column, record)}
                  </td>
                ))}
                {actions.length > 0 && (
                  <td className="actions-cell">
                    {actions.map((action, index) => {
                      if (action.condition && !action.condition(record, userRole)) {
                        return null;
                      }

                      const isDisabled = action.disabledAccessor
                        ? action.disabledAccessor(record)
                        : false;

                      const href = action.hrefAccessor
                        ? action.hrefAccessor(record)
                        : undefined;

                      return (
                        <ActionButton
                          key={index}
                          onClick={() => !href && action.callback(record)}
                          label={action.label}
                          icon={action.icon}
                          variant={action.variant || 'default'}
                          as={action.as || 'button'}
                          href={href}
                          disabled={isDisabled}
                        />
                      );
                    })}
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length + (actions.length > 0 ? 1 : 0)}>
                {noRecordsMessage || 'No records found.'}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      accessor: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.string),
      ]).isRequired,
      sortable: PropTypes.bool,
      type: PropTypes.string,
      render: PropTypes.func,
      clickToEdit: PropTypes.bool, // Prop for click-to-edit functionality
    })
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      callback: PropTypes.func.isRequired,
      variant: PropTypes.string,
      condition: PropTypes.func,
      as: PropTypes.string,
      hrefAccessor: PropTypes.func,
      disabledAccessor: PropTypes.func,
    })
  ),
  userRole: PropTypes.object,
  setSorting: PropTypes.func.isRequired,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  noRecordsMessage: PropTypes.string,
  actionsHeaderLabel: PropTypes.string,
  onRowClick: PropTypes.func, // Prop type added
};

Table.defaultProps = {
  actions: [],
  userRole: null,
  sortField: null,
  sortOrder: 'asc',
  noRecordsMessage: 'No records found.',
  actionsHeaderLabel: 'Actions',
  onRowClick: null, // Default value for the prop
};

export default Table;
