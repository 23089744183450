// src/contexts/TeamContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { firestore } from '../firebase';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { UserContext } from './UserContext';

export const TeamContext = createContext();

export const TeamProvider = ({ children }) => {
  const { organizationData } = useContext(UserContext);
  const [teams, setTeams] = useState([]);
  const [teamTypes, setTeamTypes] = useState([]);
  const [isTeamsLoading, setIsTeamsLoading] = useState(false);
  const [isTeamsError, setIsTeamsError] = useState(null);

  // ---------------------------
  // Fetch TEAMS
  // ---------------------------
  const fetchTeams = async () => {
    if (!organizationData || !organizationData.groupId) {
      console.error('Organization data is not available.');
      return;
    }
    setIsTeamsLoading(true);
    try {
      const teamsCollectionRef = collection(
        firestore,
        'teamGroups',
        organizationData.groupId,
        'teams'
      );
      const teamsSnapshot = await getDocs(teamsCollectionRef);
      const teamsList = teamsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Resolve member references and flatten member fields
      const teamsWithMemberData = await Promise.all(
        teamsList.map(async (team) => {
          const memberRefs = team.members || [];
          const membersData = await Promise.all(
            memberRefs.map(async (memberRef) => {
              const memberSnapshot = await getDoc(memberRef);
              if (memberSnapshot.exists()) {
                const memberData = memberSnapshot.data();
                memberData.id = memberRef.id;

                // Flatten memberData.fields into memberData
                if (Array.isArray(memberData.fields)) {
                  memberData.fields.forEach((field) => {
                    memberData[field.id] = field.value;
                  });
                }

                return memberData;
              }
              return null;
            })
          );
          // Filter out nulls
          const members = membersData.filter((m) => m !== null);
          return {
            ...team,
            membersData: members,
          };
        })
      );

      setTeams(teamsWithMemberData);
    } catch (error) {
      console.error('Error fetching teams:', error);
      setIsTeamsError(error);
    } finally {
      setIsTeamsLoading(false);
    }
  };

  // ---------------------------
  // Fetch TEAM TYPES (SORT by 'order')
  // ---------------------------
  const fetchTeamTypes = async () => {
    if (!organizationData || !organizationData.groupId) {
      console.error('Organization data is not available to fetch team types.');
      return;
    }
    try {
      const teamTypesCollectionRef = collection(
        firestore,
        'teamGroups',
        organizationData.groupId,
        'teamTypes'
      );
      const snapshot = await getDocs(teamTypesCollectionRef);
      let typesList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sort by 'order' ascending
      typesList.sort((a, b) => {
        const orderA = typeof a.order === 'number' ? a.order : 999999;
        const orderB = typeof b.order === 'number' ? b.order : 999999;
        return orderA - orderB;
      });

      setTeamTypes(typesList);
    } catch (error) {
      console.error('Error fetching team types:', error);
    }
  };

  // ---------------------------
  // ADD TEAM TYPE (icon optional, order appended)
  // ---------------------------
  const addTeamType = async ({ name, icon }) => {
    if (!name.trim()) {
      throw new Error('Team Type name is required');
    }
    try {
      // Compute the next order by finding the max order among existing teamTypes
      let maxOrder = 0;
      teamTypes.forEach((type) => {
        if (typeof type.order === 'number' && type.order > maxOrder) {
          maxOrder = type.order;
        }
      });
      const newOrder = maxOrder + 1;

      const teamTypesCollectionRef = collection(
        firestore,
        'teamGroups',
        organizationData.groupId,
        'teamTypes'
      );
      await addDoc(teamTypesCollectionRef, {
        name: name.trim(),
        icon: icon || '',        // empty string if no icon
        order: newOrder,         // put at the bottom
        createdAt: serverTimestamp(),
      });
      await fetchTeamTypes();
    } catch (error) {
      console.error('Error adding team type:', error);
      throw error;
    }
  };

  // ---------------------------
  // DELETE TEAM TYPE
  // ---------------------------
  const deleteTeamType = async (typeId) => {
    if (!typeId) {
      throw new Error('Team Type ID is required');
    }
    try {
      const teamTypeDocRef = doc(
        firestore,
        'teamGroups',
        organizationData.groupId,
        'teamTypes',
        typeId
      );
      await deleteDoc(teamTypeDocRef);
      await fetchTeamTypes();
    } catch (error) {
      console.error('Error deleting team type:', error);
      throw error;
    }
  };

  // EDIT TEAM TYPE (Name, Icon, Order)
  const editTeamType = async (typeId, updatedFields) => {
    // updatedFields might be: { name, icon, order }
    if (!typeId) {
      throw new Error('Team Type ID is required');
    }
    try {
      const teamTypeDocRef = doc(
        firestore,
        'teamGroups',
        organizationData.groupId,
        'teamTypes',
        typeId
      );
      await updateDoc(teamTypeDocRef, { ...updatedFields });
      await fetchTeamTypes();
    } catch (error) {
      console.error('Error editing team type:', error);
      throw error;
    }
  };

  // ---------------------------
  // TEAM CRUD
  // ---------------------------
  const addTeam = async ({ name, members, teamTypeId }) => {
    if (!name) {
      throw new Error('Team name is required');
    }
    try {
      const teamsCollectionRef = collection(
        firestore,
        'teamGroups',
        organizationData.groupId,
        'teams'
      );
      const memberRefs = (members || []).map((member) => {
        const [memberGroupId, memberId] = member.split('_');
        if (!memberGroupId) {
          throw new Error(`Missing groupId for member with ID ${memberId}`);
        }
        return doc(
          firestore,
          'formGroups',
          memberGroupId,
          'forms',
          'membersForm',
          'responses',
          memberId
        );
      });

      const newTeam = {
        name,
        members: memberRefs,
        teamTypeId: teamTypeId || null,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };
      await addDoc(teamsCollectionRef, newTeam);
      await fetchTeams();
    } catch (error) {
      console.error('Error adding team:', error);
      throw error;
    }
  };

  const editTeam = async (teamId, { name, members, teamTypeId }) => {
    if (!teamId || !name) {
      throw new Error('Team ID and name are required');
    }
    try {
      const teamDocRef = doc(
        firestore,
        'teamGroups',
        organizationData.groupId,
        'teams',
        teamId
      );
      const memberRefs = (members || []).map((member) => {
        const [memberGroupId, memberId] = member.split('_');
        if (!memberGroupId) {
          throw new Error(`Missing groupId for member with ID ${memberId}`);
        }
        return doc(
          firestore,
          'formGroups',
          memberGroupId,
          'forms',
          'membersForm',
          'responses',
          memberId
        );
      });

      await updateDoc(teamDocRef, {
        name,
        members: memberRefs,
        teamTypeId: teamTypeId || null,
        updatedAt: serverTimestamp(),
      });
      await fetchTeams();
    } catch (error) {
      console.error('Error editing team:', error);
      throw error;
    }
  };

  const deleteTeam = async (teamId) => {
    if (!teamId) {
      throw new Error('Team ID is required');
    }
    try {
      const teamDocRef = doc(
        firestore,
        'teamGroups',
        organizationData.groupId,
        'teams',
        teamId
      );
      await deleteDoc(teamDocRef);
      await fetchTeams();
    } catch (error) {
      console.error('Error deleting team:', error);
      throw error;
    }
  };

  // ---------------------------
  // useEffect Hooks
  // ---------------------------
  useEffect(() => {
    if (organizationData?.groupId) {
      fetchTeams();
      fetchTeamTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationData.groupId]);

  return (
    <TeamContext.Provider
      value={{
        teams,
        teamTypes,
        isTeamsLoading,
        isTeamsError,
        fetchTeams,
        addTeam,
        editTeam,
        deleteTeam,
        fetchTeamTypes,
        addTeamType,
        deleteTeamType,
        editTeamType,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};

export default TeamProvider;
