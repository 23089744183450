import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconMap from './IconMap'; // The file containing all FontAwesome solid icons
import './IconMapSelector.css';

const IconMapSelector = ({ selectedIcon, onChange, placeholder = 'Select Icon' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const selectorRef = useRef(null);

  // Convert IconMap object keys to a sorted array
  const iconKeys = Object.keys(IconMap).sort();

  // Filter icon keys by search term (case-insensitive substring match)
  const filteredKeys = iconKeys.filter((key) =>
    key.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Close the dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (selectorRef.current && !selectorRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setSearchTerm(''); // Reset search whenever dropdown is re-opened
    }
  };

  const handleIconSelect = (iconKey) => {
    onChange(iconKey);  // Pass the selected icon key to parent
    setIsOpen(false);
  };

  const displayedIcon = selectedIcon && IconMap[selectedIcon] ? selectedIcon : '';

  return (
    <div className="icon-map-selector" ref={selectorRef}>
      {/* Square "button" that toggles dropdown */}
      <div className="icon-selector-square" onClick={handleToggle}>
        {displayedIcon ? (
          <FontAwesomeIcon 
            icon={IconMap[displayedIcon]} 
            style={{ color: 'var(--main-color)', fontSize: '16px' }} 
          />
        ) : (
          <span className="icon-placeholder">{placeholder}</span>
        )}
        <FontAwesomeIcon icon="chevron-down" className="chevron-icon" />
      </div>

      {isOpen && (
        <div className="icon-dropdown">
          {/* Search Bar */}
          <input
            className="icon-search"
            type="text"
            placeholder="Search icons..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          {/* Grid of filtered icons */}
          <div className="icon-grid">
            {filteredKeys.map((key) => (
              <div
                key={key}
                className="icon-dropdown-item"
                onClick={() => handleIconSelect(key)}
              >
                <FontAwesomeIcon icon={IconMap[key]} style={{ color: 'var(--main-color)' }} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default IconMapSelector;
