// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import LandingPage from './components/LandingPage/LandingPage';
import Login from './components/Authentication/Login';
import MainScreen from './components/Main/MainScreen';
import PasswordRecovery from './components/Authentication/PasswordRecovery';
import ProtectedRoute from './components/Authentication/ProtectedRoute';
import ResetPassword from './components/Authentication/ResetPassword';
import PrivacyPolicy from './components/Policies/PrivacyPolicy';
import TermsService from './components/Policies/TermsService';
import ColorSettings from './components/Common/ColorSettings';
import LanguageSettings from './components/Common/LanguageSettings';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ErrorBoundary from './components/Common/ErrorBoundary';
import './App.css';
import Layout from './components/Common/Layout';
import { DataProvider } from './DataContext'; // Import DataProvider instead of DataContext
import PublicForm from './components/FormBuilder/PublicForm';
import NotFound from './NotFound';
import SignUpForm from './components/SignUp/SignUpForm';
import OrganizationGroupSelectionPage from './components/Authentication/OrganizationGroupSelectionPage';

const queryClient = new QueryClient();

// AppContent handles group-specific routes
const AppContent = ({ groupId }) => {
  return (
    <DataProvider groupID={groupId}>
      <LanguageSettings>
        <ColorSettings>
          <Layout>
            <Routes>
              {/* Protected Routes */}
              <Route
                path="main"
                element={
                  <ProtectedRoute>
                    <MainScreen />
                  </ProtectedRoute>
                }
              />
              {/* Group-Specific Password Recovery Routes */}
              <Route path="password-recovery" element={<PasswordRecovery />} />
              <Route path="reset-password" element={<ResetPassword />} />

              {/* Public Form Route */}
              <Route path="forms/:formId" element={<PublicForm />} />

              {/* Catch-all for undefined routes within group */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Layout>
        </ColorSettings>
      </LanguageSettings>
    </DataProvider>
  );
};

// Wrapper for Group-Specific Login Route
const GroupLoginWrapper = () => {
  const { groupId } = useParams(); // Extract groupId from route

  return (
    <DataProvider groupID={groupId}>
      <LanguageSettings>
        <ColorSettings>
          <Layout>
            <Login />
          </Layout>
        </ColorSettings>
      </LanguageSettings>
    </DataProvider>
  );
};

// Wrapper for Group-Specific AppContent with groupId
const AppContentWrapper = () => {
  const { groupId } = useParams(); // Extract groupId from route
  return <AppContent groupId={groupId} />;
};

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <Router>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<LandingPage />} />

            {/* General Login Route without groupID */}
            <Route
              path="/login"
              element={
                <DataProvider>
                  <LanguageSettings>
                    <ColorSettings>
                      <Layout>
                        <Login />
                      </Layout>
                    </ColorSettings>
                  </LanguageSettings>
                </DataProvider>
              }
            />

            {/* Password Recovery and Reset Password Routes */}
            <Route
              path="/password-recovery"
              element={
                <DataProvider>
                  <LanguageSettings>
                    <ColorSettings>
                      <Layout>
                        <PasswordRecovery />
                      </Layout>
                    </ColorSettings>
                  </LanguageSettings>
                </DataProvider>
              }
            />
            <Route
              path="/reset-password"
              element={
                <DataProvider>
                  <LanguageSettings>
                    <ColorSettings>
                      <Layout>
                        <ResetPassword />
                      </Layout>
                    </ColorSettings>
                  </LanguageSettings>
                </DataProvider>
              }
            />

            {/* Sign Up Route */}
            <Route
              path="/sign-up"
              element={
                <DataProvider>
                  <LanguageSettings>
                    <ColorSettings>
                      <Layout>
                        <SignUpForm />
                      </Layout>
                    </ColorSettings>
                  </LanguageSettings>
                </DataProvider>
              }
            />

            {/* Group-Specific Login Route */}
            <Route path="/:groupId/login" element={<GroupLoginWrapper />} />

            {/* Policies Accessible at Top-Level */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsService />} />

            {/* Organization Group Selection Route */}
            <Route
              path="/organization-selection"
              element={
                <DataProvider>
                  <LanguageSettings>
                    <ColorSettings>
                      <Layout>
                        <ProtectedRoute>
                          <OrganizationGroupSelectionPage />
                        </ProtectedRoute>
                      </Layout>
                    </ColorSettings>
                  </LanguageSettings>
                </DataProvider>
              }
            />

            {/* Group-Specific Routes */}
            <Route path="/:groupId/*" element={<AppContentWrapper />} />

            {/* 404 Not Found Route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

export default App;