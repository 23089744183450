// src/components/FormBuilder/Fields/CheckboxField.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './CheckboxField.css';
import { useTranslation } from 'react-i18next';

const CheckboxField = ({
  label,
  name,
  options,
  value,
  onChange,
  required,
  error,
  layout = 'vertical', // New prop with default value 'vertical'
}) => {
  const { t } = useTranslation();

  // Handle toggling of checkbox options
  const handleToggle = (optionName) => {
    const updatedOptions = value.map((option) => {
      if (option.name === optionName) {
        return { ...option, completed: !option.completed };
      }
      return option;
    });
    onChange(updatedOptions);
  };

  return (
    <div className={`checkbox-field ${required ? 'required' : ''}`}>
      <label className="checkbox-label">
        {label}
        {required && <span className="required-indicator">*</span>}
      </label>
      <div className={`checkbox-options ${layout}`}>
        {options.map((option) => {
          // Find the corresponding value object
          const valueObj =
            value.find((v) => v.name === option.value) || {
              name: option.value || '',
              completed: false,
            };
          return (
            <label key={`${name}-${option.value}`} className="checkbox-option">
              <input
                type="checkbox"
                name={name}
                checked={valueObj.completed}
                onChange={() => handleToggle(option.value)}
              />
              {t(option.label)}
            </label>
          );
        })}
      </div>
      {error && <span className="error-message">{error}</span>}
    </div>
  );
};

CheckboxField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired, // Ensure value is a string
    })
  ).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      completed: PropTypes.bool.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  error: PropTypes.string,
  layout: PropTypes.oneOf(['horizontal', 'vertical']), // Define prop types for layout
};

CheckboxField.defaultProps = {
  required: false,
  error: '',
  layout: 'vertical',
};

export default CheckboxField;
