// src/pages/GroupsPage.jsx

import React, { useContext, useState, useEffect, useRef } from 'react';
import GroupsTable from './GroupsTable';
import { DataContext } from '../../DataContext';
import { useTranslation } from 'react-i18next';
import Spinner from '../Common/Spinner';
import FillOutForm from '../FormBuilder/FillOutForm';
import CustomModal from '../Common/CustomModal';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import ToastContainer from '../Common/ToastContainer';
import ActionButton from '../Common/ActionButton';
import ConfigureFieldsModal from '../Common/ConfigureFieldsModal';
import './GroupsPage.css';

import {
  generateCSV,
  downloadCSV,
} from '../FormBuilder/CSVUtility';

import { generatePDF } from '../FormBuilder/PDFUtility';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisV,
  faCog,
  faFileCsv,
  faFilePdf,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

const GroupsPage = () => {
  const { t } = useTranslation();

  // Consume DataContext
  const {
    useResponses,
    userRole,
    deleteResponse,
    useFormFields,
    getTableFields,
    saveTableFields,
    appSettings,
  } = useContext(DataContext);

  // Use the useResponses hook to fetch groups data for "groupsForm"
  const {
    data: responses = [],
    isLoading,
    error,
    refetch,
  } = useResponses('groupsForm');

  const {
    data: formFieldsData = {},
    isLoading: formFieldsLoading,
    refetch: refetchFormFields,
  } = useFormFields('groupsForm');

  // Fetch tableFields configuration
  const [tableFields, setTableFields] = useState([]);
  const [loadingTableFields, setLoadingTableFields] = useState(true);

  useEffect(() => {
    const fetchTableFields = async () => {
      try {
        const fields = await getTableFields('groupsForm');
        if (fields && fields.length > 0) {
          setTableFields(fields);
        } else {
          // If no configuration exists, set default fields
          setTableFields([
            {
              id: 'groupName',
              label: 'Group Name',
              accessor: 'groupName',
              sortable: true,
              type: 'Text',
              hideInMobile: false,
              clickToEdit: false,
            },
            {
              id: 'description',
              label: 'Description',
              accessor: 'description',
              sortable: true,
              type: 'Text',
              hideInMobile: false,
              clickToEdit: false,
            },
            {
              id: 'createdAt',
              label: 'Created At',
              accessor: 'createdAt',
              sortable: true,
              type: 'DatePicker',
              hideInMobile: false,
              clickToEdit: false,
            },
            // Add more default fields as needed
          ]);
        }
      } catch (error) {
        console.error('Error fetching table fields:', error);
      } finally {
        setLoadingTableFields(false);
      }
    };

    fetchTableFields();
  }, [getTableFields]);
  
  // State variables for export loading states
  const [isExportingCSV, setIsExportingCSV] = useState(false);
  const [isExportingPDF, setIsExportingPDF] = useState(false);

  // State for menu visibility
  const [menuVisible, setMenuVisible] = useState(false);

  // Ref for the menu container
  const menuRef = useRef(null);
  
  // Function to handle exporting responses to CSV
  const handleExportCSV = async () => {
    if (responses.length === 0) {
      showToast(t('responsesTab.noResponsesToExport'), 'error');
      return;
    }

    setIsExportingCSV(true);

    try {
      const csvContent = generateCSV(responses, formFieldsData.fields);
      downloadCSV(csvContent, 'groups.csv');
      showToast(t('responsesTab.csvExportSuccess'), 'success');
    } catch (error) {
      showToast(t('responsesTab.csvExportError'), 'error');
      console.error('Error exporting CSV:', error);
    } finally {
      setIsExportingCSV(false);
      setMenuVisible(false);
    }
  };

  // Function to handle exporting responses to PDF
  const handleExportPDF = async () => {
    if (responses.length === 0) {
      showToast(t('responsesTab.noResponsesToExport'), 'error');
      return;
    }

    setIsExportingPDF(true);

    try {
      const groupLogo = appSettings?.profilePicture || null;
      await generatePDF(
        responses,
        formFieldsData.fields,
        false,
        groupLogo,
        t('groupsForm', 'Groups')
      );
      showToast(t('responsesTab.pdfExportSuccess'), 'success');
    } catch (error) {
      showToast(t('responsesTab.pdfExportError'), 'error');
      console.error('Error exporting PDF:', error);
    } finally {
      setIsExportingPDF(false);
      setMenuVisible(false);
    }
  };

  // Map responses to groups
  const groups = responses.map((response) => {
    const group = {};
    response.fields.forEach((field) => {
      group[field.id] = field.value;
    });
    group.id = response.id;
    group.fields = response.fields;
    group.submittedAt = response.submittedAt;
    group.createdAt = response.createdAt;
    group.updatedAt = response.updatedAt;
    group.response = response; // Include the original response
    return group;
  });

  // Local state for modals and toast notifications
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [deleteModalMessage, setDeleteModalMessage] = useState('');
  const [toasts, setToasts] = useState([]);
  const [showConfigModal, setShowConfigModal] = useState(false);

  // Toast Management
  const showToast = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prevToasts) => [...prevToasts, { id, message, type, duration }]);
    setTimeout(() => removeToast(id), duration);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  // Action Handlers
  const handleAdd = () => {
    setModalContent(
      <FillOutForm
        fields={formFieldsData.fields}
        formId="groupsForm"
        showToast={showToast}
        initialData={null}
        onCancel={() => {
          setShowModal(false);
          setModalContent(null);
          setModalTitle('');
          refetch(); // Refresh the groups list after adding
        }}
      />
    );
    setModalTitle(t('groupsTable.addGroup', 'Add Group'));
    setShowModal(true);
  };

  const handleEdit = (record) => {
    setModalContent(
      <FillOutForm
        fields={formFieldsData.fields}
        formId="groupsForm"
        showToast={showToast}
        initialData={record} // Pass initial data for editing
        responseId={record.id}
        onCancel={() => {
          setShowModal(false);
          setModalContent(null);
          setModalTitle('');
          refetch(); // Refresh the groups list after editing
        }}
      />
    );
    setModalTitle(t('groupsTable.editGroup', { name: record.groupName }));
    setShowModal(true);
  };

  const handleDelete = (record) => {
    setRecordToDelete(record);
    setDeleteModalMessage(
      t('deleteConfirmation.message', {
        item: `${record.groupName}`,
      })
    );
    setShowDeleteModal(true);
  };

  const openGroupLink = (record) => {
    const groupId = record.id; // Extract the group ID from the record
    const rootUrl = window.location.origin; // Get the root URL of the application
    const groupUrl = `${rootUrl}/${groupId}/main`; // Construct the group URL
    window.open(groupUrl, '_blank'); // Open the URL in a new tab
  };

  const handleConfirmDelete = async () => {
    if (!recordToDelete) return;
    try {
      await deleteResponse('groupsForm', recordToDelete.id);
      showToast(t('groupsTable.recordDeleted'), 'success');
      setShowDeleteModal(false);
      setRecordToDelete(null);
      refetch();
    } catch (error) {
      console.error('Error deleting group:', error);
      showToast(t('groupsTable.deleteError'), 'error');
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setRecordToDelete(null);
  };

  // Handle opening the configuration modal
  const handleOpenConfigModal = () => {
    setShowConfigModal(true);
    setMenuVisible(false);
  };

  // Handle saving the configuration
  const handleSaveConfig = async (configuredFields) => {
    try {
      await saveTableFields('groupsForm', configuredFields);
      setTableFields(configuredFields);
      setShowConfigModal(false);
      showToast(t('tableConfigurationSuccessful', 'Table configuration saved successfully'), 'success');
    } catch (error) {
      console.error('Error saving table configuration:', error);
      showToast(t('tableConfigurationFailed', 'Failed to save table configuration'), 'error');
    }
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    };

    if (menuVisible) {
      document.addEventListener('mousedown', handleClickOutsideMenu);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, [menuVisible]);

  // Define the actions you want to perform on each row
  const actions = [
    {
      label: t('edit', 'Edit'),
      icon: 'faEdit',
      callback: handleEdit,
      variant: 'secondary',
      condition: () => true,
    },
    {
      label: t('open', 'Open'),
      icon: 'faLink',
      callback: openGroupLink,
      variant: 'secondary',
      condition: () => true,
    },
    // Add more actions if necessary
  ];

  // Loading and Error States
  if (isLoading || loadingTableFields || formFieldsLoading) {
    return (
      <div className="loading-container">
        <Spinner size="100px" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-message">{t('groupsPage.errorFetchingGroups')}</div>
    );
  }

  return (
    <div className="groups-page-container">
      {/* Header Container */}
      <div className="header-container">
        <h2>{t('groupsPage.title', 'Groups')}</h2>

        {/* Configuration Menu Button */}
        <div className="config-button">
          <div className="menu-container" ref={menuRef}>
            <ActionButton
              onClick={() => setMenuVisible(!menuVisible)}
              icon="faEllipsisV"
              variant="secondary"
              label={t('moreOptions', 'More Options')}
            />
            {menuVisible && (
              <div className="dropdown-menu">
                <button onClick={handleOpenConfigModal}>
                  <FontAwesomeIcon icon={faCog} className="menu-icon" />
                  {t('configureTableFields', 'Configure Table Fields')}
                </button>
                <button
                  onClick={handleExportCSV}
                  disabled={isExportingCSV}
                >
                  <FontAwesomeIcon icon={faFileCsv} className="menu-icon" />
                  {t('exportCSV', 'Export CSV')}
                  {isExportingCSV && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      className="menu-spinner"
                    />
                  )}
                </button>
                <button
                  onClick={handleExportPDF}
                  disabled={isExportingPDF}
                >
                  <FontAwesomeIcon icon={faFilePdf} className="menu-icon" />
                  {t('exportPDF', 'Export PDF')}
                  {isExportingPDF && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      className="menu-spinner"
                    />
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <GroupsTable
        formId="groupsForm"
        fields={tableFields}
        actions={actions}
        groups={groups}
        userRole={userRole}
        addGroup={handleAdd}
        onRowClick={handleEdit}
      />

      {/* Custom Modal for Editing */}
      <CustomModal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setModalContent(null);
          setModalTitle('');
        }}
        title={modalTitle}
      >
        {modalContent}
      </CustomModal>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <DeleteConfirmationModal
          show={showDeleteModal}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          title={t('deleteConfirmation.title')}
          message={deleteModalMessage}
        />
      )}

      {/* Configuration Modal */}
      {showConfigModal && (
        <ConfigureFieldsModal
          show={showConfigModal}
          onClose={() => setShowConfigModal(false)}
          onSave={handleSaveConfig}
          availableFields={formFieldsData.fields}
          currentConfig={tableFields}
        />
      )}

      {/* Toast Notifications */}
      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </div>
  );
};

export default GroupsPage;
